import React from "react";
import "./Table.scss";
import LoadingIcon from "../../components/LoadingIcon";
import NoResultsMessage from "../EmptyMessage/NoResultsMessage";
import SuggestionMessage from "../EmptyMessage/SuggestionMessage";

const Table = ({ colWidths, headers, dataMap, id, minWidth, width, compact, height, restrictHeight, fixedHeaders, dataLoading, noResults, headerWidths, rowSize, dragDrop, onHeaderClick, wrapperStyle, tableStyle, NoResultsComponentOverride, smallNoResult, space, suggestionMessage }) => {
	return (
		<div
			className={`table-wrapper${compact ? " table-compact" : ""}${rowSize ? " table-size-" + rowSize : ""}`}
			style={{ height: height ? height : "", maxHeight: restrictHeight ? "calc(50vh - 192px)" : height ? height : "", ...wrapperStyle }}
		>
			{dataLoading ? (
				<LoadingIcon size={48} centered />
			) : dataMap && dataMap.length && dataMap.length > 0 ? (
				<table id={id} className="table" style={{ width: width, minWidth: minWidth, ...tableStyle }}>
					{colWidths && (
						<colgroup>
							{colWidths.map((width, idx) => {

								if (typeof width === "string") {
									return (
										<col key={"col-" + idx} span="1" style={{ width }} />
									)
								} else {
									return (
										<col key={"col-" + idx} span="1" style={width} />
									)
								}
							})}
						</colgroup>
					)}
					{headers && (
						<thead className={fixedHeaders ? "fixed-headers" : ""}>
							<tr className="header-table-row">
								{headers.map((header, idx) => {
									return (
										<th
											className={header.className ? header.className : ""}
											style={{ width: headerWidths ? headerWidths[idx] : "auto" }}
											key={header && header.headerKey ? 'header-' + header.headerKey : "header-" + idx}
											onClick={() => onHeaderClick && onHeaderClick(header.text ? header.text : header)}
										>
											{header.text ? header.text : header}
										</th>
									);
								})}
							</tr>
						</thead>
					)}
					{dragDrop ? dataMap : <tbody>{dataMap}</tbody>}
				</table>
			) :
				suggestionMessage
					? <SuggestionMessage message='No data to display' className={`pb-${space ? space : 'none'}`} />
					: NoResultsComponentOverride
						? <NoResultsComponentOverride />
						: <NoResultsMessage
							wrapperStyle={noResults && noResults.wrapperStyle ? noResults.wrapperStyle : null}
							message={noResults && noResults.message ? noResults.message : "No results"}
							subMessage={noResults && noResults.subMessage ? noResults.subMessage : "Looks like there are no results for this page"}
							smallNoResult={smallNoResult}
							space={space}
						/>
			}
		</div>
	);
}

export default Table;

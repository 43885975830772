import React, { useEffect, useState, useContext } from 'react';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import { Eye, Copy, ExternalLink } from 'react-feather';
import useWindowDimensions, { onDrop, useRemote, useStorage } from '../../../Utils/Utils';
import { useParams } from "react-router";
import { toastWarning, toastSuccess } from '../../../components/popups/Toast';
import { BannerContext } from '../../../InternalApiApp';
import { pieChartDataObject, ColourSample, extractColours, getCategoryChartData, getCategoryChartConfig, previewElement } from './BrandsStyles'
import PageGridItem from '../../Shared/Page/PageGridItem';
import Divider from '../../../components/Divider';
import Button from '../../../components/Buttons/Button';
import WebfontLoader from '@dr-kobros/react-webfont-loader';
import LoadingIcon from '../../Shared/LoadingIcon';
import SelectDetailField from '../../Shared/DetailSection/SelectDetailField'
import SideBarExtensionHeader from '../../../components/SideBar/SideBarExtension/SideBarExtensionHeader';
import Dropzone from '../../../components/Dropzone/Dropzone';
import DetailField from '../../Shared/DetailSection/DetailField'
import PageContentToggle from '../../../components/Menus/PageContentToggle';
import FlexWrapper from '../../FlexWrapper';
import SearchTermFilter from '../../../components/Input/SearchTermFilter';
import SearchField from '../../../components/Input/SearchField';
import FontAdmin from './FontAdmin';
import TypographyAdmin from './TypographyAdmin';
import './StyleGuide.scss'

const SubBrandPage = ({ organisation }) => {
    const { setBannerBreadCrumbs } = useContext(BannerContext);
    const { subBrandId } = useParams()
    const remote = useRemote()
    const windowHeight = useWindowDimensions().height
    const excludedColours = ["Divider", "Table Header Text 2", "Table Header Text 2", "Table Header Background 3", "Table Header Background 3"]

    const [selectedTab, setSelectedTab] = useState('Template Settings')
    const [toggleOn, setToggleon] = useState(false)
    const [portalLogoWidth, setPortalLogoWidth] = useState(null)
    const [hasSearchTerm, setHasSearchTerm] = useState(false)
    const [filteredPortalData, setFilteredPortalData] = useState([])
    const [portalData, setPortalData] = useState([])

    const [details, setDetails] = useState(null)
    const [subBrandDetails, setSubBrandDetails] = useState(null)
    const [masterDetails, setMasterDetails] = useState(null)
    const [style, setStyle] = useState(null)
    const [subBrandStyle, setSubBrandStyle] = useState(null)
    const [masterStyle, setMasterStyle] = useState(null)
    const [viewMaster, setViewMaster] = useState(false)
    const [template, setTemplate] = useState(null)

    const [logoSrc, setLogoSrc] = useState(null)
    const [logoSettings, setLogoSettings] = useState(null)
    const [defaultLogoSettings, setDefaultLogoSettings] = useState(null)
    const [subBrandLogoSettings, setSubBrandLogoSettings] = useState(null)
    const [logoUploading, setLogoUploading] = useState(false)

    const [brandColours, setBrandColours] = useState([])
    const [coloursPalette, setColoursPalette] = useState(null)
    const [pieChartImage, setPieChartImage] = useState(null)
    const [categoryChartImage, setCategoryChartImage] = useState(null)
    const [tableStyles, setTableStyles] = useState({})
    const [loading, setLoading] = useState(true)
    const [pieDataConfig, setPieDataConfig] = useState(null)
    const [fontConfig, setFontConfig] = useState(null)
    const [masterFontConfig, setMasterFontConfig] = useState(null)
    const [subBrandFontConfig, setSubBrandFontConfig] = useState(null)
    const [loadingViewMaster, setLoadingViewMaster] = useState(false)

    // const threeSkyeColours = ['#05477D', '#0F8EFD', '#5CC1F1', '#E9F4FF', '#A3CCFD', '#6C7EE0', '#56D1C3', '#13C7DE', '#02184A', '#E7EAF2' ]
    // const cfsColours = ['#008fd3', '#154a9a', '#f06ecd', '#d81521', '#60c9d3', '#64a85a', '#7961a2', '#8ea4c9', '#e9edf4', '#0d0d0d', '#c8d2e5']
    // const hejazColours = ['#D4AB61', '#1F304F', '#8E5C2E', '#D57E29', '#FBE1CA', '#A2A4A3', '#4F6FA3', '#FFF7E8', '#313131'] 
    // const myNorthColours = ['#546f78', '#001845', '#1c525d', '#c7c9e8', '#798194', '#1fc3f3', '#d3f5fc'] 
    // const janaColours = ["#002555", "#4ecdc4", "#dbf1f9"]
    // const alterisColours = ["#c8b06e", "#936878", "#2f3b42", "#843046", "#707070", "#b6aea5", "#f5e8e0", "#936878"]
    // const navegarColours = ["#039FB7", "#272727", "#7C7C7C", "#EEEEEE", "#F7C246", "#1C365D", "#8B0304", "#61F4FE", "#0B70B8", "#FC8408"]
    // const genericColours = generateLighterColors(stylesArray[3].values[0].color, stylesArray[3].values[1].color)

    useEffect(() => {
        remote.get('brand/all').then(fetchedBrands => {
            const master = fetchedBrands && fetchedBrands.length > 0 && fetchedBrands.find(b => b.master)
            const subBrand = fetchedBrands && fetchedBrands.length > 0 && fetchedBrands.find(b => b.id === +subBrandId)

            if (master) {
                setMasterStyle(master)
            }
            if (subBrand) {
                setSubBrandStyle(subBrand)
                setSubBrandLogoSettings(JSON.parse(subBrand.logoSettings))
                setDefaultLogoSettings(JSON.parse(subBrand.logoSettings))
            }
            setBannerBreadCrumbs({ tab: 'Sub-Brands', param1: { name: subBrand?.name, id: subBrandId } })
            setStyle(subBrand)

            remote.get(`brand/${master.id}/details`).then(fetchedDetails => {
                const fontConfiguration = {
                    custom: {
                        families: fetchedDetails.fonts.map(font => font.name),
                        urls: fetchedDetails.fonts.map(font => font.cssImport || `/api/fonts/${font.name}.css`)
                    }
                }
                setMasterFontConfig(fontConfiguration)
                setMasterDetails(fetchedDetails)
            });
            remote.get(`brand/sub-brand/${subBrandId}/details`).then(fetchedDetails => {
                const fontConfiguration = {
                    custom: {
                        families: fetchedDetails.fonts.map(font => font.name),
                        urls: fetchedDetails.fonts.map(font => font.cssImport || `/api/fonts/${font.name}.css`)
                    }
                }
                setFontConfig(fontConfiguration)
                setSubBrandFontConfig(fontConfiguration)
                setSubBrandDetails(fetchedDetails)
                setDetails(fetchedDetails)
                setTemplate(fetchedDetails.name)
            });
        })
    }, [])

    useEffect(() => {
        setLoadingViewMaster(true)
        if (viewMaster) {
            setDetails(masterDetails)
            setStyle(masterStyle)
            setFontConfig(masterFontConfig)
        } else {
            setDetails(subBrandDetails)
            setStyle(subBrandStyle)
            setFontConfig(subBrandFontConfig)
        }
    }, [viewMaster])

    useEffect(() => {
        if (selectedTab === 'Portal Settings') {
            const logoElement = document.getElementById('portal-manager-logo')
            if (logoElement) {
                setPortalLogoWidth(logoElement.offsetWidth - 10)
            }
        }
    }, [selectedTab])

    useEffect(() => {
        if (details) {
            const typography = details.typography
            const colours = details.colours
            const subBrandColours = subBrandDetails.colours

            // Pie Chart Configuration
            const pieChartColoursArray = [colours['Palette 1']?.value, colours['Palette 2']?.value, colours['Palette 3']?.value, colours['Palette 4']?.value, colours['Palette 5']?.value, colours['Palette 6']?.value, colours['Palette 7']?.value]

            const pieDataConfiguration = {
                width: 360,
                height: 200,
                styles: { legend: 'body-text' },
                backgroundColour: '#ffffff',
                legendColour: colours['Text']?.value,
                colours: pieChartColoursArray,
                title: { value: '' },
                data: { value: '${WhereGraph}' },
                donutRadius: 50
            }

            const pieChartData = {
                "data": { "data": pieChartDataObject(template) },
                "className": "PieChart",
                "dataSuffix": null,
                "configuration": JSON.stringify(pieDataConfiguration),
                "brandId": details?.brandId,
                "template": {
                    "name": "test",
                    "fonts": {},
                    "styles": {},
                    "pages": []

                }
            };

            // Category Chart configuration
            const chartStyles = {
                width: 200,
                height: 132,
                styles: { p: 'body-text-small', h1: 'body-header' }, // Warning: category chart still depends on a template to be generated
                backgroundColour: '#ffffff',
                gridColour: "#ffffff",
                axisColour: colours['Divider']?.value,
                title: { value: '' },
                series: [{ colour: colours['Chart Series 1']?.value }, { colour: colours['Chart Series 2']?.value }]
            }

            const chartColourList = {
                axis: "#2B2E34",
                chartBackground: "#ffffff",
                grid: "#ffffff",
                line1: colours['Chart Series 1']?.value,
                line2: colours['Chart Series 2']?.value
            }
            const categoryChartConfig = getCategoryChartConfig(chartColourList, template)
            const categoryChartData = getCategoryChartData(categoryChartConfig, template, { brandId: subBrandId })

            // Table configuration
            const tableStyleObject = {
                font: { fontFamily: typography['Table Cell']?.familyName, fontSize: `${typography['Table Cell']?.size}pt` },
                border: { ...typography['Table Border'] },
                row1: { color: colours['Table Header Text 1']?.value, backgroundColor: colours['Table Header Background 1']?.value, label: 'Table Header 1', keyName: 'tableHead1' },
                default: { color: colours['Table Cell Text']?.value, backgroundColor: colours['Table Cell Background']?.value, label: 'Table Body Cell', keyName: 'tableCell' }
            }
            const stylesArray = [
                {
                    category: 'General',
                    values: [
                        { ...subBrandColours['Primary'], keyName: 'primary' },
                        { ...subBrandColours['Secondary'], keyName: 'secondary' },
                        { ...subBrandColours['Text'], keyName: 'text' },
                        { ...subBrandColours['Divider'], keyName: 'divider' },
                    ]
                },
                {
                    category: 'Tables',
                    values: [
                        { ...subBrandColours['Table Cell Text'], keyName: 'tableCellText' },
                        { ...subBrandColours['Table Cell Background'], keyName: 'tableCellBackground' },
                        { ...subBrandColours['Table Header Text 1'], keyName: 'tableHeaderText1' },
                        { ...subBrandColours['Table Header Background 1'], keyName: 'tableBackgroundText1' },
                        { ...subBrandColours['Table Header Text 2'], keyName: 'tableHeaderText2' },
                        { ...subBrandColours['Table Header Background 2'], keyName: 'tableBackgroundText2' },
                        { ...subBrandColours['Table Header Text 3'], keyName: 'tableHeaderText3' },
                        { ...subBrandColours['Table Header Background 3'], keyName: 'tableBackgroundText3' },
                        { ...subBrandColours['Table Border'], keyName: 'tableBorder' }
                    ]
                },
                {
                    category: 'Pie Chart',
                    values: [
                        { ...subBrandColours['Palette 1'], keyName: 'palette1' },
                        { ...subBrandColours['Palette 2'], keyName: 'palette2' },
                        { ...subBrandColours['Palette 3'], keyName: 'palette3' },
                        { ...subBrandColours['Palette 4'], keyName: 'palette4' },
                        { ...subBrandColours['Palette 5'], keyName: 'palette5' },
                        { ...subBrandColours['Palette 6'], keyName: 'palette6' },
                        { ...subBrandColours['Palette 7'], keyName: 'palette7' },
                    ]
                },
                {
                    category: 'Category Chart',
                    values: [
                        { ...subBrandColours['Chart Series 1'], keyName: 'chartSeries1' },
                        { ...subBrandColours['Chart Series 2'], keyName: 'chartSeries2' }
                    ]
                },
            ]

            const uniqueColours = [...new Set(extractColours(stylesArray))]

            // update states
            setBrandColours(stylesArray)
            setPieDataConfig(pieDataConfiguration)
            setTableStyles(tableStyleObject)
            setColoursPalette(uniqueColours)

            if (style) {
                setLogoSrc(style.logo ? true : false)
                if (style.logoSettings) {
                    setLogoSettings(JSON.parse(style.logoSettings))
                } else setLogoSettings({ height: 'auto', width: '150' })
            }

            if (pieChartData && chartStyles.styles && chartStyles.styles.h1) {
                Promise.all([
                    remote.post(`ssrender/preview`, pieChartData).then(resp => {
                        setPieChartImage(resp.data)
                        setLoading(false)

                    }).catch(() => setPieChartImage(null)),
                    remote.post(`ssrender/preview`, categoryChartData).then(resp => {
                        setCategoryChartImage(resp.data);
                    }).catch(() => setCategoryChartImage(null))
                ]).then(() => {
                    setLoadingViewMaster(false)
                })
            }
        }

    }, [details])


    function uploadLogo(files) {
        onDrop(files, `/brand/logo/${subBrandStyle.id}`, {
            setLoading: setLogoUploading,
            onUploadComplete: (data) => {
                try {
                    const parsed = JSON.parse(data.target.response);
                    setSubBrandStyle(parsed.data);
                    if (!viewMaster) setStyle(parsed.data);
                } catch (e) {
                    console.log("Error ", e)
                    toastWarning("Error uploading file")
                }
            },
            maxFileSize: 5 * 1024 * 1024
        })
    }

    function updateImageSettings(string, sizeObj) {
        remote.post(`/brand/settings/${string}/${style.id}`, sizeObj).then((resp) => {
            setDefaultLogoSettings(JSON.parse(resp.data.logoSettings))
            setSubBrandLogoSettings(JSON.parse(resp.data.logoSettings))
            if (!viewMaster) setLogoSettings(JSON.parse(resp.data.logoSettings))
        })
    }

    function updateColour(id, colour, name) {
        remote.put(`/brand/colour/${details.brandId}/${id}`, { name: name, value: colour }).then(response => {
            const deets = { ...details };
            deets.colours[response.name] = response;
            setDetails(deets);
        })
    }

    function fontUpdate() {
        //cheat and just replace details from the remote.
        remote.get(`/brand/${style.id}/details`).then(setDetails);
    }

    function getPortalURL() {
        if (!organisation.portalServer) return
        return 'https://' + organisation.portalServer + '/manager/' + subBrandStyle.portalIdentifier
    }

    function copyUrl() {
        navigator.clipboard.writeText(getPortalURL())
        toastSuccess('url copied to clipboard')
    }

    return (
        <Page noBanner noOverflownY style={{ paddingLeft: '1rem' }}>
            <PageContentBodyGrid rowGap='m' Count='12' paddingTop='0rem' subGrid style={{ width: "100%", rowGap: 0 }} noOverflownY>
                <PageGridItem className='pt-l pb-xl' col="1 / span 12">
                    <FlexWrapper flexDirection="row" justify="space-between">
                        <PageContentToggle labels={[
                            { label: "Template Settings", active: selectedTab === "Template Settings", onClick: () => setSelectedTab("Template Settings") },
                            { label: "Portal Settings", active: selectedTab === "Portal Settings", onClick: () => setSelectedTab("Portal Settings") },
                        ]} />
                    </FlexWrapper>
                </PageGridItem>
                <PageGridItem style={{ display: "flex", flexDirection: "column", width: "100%" }} col="1 / span 12" >
                    <PageContentBodyGrid rowGap='m' gridColCount='4' paddingTop='0rem' subGrid gridTemplateColumns='300px 21cm' noOverflownY>
                        <a href="https://fund-portal.localhost.3skye.com/">Fund portal with referrer</a>
                        {selectedTab === 'Template Settings'
                            ? <PageGridItem container className='second-sidebar-left sub-brand' style={{ height: windowHeight - 208 }}>
                                <div className='second-sidebar-left-container' style={{ paddingTop: 0 }}>
                                    <SelectDetailField editMode={true} disabled placeholder='Select' label="Brand Name" value={{ value: template, label: template }} options={[{ value: template, label: template }]} onChange={(option) => setTemplate(option)} />
                                </div>
                                <div className='second-sidebar-left-container'>
                                    <div className='second-sidebar-left-label'>Logo</div>
                                    <img style={{ height: 'auto', width: 'fit-content', maxHeight: 100, maxWidth: '100%' }} src={`data:image/png;base64, ${subBrandStyle?.logo}`} alt="logo" id="image-template-logo" />
                                    <Dropzone onDrop={uploadLogo} uploading={logoUploading} showUploadingSpinner extraSmall />
                                </div>
                                <div className='second-sidebar-left-container'>
                                    <div className='second-sidebar-left-label'>Logo Width (pt)</div>
                                    <div style={{ display: 'flex', gap: 10 }}>
                                        <DetailField editMode={true} size='small' value={subBrandLogoSettings?.width} onChange={(e) => { setSubBrandLogoSettings({ height: 'auto', width: e.target.value }); setLogoSettings({ height: 'auto', width: e.target.value }) }} />
                                        <Button onClick={() => updateImageSettings('logo', subBrandLogoSettings)} style={{ width: 'fit-content' }} disabled={subBrandLogoSettings && subBrandLogoSettings?.width === defaultLogoSettings?.width}>Save</Button>
                                    </div>
                                </div>
                                <div className='second-sidebar-left-container'>
                                    <div className='second-sidebar-left-label'>Colour Palette</div>
                                    {brandColours && brandColours.length > 0 && brandColours.map((obj, index) => {
                                        return (
                                            <div className='sample-group' key={obj.category + "-" + index}>
                                                <label className='colour-text-neutral-dark-50'>{obj.category}</label>
                                                {obj.values.map((val, idx) => {
                                                    if (excludedColours.includes(val.name)) return ""
                                                    else return (
                                                        <ColourSample changeBrandColour={(value) => updateColour(val.id, value, val.name)} colour={val.value} label={val.name} key={`key-${val.name}`} coloursPalette={coloursPalette} />
                                                    )
                                                })}
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className='second-sidebar-left-container'>
                                    <FontAdmin brand={subBrandStyle} fontUpdated={fontUpdate} details={subBrandDetails} updateDetails={setDetails} />
                                </div>
                                <div className='second-sidebar-left-container'>
                                    <div className='second-sidebar-left-label'>Typography</div>
                                    <TypographyAdmin brand={subBrandStyle} details={subBrandDetails} updateDetails={setDetails} short />
                                </div>
                            </PageGridItem>
                            : <PageGridItem container className='second-sidebar-left sub-brand'>
                                <div className='second-sidebar-left-container no-border pb-m' style={{ paddingTop: 0 }}>
                                    <div className='second-sidebar-left-label'>Portal Staus</div>
                                    <FlexWrapper gap='s' align='flex-start'>
                                        <div style={{ opacity: !toggleOn ? 1 : 0.5 }}>Off</div>
                                        {toggleOn ?
                                            <i className='material-icons toggle-icon on' onClick={() => setToggleon(false)}>toggle_on</i> :
                                            <i className='material-icons toggle-icon off' onClick={() => setToggleon(true)}>toggle_off</i>
                                        }
                                        <div style={{ opacity: toggleOn ? 1 : 0.5 }}>On</div>
                                    </FlexWrapper>
                                </div>
                                <div className='second-sidebar-left-container no-border pb-m'>
                                    <FlexWrapper justify='space-between'>
                                        <div className='second-sidebar-left-label'>Portal URL</div>
                                        <FlexWrapper gap='xs'>
                                            <Copy className='portal-settings-icon' size={28} cursor='poniter' onClick={copyUrl} />
                                            <ExternalLink className='portal-settings-icon' size={28} cursor='pointer' onClick={() => window.open(getPortalURL(), '_blank')} />
                                        </FlexWrapper>
                                    </FlexWrapper>
                                    <SelectDetailField editMode={true} disabled placeholder='Select' value={{ label: 'portal.3Skye.com/' + template.split(' ').join('-').toLowerCase(), value: template }} options={[{ value: template, label: template }]} onChange={(option) => setTemplate(option)} />
                                </div>
                                <div className='second-sidebar-left-container'>
                                    <div className='second-sidebar-left-label'>Manager Logo</div>
                                    <label className='colour-text-neutral-dark-50'>{`Recommended size ${portalLogoWidth} x 40`}</label>
                                    <img id='portal-manager-logo' className='border-default' style={{ height: '50px', width: 'fit-content', padding: '4px' }} src={`data:image/png;base64, ${subBrandStyle?.logo}`} alt="logo" />
                                    <Dropzone onDrop={uploadLogo} uploading={logoUploading} showUploadingSpinner extraSmall />
                                </div>
                            </PageGridItem>
                        }
                        {selectedTab === 'Template Settings' ?
                            <PageGridItem container className='rest-of-the-page-width-second-sidebar-left sub-brand' style={{ height: windowHeight - 208 }}>
                                <PageContentBanner divider gap='m' dividerThinkness='m' style={{ paddingTop: 0 }}>
                                    <SideBarExtensionHeader header='Preview' icon={<Eye color='#0E8EFD' />} style={{ marginBottom: 0, paddingRight: 0, paddingTop: 0 }} noTabs />
                                    <Divider vertical height='24px' marginTop='0.25rem' />
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', height: 35 }}>
                                        {viewMaster
                                            ? <i className='material-icons toggle-on' onClick={() => setViewMaster(false)}>toggle_on</i>
                                            : <i className='material-icons toggle-off' onClick={() => setViewMaster(true)}>toggle_off</i>
                                        }
                                        <div style={{ paddingBottom: 3 }}>View Master</div>
                                    </div>
                                    <Divider vertical height='24px' marginTop='0.25rem' />
                                    <Button disabled >Save changes</Button>
                                    <Button disabled variant='secondary' >Save as Default</Button>
                                </PageContentBanner>
                                {loading ? <LoadingIcon centered /> :
                                    <PageContentBodyGrid paddingTop='0px'>
                                        <WebfontLoader onStatus={() => { }} config={fontConfig}>
                                            <PageGridItem colSpan='12' >
                                                <div className='sample-content-section'>
                                                    <label className='colour-text-neutral-dark-50'>Logo + Banner</label>
                                                    {logoSrc === null ? <LoadingIcon centered /> :
                                                        <div className='sample-banner-with-logo end'>
                                                            <img style={{ height: 'auto', width: +logoSettings?.width + 'pt' || '57pt' }} src={`data:image/png;base64, ${style?.logo}`} alt="logo" id="image-template-logo-in-banner" />
                                                            {/* <div className='sample-banner-text'>
                                                                {previewElement("Banner Header", details)}
                                                                {previewElement("Banner Sub-header", details)}
                                                            </div> */}
                                                        </div >
                                                    }
                                                </div>
                                                <div className={`sample-content-section`}>
                                                    <label className='colour-text-label-grey'>Typography</label>
                                                    {previewElement("Heading 1", details)}
                                                    {previewElement("Body", details)}
                                                    <div className='sample-headings-two-and-three'>
                                                        <div>
                                                            {previewElement("Heading 2", details)}
                                                            {previewElement("Body", details, "Lorem ipsum")}
                                                        </div>
                                                    </div>
                                                    <label className='colour-text-label-grey'>Footer Text</label>
                                                    {previewElement("Footer", details)}

                                                </div>
                                                <div className='sample-content-section double-column' style={{ paddingBottom: '2.5rem' }}>
                                                    <div className='sample-chart' style={loadingViewMaster ? null : { gap: '0.5rem' }}>
                                                        <label className='colour-text-neutral-dark-50'>Line Chart</label>
                                                        {loadingViewMaster ? <div style={{ height: 200, width: 300}}><LoadingIcon centered size={70} /></div> : categoryChartImage && <img alt="category chart" style={{ width: 360, height: 210 }} src={"data:image/png;base64," + categoryChartImage} />}
                                                    </div>
                                                    <div className='sample-chart'>
                                                        <label className='colour-text-neutral-dark-50'>Pie Chart</label>
                                                        {loadingViewMaster ? <div style={{ height: 200, width: 300}}><LoadingIcon centered size={70} /></div> : pieChartImage && <img alt="pie chart" style={{ width: pieDataConfig.width, height: pieDataConfig.height }} src={"data:image/png;base64," + pieChartImage} />}
                                                    </div>
                                                </div>
                                                <div className='sample-content-section' style={{ position: 'relative', marginTop: '-1.5rem', borderTop: 'solid 1.5px #E7EAF2', backgroundColor: '#fff' }}>
                                                    <label className='colour-text-neutral-dark-50'>Table Col/Row</label>
                                                    <div className='sample-tables'>
                                                        <table className='sample-table' style={tableStyles.font}>
                                                            <tbody>
                                                                <tr style={tableStyles.row1}>
                                                                    <th>Header 1</th>
                                                                    <th>Lorem</th>
                                                                    <th>Lorem</th>
                                                                    <th>Lorem</th>
                                                                </tr>
                                                                <tr style={tableStyles.default}>
                                                                    <td>Default</td>
                                                                    <td>Lorem</td>
                                                                    <td>Lorem</td>
                                                                    <td>Lorem</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </PageGridItem>
                                        </WebfontLoader>
                                    </PageContentBodyGrid>
                                }
                            </PageGridItem> :
                            <PageGridItem container className='rest-of-the-page-width-second-sidebar-left'>
                                <PageContentBodyGrid paddingTop='0rem'>
                                    <PageGridItem col="1 / span 6">
                                        <div className='second-sidebar-left-label'>Manager Portfolios</div>
                                    </PageGridItem>
                                    <PageGridItem col="7 / span 6">
                                        <FlexWrapper gap='m'>
                                            <SearchField
                                                options={[]}
                                                onChange={() => null}
                                                placeholder='Add Portfolio'
                                                size='small'
                                                width='m'
                                                value=""
                                                disabled
                                                classNames='disabled'
                                            />
                                            <SearchTermFilter
                                                size='small'
                                                width='m'
                                                placeholder='Search'
                                                isClearable
                                                dataSet={portalData}
                                                setFilteredData={setFilteredPortalData}
                                                path={['fundName', 'fundNumber', 'offerName', 'offerNumber']}
                                                hasSearchTerm={(boolean) => setHasSearchTerm(boolean)}
                                                disabled={portalData.length === 0}
                                            />
                                        </FlexWrapper>
                                    </PageGridItem>
                                    <PageGridItem col='1 / 12' >
                                        <div className='colour-text-neutral-dark-40'>{`All ${template} portfolios`}</div>
                                    </PageGridItem>
                                </PageContentBodyGrid>
                            </PageGridItem>
                        }
                    </PageContentBodyGrid>
                </PageGridItem>
            </PageContentBodyGrid>
        </Page >
    )
}

export default SubBrandPage;
import React, { useEffect, useContext } from "react";
import { CraigsDocsWrapper } from "./withCraigsDocs";
import { BannerContext } from "../../InternalApiApp";
import CraigsLibraryPageContentBanner from "./CraigsLibraryPageContentBanner";
import CraigsLibraryPageBody from "./CraigsLibraryPageBody";
import Page from "../Shared/Page/Page";
import "./CraigsLibraryPage.scss";

const CraigsLibraryPage = ({ }) => {
	const { setBannerBreadCrumbs } = useContext(BannerContext);

	useEffect(() => {
		setBannerBreadCrumbs({ tab: 'Library' })
	}, [])

	return (
		<Page fixedBanner id="craigs-library-page">
			<CraigsDocsWrapper>
				<CraigsLibraryPageContentBanner />
				<CraigsLibraryPageBody />
			</CraigsDocsWrapper>
		</Page>
	);
}

export default CraigsLibraryPage;

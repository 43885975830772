import React, { useEffect, useState, useContext } from 'react';
import { useRemote, useStorage, getTickerImageByRic, minutesIntoReadableFormat } from '../../../Utils/Utils';
import Table from '../../../components/Table/Table';
import Page, { PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridItem from '../../Shared/Page/PageGridItem';
import { Eye, Mail, Smartphone, Monitor, Clock, BookOpen, Briefcase, ChevronUp, ChevronDown } from 'react-feather';
import PdfIcon from '../../../images/icons/PdfIcon';
import XlsIcon from '../../../images/icons/XlsIcon';
import FlexWrapper from '../../FlexWrapper';
import PageGridDivider from '../../Shared/Page/PageGridDivider';
import Divider from '../../../components/Divider';
import TickerSectorItem from '../../../components/TickerSectorItem';
import AnalyticsMetricFilter from '../../../components/Analytics/AnalyticsMetricFilter';
import { format } from 'date-fns'
import LoadingIcon from '../../Shared/LoadingIcon';
import PageContentToggle from '../../../components/Menus/PageContentToggle'
import Tooltip from '../../../components/popups/Tooltip';
import { BannerContext } from '../../../InternalApiApp';

/**
 * Displays non-aggregated analytics for a single note
 */
const AnalyticsUserResearchViews = ({ datatita }) => {
	const { setBannerBreadCrumbs, bannerBreadCrumbs } = useContext(BannerContext);
    const remote = useRemote()
    const storage = useStorage()

    const userId = datatita.match.params.userId
    const documentId = datatita.match.params.researchId

    // state
    const [loading, setLoading] = useState(true);
    const [currentView, setCurrentView] = useState("page");
    const [currentFilter, setCurrentFilter] = useState("");
    const [sortingColumn, setSortingColumn] = useState({ column: "TotalDuration", ascending: false })

    const [user, setUser] = useState(null)
    const [noteSummary, setNoteSummary] = useState(null)
    const [noteType, setNoteType] = useState(null)
    const [imagePath, setImagePath] = useState(null)

    // per page tab states
    const [perPageList, setPerPageList] = useState([])
    const [perPageDataDisplaying, setPerPageDataDisplaying] = useState([])

    // per downloads tab states
    const [perDownloadsList, setPerDownloadsList] = useState([])
    const [perDownloadsFilteredList, setPerDownloadsFilteredList] = useState([])
    const [perDownloadsDataDisplaying, setPerDownloadsDataDisplaying] = useState([])

    // data metrics 
    const pdfDownloads = perDownloadsList.reduce((a, c) => { const file = c.file === "pdf" ? 1 : 0; return a + file }, 0);
    const modelDownloads = perDownloadsList.reduce((a, c) => { const file = c.file === "xls" ? 1 : 0; return a + file }, 0);
    const emailRead = "-"
    const portalViews = "-" //getPortalViews(noteSessions)
    const averageDuration = "-"
    const sortedByDuration = perPageList.sort((a, b) => b.totalDuration - a.totalDuration)
    const topPageDuration = sortedByDuration && sortedByDuration.length > 0 ? { minutes: sortedByDuration[0].totalDuration, page: "Page " + sortedByDuration[0].page } : "-"

    useEffect(() => {
        storage.getOrFetch(`/crm/contacts/${userId}`).then((fetchedUser) => {
            setUser(fetchedUser)
            setBannerBreadCrumbs({ tab: 'Users', param1: { name: fetchedUser.firstName + ' ' + fetchedUser.lastName, id: userId } })
        })

        Promise.all([
            storage.getOrFetch(`/analytics/users/${userId}/research/${documentId}`),
            remote.get(`/analytics/users/${userId}/research/${documentId}/per-page`),
            remote.get(`/analytics/users/${userId}/research/${documentId}/downloads`)
        ]).then(([note, fetchedByPage, fetchedByDownloads]) => {
            if (note) {
                setNoteSummary(note)
                setNoteType(note.ticker.includes(".") ? "ticker" : "group")
                getTickerImageByRic(note.ticker, setImagePath)
                setBannerBreadCrumbs({ ...bannerBreadCrumbs, param2: { name: note.title, id: documentId } })
            }

            setPerPageList(fetchedByPage)
            setPerDownloadsList(fetchedByDownloads)
            setLoading(false)
        }).catch((error) => {
            console.error(error)
            setPerPageList([])
            setPerDownloadsList([])
            setLoading(false)
        })

    }, [])

    // data filters
    function filterOnclick(value) {
        return currentFilter === value ? setCurrentFilter("") : setCurrentFilter(value);
    }

    useEffect(() => {
        if (currentView === "downloads") {
            let newList = perDownloadsList
            if (currentFilter === "pdf") newList = perDownloadsList.filter(p => p.file === "pdf")
            if (currentFilter === "model") newList = perDownloadsList.filter(p => p.file === "xls")
            setPerDownloadsFilteredList(newList)
        }

    }, [currentView, currentFilter, perDownloadsList])


    // Sorting
    useEffect(() => {
        sortTable(null)
    }, [currentView, perPageList, perDownloadsList, perDownloadsFilteredList])

    const sortTable = (column) => {
        if (column === null) {
            if (currentView === "page") setPerPageDataDisplaying(perPageList)
            if (currentView === "downloads") setPerDownloadsDataDisplaying(perDownloadsFilteredList)
            return
        }
        let tableData = []
        if (currentView === "page") {
            tableData = [...perPageList]
        } else {
            tableData = [...perDownloadsFilteredList]
        }

        const ascending = sortingColumn.column === column ? !sortingColumn.ascending : true
        switch (column) {
            case "Page":
                tableData.sort((a, b) => ascending ? a.page - b.page : b.page - a.page)
                break;
            case "TotalDuration":
                tableData.sort((a, b) => ascending ? a.totalDuration - b.totalDuration : b.totalDuration - a.totalDuration)
                break;
            case "Device":
                tableData.sort((a, b) => {
                    const user1 = a.device ? a.device : "-"
                    const user2 = b.device ? b.device : "-"
                    return ascending ? user1.localeCompare(user2) : user2.localeCompare(user1)
                })
                break;
            case "File":
                tableData.sort((a, b) => ascending ? a.file.localeCompare(b.file) : b.file.localeCompare(a.file))
                break;
            case "Time/Date":
                tableData.sort((a, b) => {
                    const user1 = a.timeDate ? format(new Date(a.timeDate), "yyyy/MM/dd hh:mmaaa") : '-'
                    const user2 = b.timeDate ? format(new Date(b.timeDate), "yyyy/MM/dd hh:mmaaa") : '-'
                    return ascending ? user1.localeCompare(user2) : user2.localeCompare(user1)
                })
                break;
            default:
                break;
        }
        setSortingColumn({ column: column, ascending: ascending })
        currentView === "page" ? setPerPageDataDisplaying(tableData) : setPerDownloadsDataDisplaying(tableData)
    }


    // Tab selecting
    function changeTab(string) {
        setCurrentFilter("")

        if (string === "page") {
            setCurrentView("page")
            setSortingColumn({ column: "TotalDuration", ascending: false })
        }
        if (string === "downloads") {
            setCurrentView("downloads")
            setSortingColumn({ column: "Time/Date", ascending: false })
        }
    }


    // Per Page table data
    const perPageColWidths = ["400px", "400px"];
    const perPageHeaders = [
        {
            text: (
                <div className='sorting-header' onClick={() => sortTable("Page")} >
                    <div className={sortingColumn.column === "Page" ? "font-weight-semi-bold" : ""}>Page</div>
                    {sortingColumn && sortingColumn.column === "Page" && sortingColumn.ascending === true ?
                        <ChevronUp className="colour-text-primary" size={12} /> :
                        <ChevronDown className={sortingColumn && sortingColumn.column === "User" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                    }
                </div>
            )
        },
        {
            text: (
                <div className='sorting-header right' onClick={() => sortTable("TotalDuration")}>
                    <Clock className="colour-text-primary" size={16} />
                    <div className={sortingColumn.column === "TotalDuration" ? "font-weight-semi-bold" : ""}>Total Duration</div>
                    {sortingColumn && sortingColumn.column === "TotalDuration" && sortingColumn.ascending === true ?
                        <ChevronUp className="colour-text-primary" size={12} /> :
                        <ChevronDown className={sortingColumn && sortingColumn.column === "TotalDuration" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                    }
                </div>
            )
        }
    ];

    const perPageDataMap = (data) => data && data.length > 0 && data.map((datum, idx) => {
        const { page, totalDuration } = datum       // change once the endpoint is implemented

        return (
            <tr key={idx}>
                <td>{page ? <div style={{ display: 'flex', alignItems: 'center', gap: 6 }}><BookOpen size={16} className="colour-text-primary" />{"Page " + page}</div> : "-"}</td>
                <td className="text-align-right">{minutesIntoReadableFormat(totalDuration) || "-"}</td>
            </tr>
        );
    });


    // Per Downlods table data
    const perDownlodsColWidths = ["400px", "200px", "200px"];
    const perDownloadsHeaders = [
        {
            text: (
                <div className='sorting-header' onClick={() => sortTable("Time/Date")} >
                    <div className={sortingColumn.column === "Time/Date" ? "font-weight-semi-bold" : ""}>Time/Date</div>
                    {sortingColumn && sortingColumn.column === "Time/Date" && sortingColumn.ascending === true ?
                        <ChevronUp className="colour-text-primary" size={12} /> :
                        <ChevronDown className={sortingColumn && sortingColumn.column === "Time/Date" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                    }
                </div>
            )
        },
        {
            text: (
                <div className='sorting-header centered' onClick={() => sortTable("Device")} >
                    <div className={sortingColumn.column === "Device" ? "font-weight-semi-bold" : ""}>Device</div>
                    {sortingColumn && sortingColumn.column === "Device" && sortingColumn.ascending === true ?
                        <ChevronUp className="colour-text-primary" size={12} /> :
                        <ChevronDown className={sortingColumn && sortingColumn.column === "Device" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                    }
                </div>
            ),
            className: "text-align-center"
        },
        {
            text: (
                <div className='sorting-header centered' onClick={() => sortTable("File")} >
                    <div className={sortingColumn.column === "File" ? "font-weight-semi-bold" : ""}>File</div>
                    {sortingColumn && sortingColumn.column === "File" && sortingColumn.ascending === true ?
                        <ChevronUp className="colour-text-primary" size={12} /> :
                        <ChevronDown className={sortingColumn && sortingColumn.column === "File" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                    }
                </div>
            )
        }
    ];

    const perDownloadsDataMap = (data) => data && data.length > 0 && data.map((datum, idx) => {
        const { timeDate, device, file } = datum     // change once the endpoint is implemented
        return (
            <tr key={idx}>
                <td>{timeDate ? format(new Date(timeDate), "hh:mmaaa dd/MM/yyyy") : "-"}</td>
                <td className="text-align-center">{device === "mobile" ?
                    <Smartphone width="16" height="16" style={{ transform: "translateY(-1px)" }} color="#0A70C9" /> : device === "desktop" ?
                        <Monitor width="16" height="16" style={{ transform: "translateY(-1px)" }} color="#0E8EFD" /> : "-"
                }</td>
                <td className="text-align-center">{file === "pdf" ?
                    <PdfIcon size={24} style={{ transform: "translateY(-1px)" }} noHover /> : file === "xls" ?
                        <XlsIcon size={24} style={{ transform: "translateY(-1px)" }} noHover /> : "-"
                }</td>
            </tr>
        );
    });


    return (
        <Page noBanner showScrollbar>
            {/* Modal */}
            <PageContentBodyGrid showScrollbar>
                <PageGridItem colSpan="12">
                    <FlexWrapper gap="l">
                        {user ?
                            <FlexWrapper gap="s" direction="column" align="flex-start">
                                <p className="font-weight-semi-bold">{user.firstName + " " + user.lastName}</p>
                                <FlexWrapper direction="row" gap="s">
                                    <Tooltip label={user.email}>
                                        <Mail className='colour-text-neutral-dark-40 informative-icon' size={16} />
                                    </Tooltip>
                                    <Tooltip label={user.organisationName || "Unknown company"}>
                                        <Briefcase className='colour-text-neutral-dark-40 informative-icon' style={{ marginTop: -1 }} size={16} />
                                    </Tooltip>
                                </FlexWrapper>
                            </FlexWrapper> : loading ? <LoadingIcon size={32} /> : ""}
                        <Divider vertical height={40} />
                        <FlexWrapper gap="s" direction="row">
                            <TickerSectorItem image={imagePath} imageSize={40} />
                            {noteSummary ?
                                <FlexWrapper direction="column" align="flex-start" gap="s">
                                    <Tooltip label={noteSummary.title}>
                                        <div style={{ maxWidth: 200, fontSize: 20 }} className="font-weight-medium truncate">{noteSummary.title}</div>
                                    </Tooltip>
                                    <FlexWrapper gap="s" direction="row" style={{ margin: 0, padding: 0 }}>
                                        {noteType === "ticker" && <div className='font-size-body-s colour-text-neutral-dark-40'>{noteSummary.ticker}</div>}
                                        {noteType === "ticker" && <Divider vertical height={14} />}
                                        <div className='font-size-body-s colour-text-neutral-dark-40'>{/*format(new Date(distribution.date), "dd/MM/yyyy") + " " +*/ noteSummary.analystName}</div>
                                    </FlexWrapper>
                                </FlexWrapper> :
                                loading ? <LoadingIcon size={32} /> : ""
                            }
                        </FlexWrapper>
                        <Divider vertical height={40} />
                        <AnalyticsMetricFilter
                            onClick={() => setCurrentFilter("")}
                            active={currentFilter === "minutes"}
                            inactive={currentFilter !== "" && currentFilter !== "minutes"}
                            label="Avg Duration"
                            status="minutes"
                            icon={<Clock className='colour-text-primary' />}
                            count={averageDuration}
                            style={{ cursor: "default" }}
                        />
                        <Tooltip label={topPageDuration && <div style={{ display: "flex", alignItems: "center", gap: 5 }}><Clock className="colour-text-primary" size={14} /> {" " + minutesIntoReadableFormat(topPageDuration.minutes)}</div>} disabled={!topPageDuration}>
                            <AnalyticsMetricFilter
                                onClick={() => setCurrentFilter("")}
                                active={currentFilter === "bookOpen"}
                                inactive={currentFilter !== "" && currentFilter !== "bookOpen"}
                                label="Top Page Duration"
                                status="bookOpen"
                                icon={<BookOpen color='#0D8EFD' />}
                                count={topPageDuration.page}
                                style={{ cursor: "default" }}
                            />
                        </Tooltip>

                        <AnalyticsMetricFilter
                            onClick={() => setCurrentFilter("")}
                            active={currentFilter === "read"}
                            inactive={currentFilter !== "" && currentFilter !== "read"}
                            label="Email Views"
                            status="read"
                            icon={<Mail />}
                            count={emailRead}
                            style={{ cursor: "default" }}
                        />
                        <Tooltip label="Total Views">
                            <AnalyticsMetricFilter
                                onClick={() => setCurrentFilter("")}
                                active={currentFilter === "portal"}
                                inactive={currentFilter !== "" && currentFilter !== "portal"}
                                label="Portal Views"
                                icon={<Eye />}
                                count={portalViews}
                                style={{ cursor: "default" }}
                            /></Tooltip>
                        <Tooltip label="Total PDF Downloads">
                            <AnalyticsMetricFilter
                                onClick={() => currentView !== "downloads" ? setCurrentFilter("") : filterOnclick("pdf")}
                                active={currentFilter === "pdf"}
                                inactive={currentFilter !== "" && currentFilter !== "pdf"}
                                label="PDF Downloads"
                                icon={<PdfIcon noHover />}
                                count={pdfDownloads}
                                style={{ cursor: currentView !== "downloads" ? "default" : "pointer" }}
                            />
                        </Tooltip>
                        <Tooltip label="Total Model Downloads">
                            <AnalyticsMetricFilter
                                onClick={() => currentView !== "downloads" ? setCurrentFilter("") : filterOnclick("model")}
                                active={currentFilter === "model"}
                                inactive={currentFilter !== "" && currentFilter !== "model"}
                                label="Model Downloads"
                                icon={<XlsIcon noHover />}
                                count={modelDownloads}
                                style={{ cursor: currentView !== "downloads" ? "default" : "pointer" }}
                            />
                        </Tooltip>
                    </FlexWrapper>
                </PageGridItem>
                <PageGridDivider colSpan="12" />
                <PageGridItem colSpan="12">
                    <FlexWrapper gap="m">
                        <PageContentToggle labels={[
                            { label: "Per Page", active: currentView === "page", onClick: () => changeTab("page") /*setCurrentView("watching")*/ },
                            { label: "Downloads", active: currentView === "downloads", onClick: () => changeTab("downloads") /*setCurrentView("watching")*/ },
                        ]} />
                    </FlexWrapper>
                </PageGridItem>
                <PageGridItem colSpan="12" style={{ paddingTop: "0.5rem" }}>
                    <Table
                        minWidth={800}
                        tableStyle={{ maxWidth: 1000 }}
                        colWidths={currentView === "page" ? perPageColWidths : perDownlodsColWidths}
                        headers={currentView === "page" ? perPageHeaders : perDownloadsHeaders}
                        dataMap={currentView === "page" ? perPageDataMap(perPageDataDisplaying) : perDownloadsDataMap(perDownloadsDataDisplaying)}
                        noResults={{ message: "No users found" }}
                        dataLoading={loading}
                    />
                </PageGridItem>
            </PageContentBodyGrid>
        </Page>
    );
}

export default AnalyticsUserResearchViews;
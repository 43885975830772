import React from 'react';
import HorizontalStackedChart from './HorizontalStackedChart';
import "./RechartsOverrides.scss";
import { useRemote } from '../../../Utils/Utils';
import { useEffect } from 'react';
import { useState } from 'react';
import { differenceInMonths } from 'date-fns'
import NoResultsMessage from '../../../components/EmptyMessage/NoResultsMessage';
import SuggestionMessage from '../../../components/EmptyMessage/SuggestionMessage';

const BrowserChart = ({ value, dateRange }) => {

	const remote = useRemote();

	const [data, setData] = useState([]);

	const type = value === 'Device Usage' ? 'Device' : 'Browser';

	useEffect(() => {
		let months = dateRange && dateRange.months

		if (typeof dateRange === "string") {
			const endDate = new Date()
			const startDate = new Date(dateRange)
			const monthsDifference = differenceInMonths(endDate, startDate);
			months = monthsDifference
		}

		remote.get(`/analytics/device?months=${months || 6}&type=${type}`).then(d => {
			const newData = [];
			for (let val in d) {
				newData.push({
					"name": val, "value": d[val]
				})
			}
			let sortedData = newData.sort((a, b) => b.value - a.value);
			setData(sortedData);
		});

	}, [value, dateRange]);


	return (
		data.length === 0
			? <SuggestionMessage message='No data to display' />
			: <HorizontalStackedChart data={data} tooltips />
	);
}

export default BrowserChart;
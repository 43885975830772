import React, { useEffect, useState } from 'react';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import { useHistory } from 'react-router-dom';
import { Mail, ChevronRight } from 'react-feather'
import { PAGES, ROUTE_PATHS } from '../../../InternalApiApp';
import useWindowDimensions, { useRemote, useStorage } from '../../../Utils/Utils';
import { format } from 'date-fns';
import PageGridItem from '../../Shared/Page/PageGridItem';
import DetailSectionHeader from '../../Shared/DetailSection/DetailSectionHeader';
import ActionButtonsBanner from '../../Shared/Banner/ActionButtonsBanner';
import DetailField from '../../Shared/DetailSection/DetailField';
import LoadingIcon from '../../Shared/LoadingIcon';
import Table from '../../../components/Table/Table';
// import Button from '../../../components/Buttons/Button';
// import Tab from '../../../components/Menus/Tab';
// import StatusToken from '../../../components/StatusTokens';
// import PageGridDivider from '../../Shared/Page/PageGridDivider';
// import { toastDanger, toastSuccess } from '../../../components/popups/Toast';


const EmailTemplatePage = ({ match }) => {
    const remote = useRemote()
    const storage = useStorage()
    const history = useHistory()
    const emailTemplateId = +match.params.emailTemplateId
    const { height } = useWindowDimensions()

    const [loading, setLoading] = useState(true)
    const [template, setTemplate] = useState(null)
    const [templatesId, setTemplatesIds] = useState([])
    const [colour, setColour] = useState(null)
    // const [uploading, setUploading] = useState([]);
    // const [view, setView] = useState('');

    useEffect(() => {
        storage.getOrFetch(`data/email/template/list`).then(list => {
            const idsArr = list.map(t => t.emailTemplateId)
            setTemplatesIds(idsArr)
        })
        remote.get(`data/email/template/${emailTemplateId}/details?dummy=true`).then(temp => {
            const arr = temp.emailTemplateValue.split("background-color: ")
            const backGroundColour = arr[1].split(";")[0]
            setTemplate(temp)
            setColour(backGroundColour)
            setLoading(false)
        }).catch(() => {
            setTemplate(null)
            setLoading(false)
        })
    }, [emailTemplateId])

    function gotTo(next, currentId) {
        const index = templatesId.findIndex(x => x === currentId)
        if (next) {
            const newId = index === templatesId.length - 1 ? templatesId[0] : templatesId[index + 1]
            history.push(`${ROUTE_PATHS[PAGES.ADMIN]}/company-email-templates/${newId}`)
        } else {
            const newId = index === 0 ? templatesId[templatesId.length - 1] : templatesId[index - 1]
            history.push(`${ROUTE_PATHS[PAGES.ADMIN]}/company-email-templates/${newId}`)
        }
    }

    function templatesUsingTable(data) {
        return data.map(t => {
            return (
                <tr className='cursor-pointer' onClick={() => history.push(`${ROUTE_PATHS[PAGES.ADMIN]}/templates/${t.id}`)}>
                    <td className='truncate'>{t.name}</td>
                    <td><div><ChevronRight size={20} className='colour-text-neutral-dark-40' /></div></td>
                </tr>
            )
        })
    }

    // function handleFileUpload(e) {
    // 	let files = e.target.files;
    // 	setUploading(true);
    // 	const tooBig = [];
    // 	const wrongFileType = [];
    // 	let anyUploaded = false;
    // 	for (var i = 0; i < files.length; i++) {
    // 		let file = files[i];
    // 		let name = file.name;

    // 		anyUploaded = true;
    // 		let xhr = new XMLHttpRequest();
    // 		xhr.open('post', getURL(), true);
    // 		xhr.addEventListener('load', e => {
    // 			if (xhr.status >= 200 && xhr.status < 300) {
    // 				// onUploadComplete(e, asset);
    // 				toastSuccess("Upload Complete");
    // 			} else {
    // 				console.log(xhr);
    // 			}
    // 		});
    // 		xhr.upload.addEventListener('progress', e => {
    // 			if (e.lengthComputable) {
    // 				let complete = (e.loaded / e.total * 100 | 0);
    // 				if (complete === 100) {
    // 					//TODO onscreen reporting
    // 					console.log("Processing ...");
    // 				} else {
    // 					console.log("Finished");
    // 				}
    // 			}
    // 		});

    // 		xhr.setRequestHeader("Content-Type", file.type);
    // 		xhr.setRequestHeader("Content-Disposition", "attachment; filename=" + name);
    // 		xhr.send(file);
    // 	}

    // 	if (tooBig.length > 0 || wrongFileType.length > 0) {
    // 		toastDanger(`Upload Failed ${tooBig.length > 0 ? "file too large" : ""}${tooBig.length > 0 && wrongFileType.length > 0 ? ", " : ""}${wrongFileType.length > 0 ? "wrong file type" : ""}`)
    // 		if (!anyUploaded) {
    // 			setUploading(false)
    // 		}
    // 	}
    // }


    return (
        <Page fixedBanner>
            <PageContentBanner divider>
                <ActionButtonsBanner
                    handleBackOnClick={() => history.push(`${ROUTE_PATHS[PAGES.ADMIN]}/company-email-templates`)}
                    previous={() => gotTo(false, emailTemplateId)}
                    next={() => gotTo(true, emailTemplateId)}
                />
            </PageContentBanner>
            {loading ?
                <PageContentBodyGrid rowGap="none" gridColCount="12" subGrid paddingTop='1.5rem'>
                    <LoadingIcon />
                </PageContentBodyGrid> :
                <PageContentBodyGrid rowGap="none" gridColCount="12" subGrid paddingTop='1.5rem'>
                    <PageGridItem container col="1 / span 12" className='pb-xl'>
                        <DetailSectionHeader header='Email Template Details' icon={<Mail />} />
                    </PageGridItem>
                    <PageGridItem container col="1 / span 4">
                        <PageContentBodyGrid rowGap="xl" gridColCount="4" subGrid>
                            <PageGridItem col="1 / span 2">
                                <DetailField
                                    bold
                                    onChange={(e) => null}
                                    label="Name"
                                    value={template?.emailTemplateName}
                                />
                            </PageGridItem>
                            <PageGridItem col="3 / span 2">
                                <DetailField
                                    bold
                                    onChange={(e) => null}
                                    label="Type"
                                    value={template?.emailType ? template.emailType.includes('Approval') ? 'Confirmation' : 'Request' : '-'}
                                />
                            </PageGridItem>
                            <PageGridItem col="1 / span 2">
                                <DetailField
                                    onChange={(e) => null}
                                    label="Date Created"
                                    value={template?.createdAt ? format(new Date(template?.createdAt), 'dd/MM/yyyy') : '-'}
                                />
                            </PageGridItem>
                            <PageGridItem col="3 / span 2">
                                <DetailField
                                    onChange={(e) => null}
                                    label="Last Modified"
                                    value={template?.lastUpdatedAt ? format(new Date(template?.lastUpdatedAt), 'dd/MM/yyyy') : '-'}
                                />
                            </PageGridItem>
                            <PageGridItem col="1/5">
                                <label className="colour-text-label-grey transition-fade-up">Templates Using</label>
                                <Table
                                    dataMap={templatesUsingTable(template?.templates)}
                                    colWidths={['auto', '2rem']}
                                    compact
                                    wrapperStyle={{ marginTop: '1rem' }}
                                />
                            </PageGridItem>
                        </PageContentBodyGrid>
                    </PageGridItem>
                    <PageGridItem container col="5 / span 8">
                        <div style={{ padding: '20px 0px', background: colour, borderRadius: 20, width: 680, margin: '0 1rem' }}>
                            <iframe
                                width="680"
                                height={height - 276}
                                frameBorder="0"
                                srcDoc={template?.emailTemplateValue}
                            />
                            {/* <input ref={htmlUploadInputRef} onChange={handleFileUpload} type="file" style={{ display: "none" }} /> */}
                        </div>
                    </PageGridItem>
                </PageContentBodyGrid >
            }
        </Page >
    )
}

export default EmailTemplatePage;
import React, { useState, useEffect, useContext } from 'react';
import Page, { PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridItem from '../../Shared/Page/PageGridItem';
import { useParams } from 'react-router-dom';
import withRouteChange from "@threeskye/route-change";
import { FileText } from 'react-feather';
import DetailField from '../../Shared/DetailSection/DetailField';
import DetailFieldList from '../../Shared/DetailSection/DetailFieldList';
import DetailSectionHeader from '../../Shared/DetailSection/DetailSectionHeader';
import Button from '../../../components/Buttons/Button';
import { useRemote } from '../../../Utils/Utils';
import { BannerContext } from '../../../InternalApiApp';


const SchemePage = () => {
    const { setBannerBreadCrumbs } = useContext(BannerContext);
    const remote = useRemote();
    const { schemeId } = useParams()

    const [originalScheme, setOriginalScheme] = useState(null)
    const [scheme, setScheme] = useState(null)  // scheme with current edits
    const [editMode, setEditMode] = useState(false)

    useEffect(() => {
        remote.get(`/funds/schemes/${schemeId}`).then((scheme) => {
            setOriginalScheme(scheme)
            setScheme(scheme)
            setBannerBreadCrumbs({ tab: 'Schemes', param1: { name: scheme.schemeName, id: schemeId } })
        });
    }, [remote, schemeId])

    function saveEdits() {
        remote.post(`/funds/schemes/${schemeId}`, scheme).then((scheme) => {
            setOriginalScheme(scheme)
            setScheme(scheme)
        })
        setEditMode(false)
    }

    function cancelEdits() {
        setScheme(originalScheme)
        setEditMode(false)
    }

    function updateScheme(newValue, property) {
        // save temporary updates
        const newObj = { ...scheme, [property]: newValue }
        setScheme(newObj)
    }

    return (
        <Page noBanner>
            <PageContentBodyGrid rowGap="xl" gridColCount="12" subGrid>
                <PageGridItem container col="1 / span 6" style={{ marginTop: '1.5rem' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <DetailSectionHeader header="Scheme Details" icon={<FileText />} />
                        <div style={{ display: 'flex', gap: 5 }}>
                            <Button onClick={() => editMode ? saveEdits() : setEditMode(true)}>{editMode ? 'Save' : 'Edit'}</Button>
                            {editMode && <Button variant="secondary" onClick={cancelEdits}>Cancel</Button>}
                        </div>
                    </div>
                </PageGridItem>
                <PageGridItem col="1 / span 3">
                    <DetailField
                        bold
                        editMode={editMode}
                        onChange={(e) => updateScheme(e.target.value, "schemeName")}
                        label="Scheme Name"
                        value={scheme?.schemeName}
                    />
                </PageGridItem>
                <PageGridItem col="4 / span 3">
                    <DetailField
                        bold
                        editMode={editMode}
                        onChange={(e) => updateScheme(e.target.value, "schemeNumber")}
                        label="Scheme Number"
                        value={scheme?.schemeNumber}
                    />
                </PageGridItem>
                <PageGridItem col="1 / span 3">
                    <DetailField
                        bold
                        editMode={editMode}
                        onChange={(e) => updateScheme(e.target.value, "status")}
                        label="Status"
                        value={scheme?.status}
                    />
                </PageGridItem>
                <PageGridItem col="4 / span 3">
                    <DetailFieldList
                        bold
                        label="Managed Fund Subtypes"
                        value={scheme?.subTypes}
                    />
                </PageGridItem>
                <PageGridItem col="1 / span 3">
                    <DetailField
                        bold
                        editMode={editMode}
                        onChange={(e) => updateScheme(e.target.value, "dateAdded")}
                        label="Date Scheme Registered"
                        value={scheme?.dateAdded}
                    />
                </PageGridItem>
            </PageContentBodyGrid>
        </Page>
    );
}

export default withRouteChange(SchemePage);
import React, { useEffect, useState, useContext } from "react";
import { useStorage } from "../../../Utils/Utils";
import { useHistory } from "react-router-dom";
import { BannerContext } from "../../../InternalApiApp";
import "./TopBannerExtension.scss";


const TopBannerExtension = (props) => {
    const { header, icon, links, headerOnly } = props

    const storage = useStorage()
    const history = useHistory()
    const { bannerBreadCrumbs } = useContext(BannerContext);

    // TopBanner states
    const [subTab, setSubTab] = useState(null)
    const [me, setMe] = useState(null)


    useEffect(() => {
        storage.getOrFetch("/crm/me").then(setMe)
    }, [])

    useEffect(() => {
        if (bannerBreadCrumbs) {
            if (bannerBreadCrumbs.tab) setSubTab(bannerBreadCrumbs.tab)
        }
    }, [bannerBreadCrumbs])


    function selectTab(link) {
        setSubTab(link.name)
        history.push(link.path)
    }

    function isTheCorrectName(name) {
        return name === subTab
    }

    
    return (
        <div className={`top-banner-extension-outter${icon === null ? " with-left-extension" : ""}`}>
            <div className="top-banner-extension">
                <div className={`top-banner-ext-header ${headerOnly ? "no-tabs" : ""}`}>
                    {icon}
                    <h3 onClick={() => headerOnly ? selectTab(links[0]) : null} style={{ cursor: headerOnly ? "pointer" : "default" }}>{header}</h3>
                    {/*alternativeName*/ bannerBreadCrumbs?.param1?.name && headerOnly ? <>
                        <h3 className={`top-banner-ext-link`}>/</h3>
                        <h3 className={`top-banner-ext-link selected`} style={{ cursor: "default" }}>{bannerBreadCrumbs?.param1?.name}</h3>
                    </> : ""}
                </div>
                {!headerOnly && <div className="top-banner-ext-links">
                    {links.map((link) => {
                        if (!link.requires || (me && me.perms.includes(link.requires))) {
                            return bannerBreadCrumbs?.param1?.name && isTheCorrectName(link.name) ?
                                < div key={header + "-" + link.name} style={{ display: "flex", gap: "0.25rem" }}>
                                    <h3 className={`top-banner-ext-link`} onClick={() => selectTab(link)}>{link.name}</h3>
                                    <h3 className={`top-banner-ext-link`}>/</h3>
                                    <h3 className={`top-banner-ext-link ${!bannerBreadCrumbs?.param2?.name ? "selected" : ""}`} onClick={() => { history.push(link.name === "Library" ? null : link.name === "Users" ? link.path + "/user/" + bannerBreadCrumbs?.param1?.id : link.path + "/" + bannerBreadCrumbs?.param1?.id); }}>
                                        {bannerBreadCrumbs?.param1?.name}
                                    </h3>
                                    {bannerBreadCrumbs?.param2?.name && <h3 className={`top-banner-ext-link`}>/</h3>}
                                    {bannerBreadCrumbs?.param2?.name && <h3 className={`top-banner-ext-link selected truncate`}>{bannerBreadCrumbs?.param2?.name}</h3>}
                                </div> : <h3 key={header + "-" + link.name} className={`top-banner-ext-link ${subTab === link.name ? "selected" : ""}`} onClick={() => selectTab(link)}>{link.name}</h3>
                        }
                        return ""
                    })}
                </div>}
            </div>
        </div >
    )
}

export default TopBannerExtension;
import React, { useEffect, useState } from 'react';
import FlexWrapper from '../FlexWrapper';
import Table from '../../components/Table/Table';
import "./WorkflowComplianceActions.scss";
import UserListModal from './UserListModal';
import { useRemote } from '../../Utils/Utils';
import LoadingIcon from '../../components/LoadingIcon';
import WorkflowComplianceStepRow from './WorkflowComplianceStepRow';

const WorkflowComplianceActions = ({ workflowId, taskId, isComplete, historical }) => {
    const [data, setData] = useState(null)
    const [approversList, setApproversList] = useState([])
    const [loading, setLoading] = useState(true)

    const displayedData = data ? data : []

    const remote = useRemote()

    useEffect(() => {
        remote.get(`workflows/workflow/${workflowId}/workflowTask/${taskId}`).then((res) => {
            setData(res.data);
            setLoading(false)
        })
    }, [])

    const refreshList = (fund) => {
        remote.get(`workflows/workflow/${workflowId}/workflowTask/${taskId}`).then((res) => {
            setData(res.data);
        })
    }

    const stepHeaders = historical ?
        ["Action", "Approvers Req", "Funds Approved", "Progress", ""] :
        ["Action", "Approvers Req", "Approvers Available", "Funds Approved", "Progress", ""]
    const stepWidths = historical ?
        ["370px", "350px", "210px", "380px", "50px"] :
        ["260px", "250px", "250px", "200px", "300px", "50px"]

    const stepDataMap = displayedData && displayedData.map((step, idx) => <WorkflowComplianceStepRow refreshList={refreshList} step={step} key={step.name + "-" + idx} idx={idx} taskId={taskId} setApproversList={setApproversList} historical={historical} />)

    if (loading) {
        return (
            <>
                <LoadingIcon centered />
            </>
        )
    }

    return (
        <>
            {approversList && approversList.length > 0 && <UserListModal handleClose={() => setApproversList([])} data={approversList} />}
            <Table
                tableStyle={{ maxWidth: 2000 }}
                wrapperStyle={{ marginBottom: "6rem" }}
                colWidths={stepWidths}
                headers={stepHeaders}
                dataMap={stepDataMap}
                minWidth={1425}
            />
        </>
    )
}

export default WorkflowComplianceActions
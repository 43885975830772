import React, { useState } from "react";
import CollapseButton from "../../Buttons/CollapseButton";
import SidebarExtensionLinkList from "./SideBarExtensionLinkList";
import SideBarExtensionHeader from "./SideBarExtensionHeader"
import { withStorage } from "../../../Utils/Utils";
import "./SidebarExtensionWrapper.scss";

const SidebarExtensionWrapper = (props) => {
	const [slideInState, setSlideInState] = useState(false)
	const [slideOutState, setSlideOutState] = useState(false)
	const { links, header, noMargin, children, position, footer, noPadding, shadow, style, slideIn, slideOut, fixedHeader, collapsable, sideBarLeft, rightExtSize, leftSideBarCollapsed, setLeftSideBarCollapsed } = props;

	const handleCollapsed = (bool) => {
		switch (bool) {

			case true:
				setSlideInState(false)
				setTimeout(() => { setSlideInState(true) }, 10)

				setTimeout(() => {
					setLeftSideBarCollapsed(!bool)
					// props.storage.put("research-master-grid", "sideBarExtLeftActive")
				}, 250)
				break;

			case false:
				setTimeout(() => {
					setLeftSideBarCollapsed(!bool)
					// props.storage.put("research-master-grid", "sideBarExtLeftInactive")
				}, 250)
				break;

			default:
				break;
		}
	}

	return (
		<>
			<div
				style={header && header.header === 'Admin' ? {borderRight: '1px solid #E7EAF2'} : style}
				className={`sidebar-extension-wrapper${(slideInState || slideIn) ? (sideBarLeft ? " sidebar-extension-slide-in-right" : " sidebar-extension-slide-in") : ""}${(slideOutState || slideOut) ? (sideBarLeft ? " sidebar-extension-slide-out-right" : " sidebar-extension-slide-out") : " "}${footer ? " sidebar-extension-wrapper-with-footer" : ""
					}${position ? " position-" + position : ""}${leftSideBarCollapsed ? " collapsed" : ""}${collapsable ? " collapsable" : ""}${noPadding ? " sidebar-extension-wrapper-no-padding" : ""}${shadow ? " sidebar-extension-wrapper-shadow" : ""
					}${fixedHeader ? " sidebar-extension-fixed-header" : ""
					}${rightExtSize ? " right-ext-" + rightExtSize : ""}`}
			>
				{header && <SideBarExtensionHeader collapsable={collapsable ? <CollapseButton onClick={() => handleCollapsed(leftSideBarCollapsed)} collapsed={leftSideBarCollapsed} /> : null} icon={header.icon} header={header.header} noMargin={noMargin} border={header.border}/>}
				{links && <SidebarExtensionLinkList links={links} />}
				{children && children}
				{collapsable && <CollapseButton outer onClick={() => handleCollapsed(leftSideBarCollapsed)} collapsed={leftSideBarCollapsed} />}
			</div>
		</>
	);
}

export default withStorage(SidebarExtensionWrapper)
import React, { useEffect, useState } from 'react';
import { useRemote } from '../../Utils/Utils';
import LoadingIcon from '../../components/LoadingIcon';
import { ArrowRight, CheckCircle, Paperclip } from 'react-feather';
import { Link } from 'react-router-dom';
import Divider from '../../components/Divider';
import FlexWrapper from '../FlexWrapper';
import { format, formatISO, parseISO } from 'date-fns';
import Tooltip from '../../components/popups/Tooltip';
import { toastDanger, toastInfo, toastSuccess } from '../../components/popups/Toast';


const WorkflowDataSynchronisation = ({ workflowId, taskId, isComplete }) => {

    const [data, setData] = useState(null);
    const remote = useRemote()

    const downloadData = (fund) => {
        remote.get(`/data/upload/document/${fund.batchId}/${fund.uploadedFile.fileName}`).then((res) => {
            const link = document.createElement('a');
            link.setAttribute('href', res.url);
            link.setAttribute('download', fund.uploadedFile.fileName);
            link.click()
        })
        .catch((res) => {
            toastDanger("Couldn't Retrieve Data")
        })
    }

    useEffect(() => {
        remote.get(`workflows/workflow/${workflowId}/workflowTask/${taskId}`).then((res) => {
            setData(res);
        })

        const interval = setInterval(() => {
            remote.get(`workflows/workflow/${workflowId}/workflowTask/${taskId}`).then((res) => {
                setData(res);
            })
        }, 30000)

        return () => clearInterval(interval)
    }, [])

    if (!data) return <><LoadingIcon centered /></>

    return (
        <>
            {data.data.map((offer) => {
                return (
                    <>
                        {isComplete
                            ? offer.succeeded && offer.succeeded.length > 0
                            && <>
                                <p style={{ fontSize: "12px", opacity: 0.5, lineHeight: "12px", marginBottom: "1rem", marginTop: "1rem" }} >{offer.offerName}</p>
                                <div style={{ gap: "0px" }} className='mb-m colour-text-neutral-dark flex-align-center' >
                                    <span className='text-bold mr-s'>Uploaded Data ({offer.succeeded.length})</span>
                                </div >
                                <div className='worflow-data-synchronisation-task'>
                                    {offer.succeeded.map((fund, idx) => {
                                        return <FlexWrapper className={`succeeded-fund ${idx == offer.succeeded.length - 1 ? "mb-xl" : ""}`} justify="space-between" >
                                            <FlexWrapper className='flex-align-center' >
                                                {fund.name}
                                            </FlexWrapper >
                                            <FlexWrapper style={{ width: "40%", minWidth: "fit-content" }} justify="space-between" >
                                                <span className="mr-xl" ><span className='text-bold' >{fund?.uploadedBy?.name ? fund.uploadedBy.name : "Unknown Uploader "}</span> at {fund.uploadedDate ? format(parseISO(fund.uploadedDate), "hh:mmaaa, dd/MM/yyyy") : "Unknown Date"}</span>
                                                <Tooltip label={fund?.uploadedFile?.fileName ? <p>Download Uploaded Data: <span className='font-weight-semi-bold'>{fund.uploadedFile.fileName}</span></p> : <p>Download Uploaded Data</p> }  >
                                                    <Paperclip onClick={() => downloadData(fund)} />
                                                </Tooltip>

                                            </FlexWrapper>
                                        </FlexWrapper>
                                    })}
                                </div>
                            </>
                            : offer.missing && offer.missing.length > 0
                                ? <>
                                    <p style={{ fontSize: "12px", opacity: 0.5, lineHeight: "12px", marginBottom: "1rem", marginTop: "1rem" }} >{offer.offerName}</p>
                                    <Link to="/data" className='mb-m text-danger-dark flex-align-center' >
                                        <span className='text-bold mr-xs'>Missing Data</span>
                                        <span className="mr-s" >{` (${offer.missing.length})`}</span>
                                        <ArrowRight color="#A0001E" size={20} />
                                    </Link >
                                    <div className='worflow-data-synchronisation-task'>
                                        {offer.missing.map((fund, idx) => {
                                            return <Link className={`missing-fund ${idx == offer.missing.length - 1 ? "mb-xl" : ""}`} to="/data" >{fund.name}</Link>
                                        })}
                                    </div>
                                </>
                                : <>
                                    <p style={{ fontSize: "12px", opacity: 0.5, lineHeight: "12px", marginBottom: "1rem", marginTop: "1rem" }} >{offer.offerName}</p>
                                    <div className='mb-m'><span className='text-bold'>No Missing Data</span></div>
                                </>
                        }

                    </>
                )
            })}
        </>
    )
}

export default WorkflowDataSynchronisation
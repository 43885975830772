import React from 'react';
import Tooltip from '../../popups/Tooltip';
import Table from '../../Table/Table';
import SidebarExtensionWrapper from './SideBarExtensionWrapper';
import SideBarSection from './SideBarSection';
import FlexWrapper from '../../../layouts/FlexWrapper';

const DataSidebarExtension = (props) => {
	const { slideIn, slideOut, contentData } = props;

	const parseJwt = token => {
		if (!token) return "";
		var base64Url = token.split('.')[1];
		var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
		var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
			return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
		}).join(''));

		return JSON.parse(jsonPayload);
	};

	const sessionToken = sessionStorage.getItem("3skye.auth.token");
	const bits = parseJwt(sessionToken);

	const isCraigs = bits && bits.email ? bits.email.indexOf('craigs') > 0 : null;


	const dataMap = Object.entries(contentData)
		.filter((key) => isCraigs ? key[0].startsWith("F") : true)
		.map((key, value) => {
			const label = isCraigs ? key[0].substring(1) : key[0];
			return (
				<tr>
					<td className="cursor-default"><Tooltip truncate delay={500} label={label}>{label ? label : "-"}</Tooltip></td>
					<td className="cursor-default"><Tooltip truncate delay={500} label={key[1]}>{key[1] ? key[1] : "-"}</Tooltip></td>
				</tr>
			);
		});

	return (
		<SidebarExtensionWrapper noPadding position="right" shadow slideIn={slideIn} slideOut={slideOut}>
			<SideBarSection header="Data">
				<Table
					compact
					fixedHeaders
					height="calc(100vh - 8rem)"
					colWidths={['40%', '60%']}
					headers={["Field", "Value"]}
					dataMap={dataMap}
					NoResultsComponentOverride={() => <FlexWrapper fullWidth justify="center" align="center" gap="m">
						<p style={{ color: "#adb1bd" }}>No data to display</p>
					</FlexWrapper>}
				/>
			</SideBarSection>
		</SidebarExtensionWrapper>
	);
}

export default DataSidebarExtension;
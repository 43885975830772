import React, { useState } from 'react';
import { AlertCircle, FileText, Mail, Send } from 'react-feather';
import Table from '../../../components/Table/Table';
import PageGridItem from '../Page/PageGridItem';
import useWindowDimensions, { formatDate, formatDateTime, onDocumentClick } from '../../../Utils/Utils';
import DetailSectionHeader from '../DetailSection/DetailSectionHeader';
import SuggestionMessage from '../../../components/EmptyMessage/SuggestionMessage';
import NoResultsMessage from '../../../components/EmptyMessage/NoResultsMessage';
import TableActionIcon from '../../../components/Table/TableActionIcon';
import PdfIcon from '../../../images/icons/PdfIcon';
import IconCounter from '../../../components/IconCounter';
import FlexWrapper from '../../FlexWrapper';
import SearchTermFilter from '../../../components/Input/SearchTermFilter';

const MiniResearchList = ({ onCraigsDocClick, editMode, data, suggestionText, restrictHeight, listHeader, showAnalyticsIcons }) => {
	const [filteredData, setFilteredData] = useState([])
	const [hasSearchTerm, setHasSearchTerm] = useState([])
	const { width } = useWindowDimensions();
	const responsiveColWidth = width > 1200 && width < 1430;
	const colWidths = showAnalyticsIcons ? [25 + "%", responsiveColWidth ? "30%" : "60%", 15 + "%", 10 + "%"] : [25 + "%", 65 + "%", 10 + "%"];

	const dataMap = (dataToMap) => dataToMap && dataToMap.map((research, idx) => {
		const hasPdf = research && research.pdfId;

		const delivered = research.receivedDate;
		const open = research.openedDate;
		const downloaded = research.downloadedDate;

		return (
			<tr key={idx} className={editMode ? "" : "cursor-pointer"}>
				<td>
					{formatDate(research.publishedDate)}
				</td>
				<td>
					<div className="truncate">
						{research.title ? research.title : "-"}
					</div>
				</td>
				{showAnalyticsIcons && <td>
					<FlexWrapper gap="m">
						{delivered
							? <IconCounter iconOnly inactive={false} tooltip={`Delivered at ${formatDateTime(delivered)}`} status="delivered" icon={<Send />} dataAvailable={true} />
							: <IconCounter iconOnly inactive={false} tooltip="Failed" status="fail" icon={<AlertCircle />} dataAvailable={true} />
						}
						<IconCounter iconOnly inactive={!open} tooltip={open ? `Opened at ${formatDateTime(open)}` : "Not opened"} status="read" icon={<Mail />} dataAvailable={true} />
						<IconCounter iconOnly inactive={!downloaded} tooltip={downloaded ? `Read at ${formatDateTime(downloaded)}` : "Not downloaded"} status="read" icon={<FileText />} dataAvailable={true} />
					</FlexWrapper>
				</td>}
				<td className="table-action-icons">
					{hasPdf && <TableActionIcon onClick={() => onDocumentClick({ "id": research.pdfId })} icon={<PdfIcon width="24px" />} toolTip="PDF download" />}
					{/*<TableActionIcon onClick={() => alert("data download")} icon={<XlsIcon />} toolTip="Data download" />*/}
				</td>
			</tr>
		);
	})

	const researchLength = data && data.length;
	const noData = !data || researchLength <= 0;
	const noSearchResults = false;

	return (
		<>
			<PageGridItem col="1 / span 6" justifyContent="between" gap="m" wrap rowSpan="1">
				<DetailSectionHeader icon={<Mail />} header={`${listHeader ? listHeader : "Published"} (${noData ? "0" : researchLength})`} />
				{!noData && (
					<SearchTermFilter
						size="small"
						width="l"
						placeholder={`Search publications ${ "(" + data.length + ")"}`}
						isClearable
						dataSet={data}
						setFilteredData={setFilteredData}
						path={["title"]}
						hasSearchTerm={(boolean) => setHasSearchTerm(boolean)}
					/>
				)}
			</PageGridItem>
			<PageGridItem col="1 / span 6" rowSpan="3">
				{noData ? (
					<SuggestionMessage
						message={
							<>
								{suggestionText ? suggestionText : "No published documents to display."}
							</>
						}
					/>
				) : noSearchResults ? (
					<NoResultsMessage compact />
				) : (
					<Table restrictHeight={restrictHeight} colWidths={colWidths} dataMap={dataMap(hasSearchTerm ? filteredData : data)} />
				)}
			</PageGridItem>
		</>
	);
}

export default MiniResearchList;
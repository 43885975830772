import React, { useEffect, useRef, useState, createContext } from "react";
import "./App.scss";
import "./styles/UtilClasses.scss";
import "./styles/DesignTokenClasses.scss";
import BuilderPage from "./layouts/BuilderPage";
import DataController from "./DataController";
import { ThreeSkyeWrapper } from "@threeskye/global";
import { withRouter } from "react-router";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import NoAccess from "./NoAccess";
import MenuWrapper from "./layouts/MenuWrapper";
import CraigsLibraryPage from "./layouts/CraigsLibraryPage/CraigsLibraryPage";
import AdminPage from "./layouts/AdminPage/AdminPage";
import DistributionList from "./layouts/CompanyUserManagement/Distribution/DistributionList";
import SettingsProfile from "./layouts/Settings/Profile/SettingsProfilePage";
import SettingsGeneralPage from "./layouts/Settings/General/SettingsGeneralPage";
import UsersLists from "./layouts/CompanyUserManagement/User/UsersLists";
import UserPage from "./layouts/CompanyUserManagement/User/UserPage";
import CompanyLists from "./layouts/CompanyUserManagement/Company/CompanyLists";
import CompanyPage from "./layouts/CompanyUserManagement/Company/CompanyPage";
import ToastContainer from "./components/popups/ToastContainer";
import DistributionListPage from "./layouts/CompanyUserManagement/Distribution/DistributionListPage";
import ErrorPage from "./layouts/ErrorPage";
import DeliveryDetails from "./layouts/CraigsLibraryPage/DeliveryDetails";
import SuppressionList from "./layouts/CompanyUserManagement/Distribution/SuppressionList";
// Analytics
import AnalyticsPage from "./layouts/Analytics/AnalyticsPage";
import AnalyticsTickersList from "./layouts/Analytics/Tickers/AnalyticsTickersList";
import AnalyticsGroupList from './layouts/Analytics/Tickers/AnalyticsGroupList';
import AnalyticsTickerPage from "./layouts/Analytics/Tickers/AnalyticsTickerPage"
import AnalyticsTickerResearchViews from "./layouts/Analytics/Tickers/AnalyticsTickerResearchViews"
import AnalyticsUsersList from "./layouts/Analytics/Users/AnalyticsUsersList";
import AnalyticsUserPage from "./layouts/Analytics/Users/AnalyticsUserPage";
import AnalyticsUserResearchViews from "./layouts/Analytics/Users/AnalyticsUserResearchView"
import AnalyticsCompaniesList from "./layouts/Analytics/Companies/AnalyticsCompaniesList";
import AnalyticsCompanyPage from "./layouts/Analytics/Companies/AnalyticsCompanyPage";
import DraftsLibrary from "./layouts/Drafts/DraftsLibrary";
import RequestsLibrary from "./layouts/Drafts/RequestsLibrary";
import { unimpersonate, useStorage, hasFeature, hasOneOfFeatures, useRemote, withRemote } from "./Utils/Utils";
import AnalyticsAuthorsList from "./layouts/Analytics/Authors/AnalyticsAuthorsList";
import AnalyticsAuthorPage from "./layouts/Analytics/Authors/AnalyticsAuthorPage";
import NoPermissionsPage from "./layouts/NoPermissionsPage";
import CheckAuthorisation from "./CheckAuthorisation";
import Login from "./Login";
import ActiveWorkflowsPage from "./layouts/ActiveWorkflowsPage/ActiveWorkflowsPage";
import DataManagerPage from "./layouts/AdminPage/DataManagement/DataManagerPage";
import SockJsClient from 'react-stomp';
import { useWebsockets } from "./Utils/WebsocketReceiverHook";

export const PAGES = {
	EDITOR: "editor",
	LIBRARY: "library",
	DRAFTS: "drafts",
	ADMIN: "admin",
	CRM: "crm",
	ANALYTICS: "analytics",
	DRAFTS: "drafts",
	REQUESTS: "requests",
	ACTIVE_WORKFLOWS: "active-workflows",
	DATA_MANAGEMENT: "data"
};

export const ROUTE_PATHS = {
	[PAGES.EDITOR]: "/editor",
	[PAGES.LIBRARY]: "/",
	[PAGES.DRAFTS]: "/drafts",
	[PAGES.ADMIN]: "/admin",
	[PAGES.CRM]: "/crm",
	[PAGES.SETTINGS]: "/settings",
	[PAGES.ANALYTICS]: "/analytics",
	[PAGES.DRAFTS]: "/drafts",
	[PAGES.REQUESTS]: "/requests",
	[PAGES.ACTIVE_WORKFLOWS]: "/active-workflows",
	[PAGES.DATA_MANAGEMENT]: "/data",

};

export const BannerContext = createContext({ bannerBreadCrumbs: null, setBannerBreadCrumbs: () => {} });

const AuthIntercept = () => {

	const [authenticated, setAuthenticated] = useState(false);
	const [me, setMe] = useState(null);
	const [socketAuth, setSocketAuth] = useState("");
	const [topMessage, setTopMessage] = useState(null);
	const [organisation, setOrganisation] = useState(null);
	const [loadingRole, setLoadingRole] = useState(true);
	const [bannerBreadCrumbs, setBannerBreadCrumbs] = useState(null)
	const [isConnected, setIsConnected] = useState(false);
	const [reconnectionAttempted, setReconnectionAttempted] = useState(0);

	const remote = useRemote();
	const clientRef = useRef(null)
	const wsSourceUrl = "https://" + window.location.host + "/websocket?"+socketAuth;
	const sockets = useWebsockets();

	const customSocketHeaders = {
		"Authorization": sessionStorage.getItem('3skye.auth.token')
	}

	const handleSocketMessage = ((msg, topic) => {
		sockets.handleMessage(topic, msg);
	})

	function readCookie(name) {
		var nameEQ = name + "=";
		var ca = document.cookie.split(';');
		for(var i=0;i < ca.length;i++) {
			var c = ca[i];
			while (c.charAt(0)==' ') c = c.substring(1,c.length);
			if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
		}
		return null;
	}

	const handleConnect = () => {
		setIsConnected(true);
		setReconnectionAttempted(0);
	};

	const handleDisconnect = () => {
		setIsConnected(false);
	};

	useEffect(() => {
		if (!authenticated) {
			setSocketAuth(""); // Reset socket auth if not authenticated
			if (isConnected && clientRef.current) {
				clientRef.current.disconnect(); // Disconnect WebSocket if logged out
			}
			return;
		}

		remote.registerLogoutHandler(handleLogout);
		remote.get("crm/company").then(setOrganisation);
		remote.get("crm/me").then((me) => {
			setMe(me);
			remote.get("crm/socket-auth").then((auth) => {
				setSocketAuth(auth.totp);
				setLoadingRole(false);
			})
		});
	}, [authenticated])

	// Visibility change detection and reconnection
	useEffect(() => {
		const handleVisibilityChange = () => {
		if (document.visibilityState === 'visible') {
			if (authenticated && !isConnected && clientRef.current && socketAuth) {
			// console.log('Attempting to reconnect WebSocket...');
			remote.get("crm/socket-auth").then((auth) => {
				setSocketAuth(auth.totp);
			})
			// clientRef.current.connect(); // Reconnect only if authenticated and TOTP is available
			}
		} else {
			// console.log('Tab became hidden at', new Date().toISOString());
		}
		};

		document.addEventListener('visibilitychange', handleVisibilityChange);
		return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
	}, [authenticated, isConnected, socketAuth]);

	const handleLogout = () => {
		setAuthenticated(false);
	}

	const sendMessage = (msg) => {
		clientRef.current.sendMessage('/app/test', msg);
	}

	if (!authenticated || loadingRole) {
		return <CheckAuthorisation setAuthorised={setAuthenticated} />
	}
	const userHasNoPermissions = me === null || (me && me.perms && me.perms.length < 1)
	return (
		<BannerContext.Provider value={{ bannerBreadCrumbs: bannerBreadCrumbs, setBannerBreadCrumbs: setBannerBreadCrumbs }}>

			<MenuWrapper topComponent={topMessage} user={me} updateUser={setMe} noPermissions={userHasNoPermissions} organisation={organisation} sendMessage={sendMessage}>
				<SockJsClient
					headers={{ credentials: "same-origin" }}
					url={wsSourceUrl}
					topics={['/topic/broadcast', '/user/topic/message', '/user/topic/updates', '/topic/updates']}
					onMessage={handleSocketMessage}
					ref={(client) => { clientRef.current = client }}
					onConnect={() => { handleConnect() }}
					onDisconnect={()=> { handleDisconnect() }}
					autoReconnect={reconnectionAttempted < 5}
					onConnectFailure={(err) => { setReconnectionAttempted(reconnectionAttempted + 1); }}
					debug={false}
				/>
				<Switch>
					{userHasNoPermissions && < Route path="*" component={NoPermissionsPage} noPermissions={userHasNoPermissions} />}
					<Route
						exact
						path={ROUTE_PATHS[PAGES.EDITOR]}
						render={() => (
							<ProtectedRoute user={me} requiredFeature={"EDIT"}>
								<DataController internalApi={true} setTopMessage={setTopMessage}>
									<BuilderPage organisation={organisation} setTopMessage={setTopMessage} />
								</DataController>
							</ProtectedRoute>
						)}
					/>

					{/* Settings and Profile Pages */}
					<Route path={ROUTE_PATHS[PAGES.SETTINGS] + "/profile"} component={SettingsProfile} />
					<Route path={ROUTE_PATHS[PAGES.SETTINGS] + "/general"} component={SettingsGeneralPage} />

					{/* Drafts Workspace/Requests Routes */}
					<Route exact path={ROUTE_PATHS[PAGES.DRAFTS]} component={hasOneOfFeatures(["DRAFTS_ANALYST", "DRAFTS_PEER"], DraftsLibrary, me)} />
					<Route exact path={ROUTE_PATHS[PAGES.REQUESTS]} component={hasOneOfFeatures(["REVIEW_PEER", "REVIEW_COMPLIANCE", "REVIEW_SUPERVISOR"], RequestsLibrary, me)} />

					{/* ANALYCTICS Routes */}
					<Route path={ROUTE_PATHS[PAGES.ANALYTICS] + "/dashboard"} component={hasFeature("ANALYTICS_VIEW", AnalyticsPage, me)} />
					<Route exact path={ROUTE_PATHS[PAGES.ANALYTICS] + "/authors"} component={hasFeature("ANALYTICS_VIEW", AnalyticsAuthorsList, me)} />
					<Route exact path={ROUTE_PATHS[PAGES.ANALYTICS] + "/tickers"} component={hasFeature("ANALYTICS_VIEW", AnalyticsTickersList, me)} />
					<Route
						exact
						path={ROUTE_PATHS[PAGES.ANALYTICS] + "/authors/author/:authorId"}
						render={(props) =>
							<ProtectedRoute user={me} requiredFeature={"ANALYTICS_VIEW"}>
								<AnalyticsAuthorPage datatita={props} />
							</ProtectedRoute>
						} />
					<Route
						exact
						path={ROUTE_PATHS[PAGES.ANALYTICS] + "/tickers/:tickerId"}
						render={(props) =>
							<ProtectedRoute user={me} requiredFeature={"ANALYTICS_VIEW"}>
								<AnalyticsTickerPage datatita={props} />
							</ProtectedRoute>
						} />
					<Route exact path={ROUTE_PATHS[PAGES.ANALYTICS] + "/group"} component={hasFeature("ANALYTICS_VIEW", AnalyticsGroupList, me)} />
					<Route
						exact
						path={ROUTE_PATHS[PAGES.ANALYTICS] + "/group/:groupId"}
						render={(props) =>
							<ProtectedRoute user={me} requiredFeature={"ANALYTICS_VIEW"}>
								<AnalyticsTickerPage datatita={props} />
							</ProtectedRoute>
						}
					/>
					<Route
						path={ROUTE_PATHS[PAGES.ANALYTICS] + "/tickers/:tickerId/research/:researchId"}
						render={(props) =>
							<ProtectedRoute user={me} requiredFeature={"ANALYTICS_VIEW"}>
								<AnalyticsTickerResearchViews datatita={props} />
							</ProtectedRoute>
						} />
					<Route
						path={ROUTE_PATHS[PAGES.ANALYTICS] + "/group/:groupId/research/:researchId"}
						render={(props) =>
							<ProtectedRoute user={me} requiredFeature={"ANALYTICS_VIEW"}>
								<AnalyticsTickerResearchViews datatita={props} />
							</ProtectedRoute>
						} />
					<Route
						path={ROUTE_PATHS[PAGES.ANALYTICS] + "/users/user/:userId/research/:researchId"}
						render={(props) =>
							<ProtectedRoute user={me} requiredFeature={"ANALYTICS_VIEW"}>
								<AnalyticsUserResearchViews datatita={props} />
							</ProtectedRoute>
						} />

					<Route exact path={ROUTE_PATHS[PAGES.ANALYTICS] + "/users"} component={hasFeature("ANALYTICS_VIEW", AnalyticsUsersList, me)} />
					<Route path={ROUTE_PATHS[PAGES.ANALYTICS] + "/users/user/:userId"} component={hasFeature("ANALYTICS_VIEW", AnalyticsUserPage, me)} />
					<Route exact path={ROUTE_PATHS[PAGES.ANALYTICS] + "/companies"} component={hasFeature("ANALYTICS_VIEW", AnalyticsCompaniesList, me)} />
					<Route path={ROUTE_PATHS[PAGES.ANALYTICS] + "/companies/company/:companyId"} component={hasFeature("ANALYTICS_VIEW", AnalyticsCompanyPage, me)} />

					{/* Admin Routes - subpages contained inside <AdminPage /> */}
					<Route
						path={ROUTE_PATHS[PAGES.ADMIN]}
						render={() =>
							<ProtectedRoute user={me} requiredFeature={["TICKERS", "MODEL_PORTFOLIOS", "ASSET_CLASS_MGT", "SECTORS", "DISCLAIMER", "USER_MANAGEMENT", "COMPANY_MANAGEMENT", "ROLES", "TEMPLATES"]} >
								<AdminPage me={me} organisation={organisation} />
							</ProtectedRoute>
						} />

					{/* CRM Routes */}
					<Route exact path={ROUTE_PATHS[PAGES.CRM] + "/users"} component={hasFeature("USERS", UsersLists, me)} />
					<Route path={ROUTE_PATHS[PAGES.CRM] + "/users/user/:userId"} component={hasFeature("USERS", UserPage, me)} />
					<Route exact path={ROUTE_PATHS[PAGES.CRM] + "/companies"} component={hasFeature("COMPANIES", CompanyLists, me)} />
					<Route path={ROUTE_PATHS[PAGES.CRM] + "/companies/company/:companyId"} component={hasFeature("COMPANIES", CompanyPage, me)} />
					<Route exact path={ROUTE_PATHS[PAGES.CRM] + "/distribution"} component={hasFeature("DISTRIBUTION", DistributionList, me)} />
					<Route exact path={ROUTE_PATHS[PAGES.CRM] + "/distribution/distribution-list/:distributionListId"} component={hasFeature("DISTRIBUTION", DistributionListPage, me)} />
					<Route exact path={ROUTE_PATHS[PAGES.CRM] + "/suppressions"} component={hasFeature("SUPPRESSED_EMAILS", SuppressionList, me)} />

					{/* Process Dashboard */}
					<Route path={ROUTE_PATHS[PAGES.ACTIVE_WORKFLOWS]} component={ActiveWorkflowsPage} />
					{/* Data Manager */}
					<Route exact path={ROUTE_PATHS[PAGES.DATA_MANAGEMENT]} component={hasFeature("DATA_MANAGEMENT", DataManagerPage, me)} />

					{/* Library Pages */}
					<Route path={ROUTE_PATHS[PAGES.CRM] + "/delivery/:deliveryId"} component={DeliveryDetails} />
					<Route exact component={hasFeature("LIBRARY_VIEW", CraigsLibraryPage, me)} />
					{/* <Route path={ROUTE_PATHS[PAGES.LIBRARY] + "publication/:publicationId"} component={PublicationView} /> */}

					{/* 404/error page */}
					<Route path="*" component={ErrorPage} />
				</Switch>
			</MenuWrapper>
		</BannerContext.Provider>
	);
}

const ProtectedRoute = ({ user, requiredFeature, children }) => {

	if (Array.isArray(requiredFeature)) {
		if (user && user.perms && requiredFeature.some(f => { return user.perms.includes(f) })) {
			return children;
		}
	} else if (user && user.perms && user.perms.includes(requiredFeature)) {
		return children;
	}

	return <ErrorPage />
};

const ImpersonationWrapper = ({ remote, ...others }) => {
	const storage = useStorage();

	const logout = (storage) => {
		unimpersonate(storage);
	}

	return <AuthIntercept logout={() => logout(storage)} remote={remote} {...others} />
}

const InternalApiApp = () => {
	const remote = useRemote();

	const component = window.sessionStorage.getItem("3skye.auth.super.token") !== null ? ImpersonationWrapper : AuthIntercept;
	const hocd = withRemote(withRouter(component));

	return (
		<ThreeSkyeWrapper disableI18n={true}>
			<ToastContainer />
			<BrowserRouter>
				<Switch>
					{/* <Route exact path="/authorise/:token" component={Authorise} />
						<Route exact path="/authorise" component={CheckAuthorisation} />
						<Route path="/ext/:comp" component={Extensions} /> */}
					<Route path="/no-access" component={NoAccess} />
					<Route exact path="/sign-in" component={Login} />
					<Route path="/sign-in/:serviceName" component={Login} />
					<Route component={hocd} />
				</Switch>
			</BrowserRouter>
		</ThreeSkyeWrapper>
	);
}

export default (InternalApiApp);

import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import React, { useEffect, useState, useCallback } from 'react';
import DetailSectionHeader from '../../Shared/DetailSection/DetailSectionHeader';
import SearchTermFilter from '../../../components/Input/SearchTermFilter';
import DataUploadTable from './DataUploadTable';
import PageGridItem from '../../Shared/Page/PageGridItem';
import Button from '../../../components/Buttons/Button';
import { toastDanger, toastSuccess } from '../../../components/popups/Toast';
import { onDropBatch, useRemote } from '../../../Utils/Utils';
import { RefreshCw } from 'react-feather';
import LoadingIcon from '../../../components/LoadingIcon';
import SideBarExtensionHeader from '../../../components/SideBar/SideBarExtension/SideBarExtensionHeader';
import Divider from '../../../components/Divider';
import { useWebsockets } from '../../../Utils/WebsocketReceiverHook';
import "./DataManagerPage.scss";

const DataManagerPage = () => {
    const [uploadsProcessing, setUploadsProcessing] = useState(null);
    const [filteredUploads, setFilteredUploads] = useState([]);
    const [hasSearchTerm, setHasSearchTerm] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [loading, setLoading] = useState(true)

    const remote = useRemote()

    const sockets = useWebsockets();

    const getUploads = useCallback(async function getUploads() {
        await remote.get(`data/listBatches`).then((response) => {
            setUploadsProcessing(response);
            setLoading(false)
        }).catch((error) => {
            setUploadsProcessing([])
            setLoading(false)
            console.error("Front end encountered an error getting the uploads: ", error);
        });
    }, [remote]);

    useEffect(() => {
        getUploads();
        sockets.subscribe({
            "/api/data/listBatches": [() => getUploads()]
        })
        return () => sockets.unsubscribe(["/api/data/listBatches"]);

    }, [getUploads]);

    function uploadData() {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.style.display = 'none';
        fileInput.multiple = true; // Allow multiple file selection
        fileInput.addEventListener('change', (event) => {
            if (event.target.files.length > 0) {
                let formData = new FormData()
                let uploaded = [];
                let rejected = [];
                for (let i = 0; i < event.target.files.length; i++) {
                    if (event.target.files[i].name.split('.').pop() !== 'xlsx') {
                        rejected.push(event.target.files[i].name);
                        continue;
                    }
                    uploaded.push(event.target.files[i].name);
                    formData.append('files', event.target.files[i]);
                }

                onDropBatch(formData, `/data/upload/bulk`, {
                    onUploadComplete: (event, response) => {
                        getUploads();
                        const resp = JSON.parse(response);
                        if (resp.data) {
                            resp.data?.success > 0 && toastSuccess(`Uploaded ${resp.data.success} files`);
                            resp.data?.failed > 0 && toastDanger(`Failed to upload ${resp.data.failed} files`);
                        }
                    },
                    onUploadError: (e, xhr) => {
                        toastDanger("Upload Error");
                    },
                    setLoading: setUploading
                })
            }
        });

        document.body.appendChild(fileInput);
        fileInput.click();
    }


    return <Page fixedBanner>
        <PageContentBanner gap="m" divider>
            <SideBarExtensionHeader header='Data Manager' icon={<RefreshCw className='colour-text-primary'/>} style={{ marginBottom: 0, paddingRight: 0 }} noTabs />
            <Divider vertical height="24px" marginTop="0.25rem" />
            <SearchTermFilter
                placeholder="Search Data Manager"
                size="small"
                width="l"
                isClearable
                dataSet={uploadsProcessing}
                setFilteredData={setFilteredUploads}
                path={["uploadedDate", "uploadedBy.name", 'uploadedBy.email', "status.numberCompleted", "status.numberFailed", "status.numberOfFiles", "status.numberPending", "status.numberProcessing"]}
                hasSearchTerm={setHasSearchTerm} />
            <Button onClick={() => uploadData()} >Import Data</Button>
            {uploading && <LoadingIcon size={24} />}
        </PageContentBanner>
        <PageContentBodyGrid rowGap="xl" gridTemplateRows="1fr">
            <PageGridItem container col="1 / span 12">
                <DataUploadTable data={hasSearchTerm ? filteredUploads : uploadsProcessing} loading={loading} />
            </PageGridItem>
        </PageContentBodyGrid >
    </Page >
}

export default DataManagerPage;
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Save, X, ChevronDown, ChevronUp, Trash2, FileText, ArrowUp } from 'react-feather';
import { useRemote, useStorage } from '../../../Utils/Utils';
import { PAGES, ROUTE_PATHS } from '../../../InternalApiApp'
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import NumberInputWithStepper from '../../../components/Input/NumberInputWithStepper';
import withRouteChange from "@threeskye/route-change"
import Table from '../../../components/Table/Table';
import PageGridItem from '../../Shared/Page/PageGridItem';
import ModelAssetClassTable from './ModelAssetClassTable';
import Divider from '../../../components/Divider';
import FlexWrapper from '../../FlexWrapper';
import DetailField from '../../Shared/DetailSection/DetailField';
import DetailSectionHeader from '../../../layouts/Shared/DetailSection/DetailSectionHeader'
import ActionButtonsBanner from '../../Shared/Banner/ActionButtonsBanner'
import LoadingIcon from '../../../components/LoadingIcon';
import Button from '../../../components/Buttons/Button';
import Modal from '../../../components/Modals/Modal';
import CreatableInputField from '../../../components/CreatableInputField';
import SelectDetailField from '../../Shared/DetailSection/SelectDetailField';
import { debounce } from 'throttle-debounce';
import "./ModelPortfolioPage.scss"


const ModelPortfolioPage = ({ match, changeRoute }) => {
	const modelId = +match.params.modelId
	const remote = useRemote()
	const storage = useStorage()
	const defaultOptions = [{ label: 'One', value: 'one' }, { label: 'Two', value: 'two' }, { label: 'Three', value: 'three' }]
	const memoizedOptions = useMemo(() => defaultOptions, [defaultOptions]);

	const [data, setData] = useState([])
	const [originalData, setOriginalData] = useState([])
	const [dataDisplaying, setDataDisplaying] = useState(null)
	const [loading, setLoading] = useState(true)
	const [noModelClasses, setNoModelclasses] = useState(true)
	const [editMode, setEditMode] = useState(false)
	const [newAssetClassName, setNewAssetClassName] = useState('')
	const [sortingColumn, setSortingColumn] = useState({ column: "Name", ascending: false })
	const [editingModelName, setEditingModelName] = useState(null)
	const [searchSelect, setSearchSelect] = useState(null)
	const [saving, setSaving] = useState(false)
	const [cancelling, setCancelling] = useState(false)
	const [tickers, setTickers] = useState(null)
	const [classesToDelete, setClassesToDelete] = useState([])
	const [cancelModal, setCancelModal] = useState(false)
	const [deleteModal, setDeleteModal] = useState(false)
	const [classesToUpdate, setClassesToUpdate] = useState([])

	useEffect(() => {
		storage.getOrFetch('crm/tickers').then((fetchedTickers) => {
			const tickersObj = fetchedTickers.reduce((a, c) => ({ ...a, [c.isin]: c }), {})
			setTickers(tickersObj)
		})
	}, [])

	useEffect(() => {
		getFreshData()
	}, [modelId])

	useEffect(() => {
		sortTable(null)
	}, [data])

	useEffect(() => {
		if (classesToDelete.length > 0) {
			const newClasses = data.modelClasses && data.modelClasses.length > 0 && data.modelClasses.filter(modelClass => !classesToDelete.includes(modelClass.id))
			const updatedData = { ...data, modelClasses: newClasses }
			setData(updatedData)
		}
	}, [classesToDelete])

	function sortTable(column, table) {
		if (column === null) {
			setDataDisplaying(data)
			return
		}

		let tableData = data
		const ascending = sortingColumn.column === column ? !sortingColumn.ascending : true

		switch (column) {
			case "Name":
				tableData.sort((a, b) => ascending ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name))
				break;
			case "Asset Classes":
				tableData.sort((a, b) => {
					const class1 = a.modelClasses && a.modelClasses.length ? a.modelClasses.length : 0
					const class2 = b.modelClasses && b.modelClasses.length ? b.modelClasses.length : 0
					return ascending ? class1 - class2 : class2 - class1
				})
				break;
			case "Last Modified":
				tableData.sort((a, b) => {
					const class1 = a.lastModified || '-'
					const class2 = b.lastModified || '-'
					return ascending ? class1.localeCompare(class2) : class2.localeCompare(class1)
				})
				break;
			case "Date Created":
				tableData.sort((a, b) => {
					const class1 = a.dateCreated || '-'
					const class2 = b.dateCreated || '-'
					return ascending ? class1.localeCompare(class2) : class2.localeCompare(class1)
				})
				break;
			default:
				break;
		}
		setSortingColumn({ column: column, ascending: ascending })
		setDataDisplaying(tableData)
	}

	function getFreshData() {
		remote.get(`model-portfolio/model/${modelId}`).then(fetchedModel => {
			let promisedClasses = fetchedModel.modelClasses.map((modelClass) => {
				return remote.get(`model-portfolio/model/${modelId}/class/${modelClass.id}`)
			})
			setEditingModelName(fetchedModel.modelName)
			Promise.all(promisedClasses).then((fetchedClases) => {
				let modelData = { ...fetchedModel, modelClasses: fetchedClases }
				setData(modelData)
				setOriginalData(modelData)
				if (modelData.modelClasses && modelData.modelClasses.length === 0) setNoModelclasses(true)
				else setNoModelclasses(false);
			}).then(() => setLoading(false))
		})
	}

	function createAssetClass(name) {
		remote.post(`model-portfolio/model/${modelId}`, { "name": name })
			.then(() => {
				getFreshData()
				setNewAssetClassName("")
			})
	}

	function updateDataState(val, classEdited, string) {
		if (classesToUpdate.length > 0) {
			const exists = classesToUpdate.find(c => c.id === classEdited.id)
			if (exists) {
				const modificationsArray = classesToUpdate.map(c => {
					if (c.id === classEdited.id) {
						const updatedClass = { ...c, [string]: val }
						return updatedClass
					} else return c
				})
				setClassesToUpdate(modificationsArray)
			} else setClassesToUpdate([...classesToUpdate, { ...classEdited, [string]: val }])
		} else setClassesToUpdate([{ ...classEdited, [string]: val }])

		const updatedModelClasses = data.modelClasses && data.modelClasses.length > 0 && data.modelClasses.map(modelClass => {
			if (classEdited.id === modelClass.id) {
				const updatedModelClass = { ...modelClass, [string]: val }
				return updatedModelClass
			} else return modelClass
		})
		const updatedData = { ...data, modelClasses: updatedModelClasses }
		setData(updatedData)
	}

	function updateModelName(name, model) {
		remote.put(`model-portfolio/model/${model.id}`, { ...model, "name": name })
	}

	function deleteModel() {
		remote.delete(`model-portfolio/model/${modelId}`).then(() => changeRoute(`${ROUTE_PATHS[PAGES.ADMIN]}/model-portfolio`))
	}

	function deleteModelClass() {
		classesToDelete.map(clssId => remote.delete(`model-portfolio/model/${modelId}/class/${clssId}`).then((resp) => {
			setClassesToDelete(classesToDelete.filter(id => id !== clssId))
		}))
	}

	function editModelClass() {
		classesToUpdate.map((clss) => {
			const payload = {
				name: clss.name,
				// assets: clss.assets,
				actual: clss.actual,
				target: +clss.target,
				rangeCurrentMin: +clss.rangeCurrentMin,
				rangeCurrentMax: +clss.rangeCurrentMax,
				rangePrevMin: +clss.rangePrevMin,
				rangePrevMax: +clss.rangePrevMax
			}
			return remote.put(`model-portfolio/model/${modelId}/class/${clss.id}`, payload).then((resp) => {
				setClassesToUpdate(classesToUpdate.filter(c => c.id !== clss.id))
			})
		})
	}

	function saveChanges() {
		setSaving(true)
		if (classesToDelete.length > 0) deleteModelClass()
		if (data.modelName !== editingModelName) updateModelName(editingModelName, data)
		if (classesToUpdate.length > 0) editModelClass()
		setEditMode(false)
		setSaving(false)
	}

	function cancelChanges() {
		setCancelling(true)
		setClassesToDelete([])
		setData(originalData)
		setSaving(false)
		setSearchSelect(null)
		setEditMode(false)
		setCancelModal(false)
	}

	function scrollToTable(id) {
		const element = document.getElementById('model-class-id-' + id)
		element?.scrollIntoView({ behavior: "smooth", block: "center" });
	}

	function classesTableDataMap(dataToMap) {
		return dataToMap && dataToMap.modelClasses && dataToMap.modelClasses.length > 0 && dataToMap.modelClasses.map((modelClass, idx) => {
			const { id, actual, target, rangeCurrentMin, rangeCurrentMax, rangePrevMin, rangePrevMax, name } = modelClass
			const actualValue = actual * 100
			return (
				<tr className={`row-padding-s${editMode ? ' model-tr-edit-mode' : ''}`} key={modelClass.id + '-' + modelClass.name}>
					<td className={`model-portfolio-class-name${editMode ? ' model-td-edit-mode' : ''}`} onClick={() => scrollToTable(id)}>
						{editMode ?
							<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
								{name}
								<Trash2 size={22} className='delete-icon' onClick={(e) => { e.stopPropagation(); setClassesToDelete(classesToDelete.length > 0 ? [...classesToDelete, id] : [id]) }} />
							</div> : name
						}
					</td>
					<td className={`text-align-right${editMode ? ' model-td-edit-mode' : ''}`}>
						{actualValue ? actualValue.toFixed(2) + '%' : "-"}
					</td>
					<td className={`text-align-right${editMode ? ' model-td-edit-mode' : ''}`}>
						{editMode ?
							<div className='range-number-input-container flex-align-right'>
								<NumberInputWithStepper width={60} inputClassName='range-number-input' value={target || 0} onChange={(val) => updateDataState(val, modelClass, 'target')} />
							</div> : target ? target + '%' : "-"
						}
					</td>
					<td className={`text-align-right${editMode ? ' model-td-edit-mode' : ''}`}>
						{editMode ?
							<div className='range-number-input-container flex-align-right'>
								<NumberInputWithStepper width={60} inputClassName='range-number-input' value={rangePrevMin} onChange={(val) => updateDataState(val, modelClass, 'rangePrevMin', rangePrevMin)} />
								-
								<NumberInputWithStepper width={60} inputClassName='range-number-input' value={rangePrevMax} onChange={(val) => updateDataState(val, modelClass, 'rangePrevMax', rangePrevMax)} />
							</div> : (rangePrevMin + "% - " + rangePrevMax + "%") || '-'
						}
					</td>
					<td className={`text-align-right${editMode ? ' model-td-edit-mode' : ''}`}>
						{editMode ?
							<div className='range-number-input-container flex-align-right'>
								<NumberInputWithStepper width={60} inputClassName='range-number-input' value={rangeCurrentMin} onChange={(val) => updateDataState(val, modelClass, 'rangeCurrentMin', rangeCurrentMin)} />
								-
								<NumberInputWithStepper width={60} inputClassName='range-number-input' value={rangeCurrentMax} onChange={(val) => updateDataState(val, modelClass, 'rangeCurrentMax', rangeCurrentMax)} />
							</div> : (rangeCurrentMin + "% - " + rangeCurrentMax + "%") || '-'
						}
					</td>
				</tr>
			)
		})
	}

	const classesTableColWidths = ['35%', '17%', '12%', '18%', '18%']
	const classesTableHeaders = [
		{
			text: (
				<div className='sorting-header' onClick={() => sortTable("Asset Classes")}>
					Name
					{sortingColumn && sortingColumn.column === "Asset Classes" && sortingColumn.ascending === true ?
						<ChevronUp className="colour-text-primary" size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === "Asset Classes" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
					}
				</div>
			),
			headerKey: 'asset-classes',
		},
		{
			text: (
				<div className='sorting-header right' onClick={() => sortTable("Actual")} >
					Actual
					{sortingColumn && sortingColumn.column === "Actual" && sortingColumn.ascending === true ?
						<ChevronUp className="colour-text-primary" size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === "Actual" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
					}
				</div>
			),
			headerKey: 'Actual',
			className: 'text-align-right'
		},
		{
			text: (
				<div className='sorting-header right' onClick={() => sortTable("Target")} >
					Target
					{sortingColumn && sortingColumn.column === "Target" && sortingColumn.ascending === true ?
						<ChevronUp className="colour-text-primary" size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === "Target" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
					}
				</div>
			),
			headerKey: 'target',
			className: 'text-align-right'
		},
		{
			text: (
				<div className='sorting-header right' onClick={() => sortTable("RangePrev")} >
					{'Range (Prev)'}
					{sortingColumn && sortingColumn.column === "RangePrev" && sortingColumn.ascending === true ?
						<ChevronUp className="colour-text-primary" size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === "RangePrev" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
					}
				</div>
			),
			headerKey: 'range-prev',
			className: 'text-align-right'
		},
		{
			text: (
				<div className='sorting-header right' onClick={() => sortTable("RangeCurrent")} >
					{'Range (Current)'}
					{sortingColumn && sortingColumn.column === "RangeCurrent" && sortingColumn.ascending === true ?
						<ChevronUp className="colour-text-primary" size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === "RangeCurrent" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
					}
				</div>
			),
			headerKey: 'range-current',
			className: 'text-align-right'
		}
	]


	if (loading)
		return (
			<Page>
				<PageContentBodyGrid gridTemplateColumns='1fr'>
					<LoadingIcon centered />
				</PageContentBodyGrid>
			</Page>)

	return (<>
		<Page showScrollbar>
			<PageContentBanner divider gap='m' align='baseline' fixed>
				<ActionButtonsBanner
					handleBackOnClick={() => changeRoute(`${ROUTE_PATHS[PAGES.ADMIN]}/model-portfolio`)}
					cancel={{ onClick: () => setCancelModal(true) }}
					editMode={editMode}
					edit={{ onClick: () => setEditMode(true) }}
					remove={{ onClick: () => setDeleteModal(true) }}
					hideFirstDivider
					save={{ onClick: () => saveChanges() }}
				/>
			</PageContentBanner>
			<Button
				icon={<ArrowUp />}
				style={{ position: 'fixed', right: '1.5rem', bottom: '2rem', zIndex: 100 }}
				onClick={() => document.getElementById("caca").scrollIntoView({ behavior: 'smooth', block: 'start' })}
			>
				Scroll to top
			</Button>
			<PageContentBodyGrid id="model-portfolio-page" rowGap="l" paddingBottom='0rem'>
				<PageGridItem id='caca' container col="1 / span 12" className={editMode ? '' : 'mb-m'} direction='row' gap='m' align='center' style={{ scrollMarginTop: '8rem' }}>
					{editMode ?
						<DetailField
							size='small'
							inputstyle={{ minWidth: 420 }}
							editMode={editMode}
							value={editingModelName}
							onChange={(e) => setEditingModelName(e.target.value)}
						/> : <DetailSectionHeader header={data.modelName} icon={<FileText />} />
					}
				</PageGridItem>
				<PageGridItem id='model-classes-table' container col="1 / span 12" className={editMode || noModelClasses ? '' : 'mb-s'} direction='row' gap='m' align='center' alignItems='center'>
					<PageGridItem container col="1 / span 2" className='font-weight-semi-bold'>Asset Classes</PageGridItem>
					{(noModelClasses || editMode) &&
						<FlexWrapper direction="row" gap="m">
							<DetailField
								size='small'
								editMode={true}
								autoFocus
								placeholder="Create new Asset Class e.g Cash"
								value={newAssetClassName}
								onChange={(e) => setNewAssetClassName(e.target.value)}
								style={{ width: 300 }}
							/>
							{/* <SelectDetailField
								creatable
								editMode
								placeholder="Create an new class or search for an existing class"
								onInputChange={(e) => console.log('e', e)}
								value={newAssetClassName}
								onChange={(e) => { console.log('onChange', e); setNewAssetClassName(e.value) }}
								options={memoizedOptions}
							/>						 */}
							<FlexWrapper direction="row" gap="s">
								<Divider height={32} dark vertical />
								<Save size={20} color={newAssetClassName.length === 0 ? '#999DAD' : '#0D8EFD'} className={newAssetClassName.length === 0 ? 'disabled-icon' : 'cursor-pointer'} onClick={() => newAssetClassName.length === 0 ? null : createAssetClass(newAssetClassName)} />
								{/* <X size={20} color="#999DAD" onClick={() => setNewAssetClassName("")} /> */}
							</FlexWrapper>
						</FlexWrapper>
					}
				</PageGridItem>
				{dataDisplaying && !noModelClasses ?
					<PageGridItem container col="1 / span 12" className='mb-xl' style={{ marginBottom: "2rem" }}>
						<Table
							minWidth={900}
							tableStyle={{ maxWidth: 1400 }}
							colWidths={classesTableColWidths}
							headers={classesTableHeaders}
							dataMap={classesTableDataMap(dataDisplaying)}
							dataLoading={loading}
							id='model-portfolio-table'
							noResults={{ message: '' }}
						/>
					</PageGridItem> :
					<PageGridItem container col='1 / span 12' className='helper-text mb-xl'>No asset classes on this Model. Add/Create some model classes.</PageGridItem>
				}
				<PageGridItem container col="1 / span 12" className='font-weight-semi-bold' >Asset Class Holdings</PageGridItem>
				<PageGridItem container col="1 / span 12" gap='l' style={{ display: 'grid', marginTop: '0rem', marginBottom: '1.5rem' }}>
					{data && data.modelClasses && data.modelClasses.map((assetClass, idx) => {
						return <div key={`${assetClass.name}-${idx}`} style={{ marginTop: "0rem" }}>
							<ModelAssetClassTable
								editMode={editMode}
								setEditMode={setEditMode}
								assetClass={assetClass}
								searchSelect={searchSelect}
								setSearchSelect={setSearchSelect}
								updateDataState={updateDataState}
								saving={saving}
								cancelling={cancelling}
								setCancelling={setCancelling}
								tickers={tickers}
								isLast={data.modelClasses?.length - 1 === idx}
							/>
						</div>
					})}
				</PageGridItem>
			</PageContentBodyGrid>
		</Page >
		{cancelModal &&
			<Modal
				header="Are you sure?"
				cancelButton={{ onClick: () => setCancelModal(false), label: 'No, Go Back' }}
				confirmButton={{ onClick: () => cancelChanges(), label: 'Yes, Cancel' }}
				handleClose={() => setCancelModal(false)}>
				<p>You will lose all changes made so far</p>

			</Modal>
		}
		{deleteModal &&
			<Modal
				header="Are you sure?"
				cancelButton={{ onClick: () => setDeleteModal(false), label: 'No, Go Back' }}
				confirmButton={{ onClick: () => deleteModel(), label: 'Yes, Delete' }}
				handleClose={() => setDeleteModal(false)}>
				<p>{`The model ${editingModelName} will be deleted permanently`}</p>

			</Modal>
		}
	</>);
}

export default withRouteChange(ModelPortfolioPage);
import React from 'react';
import "./Icons.scss";

const MailIconFilled = ({ className, style, colour, onClick }) => {

	//width 400
	//filled
	return (
		<svg onClick={onClick} style={style ? style : {}} className={`${className ? className : ""} material-downloaded-icon`} xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill={colour ? colour : "#999DAD"}>
			<path d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm320-280 320-200v-80L480-520 160-720v80l320 200Z"/>
		</svg>
	);
}

export default MailIconFilled;

import React, { useState } from 'react';
import { ChevronDown, ChevronLeft, ChevronRight, ChevronUp, User } from 'react-feather';
import ProgressBar from '../../components/Input/ProgressBar';
import ProfileImage from '../../components/ProfileImage';
import WorkflowComplianceActionRow from './WorkflowComplianceActionRow';
import FlexWrapper from '../FlexWrapper';
import ExpandableTableRow from '../../components/Table/ExpandableTableRow';
import "./WorkflowComplianceActions.scss";
import Table from '../../components/Table/Table';


const WorkflowComplianceStepRow = ({ step, idx, setApproversList, taskId, historical, refreshList }) => {
	const [rowSelected, setRowSelected] = useState(false)
	const [selectedFunds, setSelectedFunds] = useState([])

	const onFundClick = (idx) => {
		if (selectedFunds.includes(idx)) {
			setSelectedFunds(selectedFunds.filter(i => i !== idx))
		} else {
			setSelectedFunds([...selectedFunds, idx])
		}
	}

	const totalFunds = step.totalData
	const completedFunds = step.datas ? step.datas.filter(f => f.status === "Approved").length : 0
	const content = [
		step.name,
		<FlexWrapper >
			{Array.from({ length: step.approversNeeded }).map((_, index) => (
				<User key={index} color="#0E8EFD" />
			))}
		</FlexWrapper>,
		<FlexWrapper >
			{step.approversAvailable.map((approver, idx) => {
				if (idx > 3) return null
				return <ProfileImage onClick={(e) => { e.stopPropagation(); setApproversList(step.approversAvailable) }} style={idx > 0 ? { marginLeft: "-0.25rem", border: "white 1px solid", cursor: "pointer" } : {}} user={approver} />
			})}
			{step.approversAvailable.length > 4 && <p onClick={(e) => { e.stopPropagation(); setApproversList(step.approversAvailable) }} className='extension-label ml-s pointer'>{`+${step.approversAvailable.length - 4} more`}</p>}
		</FlexWrapper>,
		`${completedFunds}/${totalFunds}`,
		<ProgressBar extent={totalFunds} value={completedFunds} colour1={totalFunds === completedFunds ? "#E0FFF6" : null} colour2={totalFunds === completedFunds ? "#56D1C3" : null} />	
	]

	const closeSelectedRow = () => {
		setRowSelected(false)
		setSelectedFunds([])
	}

	const fundDataMap = step && step.datas.map((fund, idx) => <WorkflowComplianceActionRow refreshList={refreshList} taskId={taskId} key={idx} fund={fund} idx={idx} selectedRow={step} selectedFunds={selectedFunds} onFundClick={onFundClick} />)
	const fundTable = <Table dataMap={fundDataMap} minWidth={1000} colWidths={["590px", "290px", "290px", "200px" ]}/>

	return (
		<ExpandableTableRow expandRowClassname="workflow-step-expansion" className='workflow-compliance-step-row pointer' expandContent={fundTable} onClick={() => { !rowSelected ? setRowSelected(true) : closeSelectedRow() }} numberOfCols={historical ? 5 : 6} >
			<td><FlexWrapper align="center" >{content[0]}</FlexWrapper></td>
			<td>{content[1]}</td>
			{historical ? "" : <td>{content[2]}</td>}
			<td>{content[3]}</td>
			<td>{content[4]}</td>
			<td className='workflow-cell-align-right action-row-hoverable-chevron'>{rowSelected ? <ChevronUp color="#0E8EFD" /> : <ChevronDown color="#C2C4CE" />}</td>
		</ExpandableTableRow>
	)
}

export default WorkflowComplianceStepRow;
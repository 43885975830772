import React from "react";
import { Info } from "react-feather";
import "./NoResultsMessage.scss";

const NoResultsMessage = ({ icon, message, subMessage, noHeader, noIcon, compact, wrapperStyle, smallNoResult, space }) => {
	return (smallNoResult ?
		<div className={`no-results-message-container no-results-compact${space ? ' with-space' : ''}`}>
			{noIcon ? "" : icon ? icon : <Info />}
			{noHeader ? "" : <h2>{message ? message : "No Results Found"}</h2>}
		</div> :
		<div className={`no-results-message-container${compact ? " no-results-compact" : ""}`} style={wrapperStyle ? wrapperStyle : {}}>
			{noIcon ? "" : icon ? icon : <Info />}
			{noHeader ? "" : <h2>{message ? message : "No Results Found"}</h2>}
			<div className="sub-message">{subMessage ? subMessage : "Please check your spelling or try a different term"}</div>
		</div>
	);
}

export default NoResultsMessage;
import React from 'react';
import DropDown from '../../DropDown';

const FundSelector = ({ updateTickerStatus, tickerOption, setState, state, onUpdateTickerGroup }) => {
	const { selectedTickerGroup, tickers, selectionGroups } = state;
	const funds = selectedTickerGroup ? selectedTickerGroup.funds : []
	const selectedFund = funds && tickers && tickers[0] && funds.find(f => f.fundNumber === tickers[0])

	const chooseFund = (fund) => {
		setState({ tickers: [fund.fundNumber] }, () => updateTickerStatus(true));
	};

	const chooseOffer = (offer) => {
		setState({ selectedTickerGroup: offer });
	}

	return (
		<>
			<DropDown ellipseWidth={230} stringStyle={{padding: '11px 0'}} clickable fullWidth label={tickerOption.selectionName} value={selectedTickerGroup && selectedTickerGroup.offerName} maxDropdownHeight={"calc(98vh - 372px"}>
				{selectionGroups.map((option, index) => (
					<li key={index} onClick={() => chooseOffer(option)}>
						{option.offerName}
					</li>
				))}
			</DropDown>
			{selectedTickerGroup &&
				<DropDown ellipseWidth={230} stringStyle={{padding: '11px 0'}} clickable fullWidth label={tickerOption.subSelectionName} value={selectedFund && selectedFund.fundName} labelClassName='mt-l' maxDropdownHeight={"calc(98vh - 462px"}>
					{funds.map((option, index) => (
						<li key={index} onClick={() => chooseFund(option, index)}>
							{option.fundName}
						</li>
					))}
				</DropDown>
			}
		</>
	)
}

export default FundSelector;
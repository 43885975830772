import React, { useEffect, useState, useContext } from 'react';
import { useRemote } from '../../../Utils/Utils';
import { useHistory } from "react-router-dom";
import { ChevronUp, ChevronDown } from 'react-feather';
import { PAGES, ROUTE_PATHS, BannerContext } from '../../../InternalApiApp';
import Page, { PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridItem from '../../Shared/Page/PageGridItem';
import Table from '../../../components/Table/Table';
import SearchTermFilter from '../../../components/Input/SearchTermFilter'
import Button from '../../../components/Buttons/Button';
import TextInput from '../../../components/Input/TextInput';
import './StyleGuide.scss'


const SubBrandsList = ({ organisation }) => {
    const { setBannerBreadCrumbs } = useContext(BannerContext);
    const remote = useRemote()
    const history = useHistory()

    const [loading, setLoading] = useState(true)
    const [hasSearchTerm, setHasSearchTerm] = useState(false);
    const [list, setList] = useState([])
    const [filteredList, setFilteredList] = useState([])
    const [listDisplayed, setListDisplayed] = useState([])
    const [sortingColumn, setSortingColumn] = useState({ column: 'SubBrand', ascending: true })
    const [newSubBrand, setNewSubBrand] = useState('')

    useEffect(() => {
        setBannerBreadCrumbs({ tab: 'Sub-Brands' })
        remote.get('brand/all').then(fetchedBrands => {
            const subBrandsList = fetchedBrands && fetchedBrands.length > 0 && fetchedBrands.filter(b => !b.master)
            if (subBrandsList.length > 0) {
                const listWithDummyData = subBrandsList.map(b => {
                    const newObj = {
                        ...b,
                        creator: 'Charlie Matina',
                        createdDate: '2.00pm 10/02/2025',
                        lastEdited: '4.00pm 10/02/2025'
                    }
                    return newObj
                })
                setList(listWithDummyData?.sort((a, b) => a?.name?.localeCompare(b?.name)))
                setLoading(false)
            }
            else {
                setLoading(false)
            }
        })
    }, [])

    useEffect(() => {
        sortTable(null)
    }, [list, filteredList])

    const sortTable = (column) => {
        if (column === null) {
            setListDisplayed(hasSearchTerm ? filteredList : list)
            return
        }
        let tableData = hasSearchTerm ? filteredList : list
        const ascending = sortingColumn.column === column ? !sortingColumn.ascending : true
        switch (column) {
            case 'SubBrand':
                tableData.sort((a, b) => ascending ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name))
                break;
            case 'Creator':
                tableData.sort((a, b) => ascending ? a.creator.localeCompare(b.creator) : b.creator.localeCompare(a.creator))
                break;
            case 'Created':
                tableData.sort((a, b) => ascending ? a.createdDate.localeCompare(b.createdDate) : b.createdDate.localeCompare(a.createdDate))
                break;
            case 'Edited':
                tableData.sort((a, b) => ascending ? a.lastEdited.localeCompare(b.lastEdited) : b.lastEdited.localeCompare(a.lastEdited))
                break;
            default:
                break;
        }
        setSortingColumn({ column: column, ascending: ascending })
        setListDisplayed(tableData)
    }

    const colWidths = ['180px', '400px', '270px', '150px'];
    const headers = [
        {
            text: (
                <div className='sorting-header' onClick={() => sortTable('Created')}>
                    Date Created
                    {sortingColumn && sortingColumn.column === 'Created' && sortingColumn.ascending === true ?
                        <ChevronUp className='colour-text-primary' size={12} /> :
                        <ChevronDown className={sortingColumn && sortingColumn.column === 'Created' ? 'colour-text-primary' : 'colour-text-neutral-dark-30'} size={12} />
                    }
                </div>
            )
        },
        {
            text: (
                <div className='sorting-header' onClick={() => sortTable('SubBrand')}>
                    Sub-Brand
                    {sortingColumn && sortingColumn.column === 'SubBrand' && sortingColumn.ascending === true ?
                        <ChevronUp className='colour-text-primary' size={12} /> :
                        <ChevronDown className={sortingColumn && sortingColumn.column === 'SubBrand' ? 'colour-text-primary' : 'colour-text-neutral-dark-30'} size={12} />
                    }
                </div>
            )
        },
        {
            text: (
                <div className='sorting-header' onClick={() => sortTable('Creator')}>
                    Creator
                    {sortingColumn && sortingColumn.column === 'Creator' && sortingColumn.ascending === true ?
                        <ChevronUp className='colour-text-primary' size={12} /> :
                        <ChevronDown className={sortingColumn && sortingColumn.column === 'Creator' ? 'colour-text-primary' : 'colour-text-neutral-dark-30'} size={12} />
                    }
                </div>
            )
        },
        {
            text: (
                <div className='sorting-header' onClick={() => sortTable('Edited')}>
                    Last Edited
                    {sortingColumn && sortingColumn.column === 'Edited' && sortingColumn.ascending === true ?
                        <ChevronUp className='colour-text-primary' size={12} /> :
                        <ChevronDown className={sortingColumn && sortingColumn.column === 'Edited' ? 'colour-text-primary' : 'colour-text-neutral-dark-30'} size={12} />
                    }
                </div>
            )
        }
    ];

    const dataMap = (data) => data && data.length > 0 && data.map((datum, idx) => {
        const { name, id, createdDate, lastEdited, creator } = datum
        const pathName = name.split(' ').join('-').toLowerCase()
        return (
            <tr key={idx + '-' + id} className='cursor-pointer' onClick={() => history.push(ROUTE_PATHS[PAGES.ADMIN] + '/brand-settings/sub-brands/' + id)}>
                <td>{createdDate}</td>
                <td>{name}</td>
                <td>{creator}</td>
                <td>{lastEdited}</td>
            </tr>
        );
    });

    function createBrand() {
        if (organisation.hasSubBrands) {
            remote.post(`brand/create-sub-brand`, { name: newSubBrand }).then(resp => {
                setList(list.length > 0 ? [resp, ...list] : [resp])
                setNewSubBrand('')
            })
        }
    }

    return (
        <Page noBanner noOverflownY style={{ paddingLeft: '1rem' }}>
            <PageContentBodyGrid id='client-details-page' rowGap='m' gridColCount='4' paddingTop='0rem' subGrid noOverflownY>
                <PageGridItem colSpan='12' style={{ paddingTop: '1.5rem', display: 'flex', gap: '1.5rem' }}>
                    <SearchTermFilter
                        size='small'
                        width='l'
                        placeholder={`Search sub-brands ${loading ? '' : '(' + list.length + ')'}`}
                        isClearable
                        dataSet={list}
                        setFilteredData={setFilteredList}
                        path={['name', 'creator']}
                        hasSearchTerm={(boolean) => setHasSearchTerm(boolean)}
                    />
                    <TextInput disabled={!organisation.hasSubBrands} placeholder='Add a sub-brand' value={newSubBrand} onChange={(e) => setNewSubBrand(e.target.value)} size='small' width='l'/>
                    <Button disabled={newSubBrand.length === 0 || !organisation.hasSubBrands} onClick={createBrand}>New Sub-brand</Button>
                </PageGridItem>
                <PageGridItem colSpan="12" style={{ paddingTop: "2rem" }}>
                    <Table
                        minWidth={1000}
                        tableStyle={{ maxWidth: 1200 }}
                        colWidths={colWidths}
                        headers={headers}
                        dataMap={dataMap(listDisplayed)}
                        noResults={{ message: "No results found" }}
                        dataLoading={loading}
                    />
                </PageGridItem>
            </PageContentBodyGrid>
        </Page >
    )
}

export default SubBrandsList;
import React, { useEffect, useRef, useState } from 'react';
import StatusToken from '../StatusTokens';
import CommentActionMenu from './CommentActionMenu';
import { format, formatDistance, isAfter, parseISO, set, subDays } from 'date-fns';
import Popup from '../Popup';
import { MoreVertical } from 'react-feather';
import FlexWrapper from '../../layouts/FlexWrapper';
import TextArea from '../Input/TextArea';
import Button from '../Buttons/Button';
import "./CommentsSection.scss";
import { useRemote } from '../../Utils/Utils';

const Comment = ({ ticker, templateName, comment, idx, isLastComment, authors, userRole, handleCommentSubmit, refresh }) => {
	const [showReplies, setShowReplies] = useState(false)
	const [showReplyInput, setShowReplyInput] = useState(false)
	const [replyInputValue, setReplyInputValue] = useState("")
	const [menuShowing, setMenuShowing] = useState(false)

	const replyInputRef = useRef();
	const remote = useRemote();

	useEffect(() => {
		if (showReplyInput) {
			replyInputRef.current.focus()
		}
	}, [showReplyInput])


	const getAuthor = (email) => {
		if (!authors || !email) return "Unknown"
		const author = authors.find(author => author.email === email)
		return author ? author.name : "Unknown"
	}
	const getAuthorPhoto = (email) => {
		if (!authors || !email) return null
		const author = authors.find(author => author.email === email)
		return author ? author.photo : null
	}

	const hideShowReplies = () => {
		if (showReplies) {
			setShowReplies(false)
			setReplyInputValue("")
			setShowReplyInput(false)
		} else {
			setShowReplies(true)
		}
	}

	const showHideReplyInput = () => {
		if (showReplyInput) {
			setShowReplyInput(false)
			setReplyInputValue("")
		} else {
			setShowReplyInput(true)
		}
	}

	const parseDate = (date) => {
		if (!date) {
			return ""
		}
		try {
			const parsedDate = parseISO(date)
			return handleDateFormatting(parsedDate)
		} catch {
			return handleDateFormatting(date)
		}

	}

	const handleFeatureVisibilityOnSubmit = () => {
		setReplyInputValue(""); 
		setShowReplyInput(true);
		setShowReplies(true);
	}

	const handleDateFormatting = (date) => {
		const oneDayprevious = subDays(new Date(), 1)
		const dateIslessThanOneDayOld = isAfter(date, oneDayprevious)
		if (dateIslessThanOneDayOld) {
			return formatDistance(date, new Date())
		} else {
			return format(date, "dd MMM yyyy h:mm aaa")
		}
	}

	const resolveComment = () => {
		remote.put(`/comments/${btoa(ticker)}/${templateName}/${comment.commentId}?status=RESOLVED`).then(() => {
			refresh()
		})
	}


	return (
		<div key={comment.commentId + idx} className={`single-comment ${isLastComment ? " last-comment" : ""} ${idx === 0 ? " first-comment" : ""}`}>
			<div className='comment-header'>
				{getAuthorPhoto(comment.author)
					? <img src={getAuthorPhoto(comment.author)} alt={getAuthor(comment.author)} className='comment-author-image' />
					: <div className="comment-author-image-initials">{getAuthor(comment.author).split(" ").map(val => val[0])}</div>
				}
				<div className="comment-header-info">
					<div className="comment-header-info-top-row">
						<h4 className='comment-author'>{getAuthor(comment.author)}</h4>
					</div>
					<div className="comment-header-info-bottom-row">
						<StatusToken type="primary" label={comment.authorRole} />
						<p className='comment-time'>{parseDate(comment.date)}</p>
					</div>
				</div>
				<Popup
					placement="left-start"
					onClick={() => setMenuShowing(!menuShowing)}
					triggerElement={<MoreVertical className="comment-action-menu-trigger" size={16} />}
					popupElement={<CommentActionMenu
						onClick={() => setMenuShowing(!menuShowing)}
						resolveOnClick={resolveComment}
						handleClickAway={() => setMenuShowing(false)}
					/>}
				/>
			</div>
			<p className='comment-message-body'>{comment.comment}</p>
			{comment.replies
				? <div className='comment-footer' onClick={() => hideShowReplies()}>
					{comment.replies &&
						<span>{comment.replies.length > 1 ? comment.replies.length + " Replies" : "1 Reply"}</span>
					}
					<i className='material-icons' >{showReplies ? "expand_less" : "expand_more"}</i>
				</div>
				: <>

					<div className='comment-footer' onClick={() => showHideReplyInput()}>
						{showReplyInput ? "Hide" : "Reply"}
					</div>

					{showReplyInput &&
						<FlexWrapper direction="column" className="comments-input-container" align="start">
							<TextArea
								placeholder="Add a comment"
								className="add-comment-text-area white"
								value={replyInputValue}
								onChange={(e) => setReplyInputValue(e.target.value)}
								maxlength={200}
								forwardRef={replyInputRef}
							/>
							<Button size="small" disabled={!userRole || !replyInputValue} fullWidth onClick={() => handleCommentSubmit(comment.commentId, replyInputValue, handleFeatureVisibilityOnSubmit)} >Submit Reply</Button>
						</FlexWrapper >
					}
				</>
			}

			{showReplies && comment?.replies?.map((reply, replyIdx) => (
				<div className='single-reply' key={reply.id + replyIdx}>
					<div className='reply-header'>
						{getAuthorPhoto(reply.author)
							? <img src={getAuthorPhoto(reply.author)} alt={getAuthor(reply.author)} className='comment-author-image' />
							: <div className="comment-author-image-initials">{getAuthor(reply.author).split(" ").map(val => val[0])}</div>
						}									
						<div className="reply-header-info">
							<div className="reply-header-info-top-row">
								<h4 className='reply-author'>{getAuthor(reply.author)}</h4>
							</div>
							<div className="reply-header-info-bottom-row">
								<StatusToken type="primary" label={reply.peer ? "Peer" : reply.compliance ? "Compliance" : ""} />
								<p className='reply-time'>{parseDate(reply.date)}</p>
							</div>
						</div>
					</div>
					<p className='reply-message-body'>{reply.comment}</p>
					{(replyIdx + 1) === comment.replies.length &&
						<div className='comment-footer' onClick={() => showHideReplyInput(comment.commentId)}>
							{showReplyInput ? "Hide" : "Reply"}
						</div>
					}
					{(replyIdx + 1) === comment.replies.length && showReplyInput &&
						<FlexWrapper direction="column" className="comments-input-container" align="start">
							<TextArea
								placeholder="Add a comment"
								className="add-comment-text-area white"
								value={replyInputValue}
								onChange={(e) => setReplyInputValue(e.target.value)}
								maxlength={200}
								forwardRef={replyInputRef}
							/>
							<Button size="small" disabled={!userRole} fullWidth onClick={() => handleCommentSubmit(comment.commentId, replyInputValue, handleFeatureVisibilityOnSubmit)} >Submit Reply</Button>
						</FlexWrapper >
					}
				</div>
			))}
		</div>
	);
}

export default Comment;
import React, { useState } from "react";
import { useRemote } from '../../../Utils/Utils';
import DetailField from '../../Shared/DetailSection/DetailField'
import Button from "../../../components/Buttons/Button";
import SelectDetailField from "../../Shared/DetailSection/SelectDetailField";
import RadioInputGroup from "../../../components/Input/RadioInput/RadioInputGroup"
import './ParamField.scss';

const ParamField = ({ param, template, action, setSelectedPublishActions, onValueChange, editMode }) => {
	const value = (action.paramValues && action.paramValues[param.name]) || "";
	const [editor, setEditor] = useState(value);
	const remote = useRemote();

	const camelToTitle = (camelCaseText) => {
		return camelCaseText
			.replace(/([A-Z])/g, ' $1') // Add a space before each uppercase letter
			.replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
	};

	const onInputChange = (value) => {
		setEditor(value);
		onValueChange && onValueChange(value);
	}

	const getInputType = (type) => {
		const options = Object.keys(param.availableOptions).map(key => ({ value: key, label: param.availableOptions[key] }))
		const optionStrings = Object.keys(param.availableOptions).map(key => (param.availableOptions[key]))

		switch (type) {
			case "SELECT":
				return <SelectDetailField
					editMode={editMode}
					label={param.displayName ? param.displayName : camelToTitle(param.name)}
					value={editor && param.availableOptions[editor] && {value: editor, label: param.availableOptions[editor]}}
					onChange={(value) => {onInputChange(value.value)}}
					options={options}
					noPlaceholder
					invalid={editor === "" ? true : false}
				/>
			case "NUMBER":
				let listOptions = options.map(option => option.value)
				listOptions.sort((a, b) => a - b)
				return <DetailField
					type="number"
					variant="light"
					invalid={editor === "" ? true : false}
					editMode={editMode}
					autoFocus
					label={param.displayName ? param.displayName : camelToTitle(param.name)}
					value={editor}
					onChange={(e) => { onInputChange(e.target.value) }}
					noPlaceholder
					min={listOptions[0]}
					max={listOptions[listOptions.length - 1]}
				/>
			case "RADIO":
				return <div className={`mt-m ${editMode ? "" : "opacity-05"}`}><RadioInputGroup
					groupLabel={param.displayName ? param.displayName : camelToTitle(param.name)}
					options={optionStrings}
					value={editor}
					onChange={(value) => { editMode && onInputChange(value) }}
					invalid={ (!editor || editor === "") ? "invalid" : undefined}
				/></div>
			default:
				return <DetailField
					type={type}
					variant="light"
					invalid={ (!editor || editor === "") ? "invalid" : undefined}
					editMode={editMode}
					autoFocus
					label={param.displayName ? param.displayName : camelToTitle(param.name)}
					value={editor}
					onChange={(e) => onInputChange(e.target.value)}
					noPlaceholder
				/>
		}
	}



	const update = () => {
		remote.put(`/crm/templates/${template}/publish-steps/${action.stepId}/${param.name}`, { "value": editor }).then(setSelectedPublishActions);
	}

	return (
		<div className="param-field" >
			{getInputType(param.inputType)}
			{/* <Button className="mt-s" disabled={editor === value} onClick={update}>Save</Button> */}
		</div>
	)
};

export default ParamField;
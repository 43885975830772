import React, { useState } from 'react';
import { SortableSidebarSection } from './SideBarSection';
import { onDrop } from '../../../Utils/Utils';
import TextInput from '../../Input/TextInput';
import arrayMove from "array-move";
import TickerGroupSelector from './TickerGroupSelector';
import AlertBlock from '../../../layouts/Shared/Prompts/AlertBlock';
import Dropzone from "../../Dropzone/Dropzone";
import { TEMPLATE_TICKER_TYPES } from './EditorSideBarExtension';
import FundSelector from './FundSelector';
import SchemeSelector from './SchemeSelector';
import { ChevronDown, ChevronUp } from 'react-feather';
import BrandSelector from './BrandSelector';

const AssetSelectionSection = ({ setState, state, updateTickerStatus, showModal, updateTickerGroup }) => {
	const { selectedTickerGroup, tickers, tickerMessage, loadingLocalData, template, uploading, showUploadingSpinner, uploadingMessage } = state
	const [showDropBox, setShowDropBox] = useState(false)
	const [startAnimation, setStartAnimation] = useState(false)

	const reorderTicker = ({ oldIndex, newIndex }) => {
		document.body.style.cursor = 'default';
		setState((prevState) => {
			prevState.selectedTickerGroup.tickers = arrayMove(prevState.selectedTickerGroup.tickers, oldIndex, newIndex);
			prevState.tickers = prevState.selectedTickerGroup.tickers.filter(ticker => prevState.tickers.includes(ticker));
			return prevState;
		}, () => updateTickerStatus(true));
	};

	const uploadDataFiles = (files) => {
		onDrop(files, '/data/upload', { acceptedFileTypes: 'spreadsheetml', maxFileSize: 5 * 1024 * 1024, setLoading: (val) => setState({ uploading: val, showUploadingSpinner: val }), onUploadComplete: onUploadComplete })
	}

	const onUploadComplete = (e) => {
		//Reload data, if we've got a ticker
		const resp = JSON.parse(e.target.response);
		if (!resp.success) {
			showModal("uploadFailure");
			setState({ uploading: false, showUploadingSpinner: false, uploadingMessage: resp.message });
		} else {
			setState({ showUploadingSpinner: false, uploadingMessage: resp.message });
			setTimeout(() => setState({ uploading: false }), 1500);
			updateTickerStatus(true);
			onUpdateTickerGroup();
		}
	}

	const onUpdateTickerGroup = () => {
		updateTickerGroup();
	}

	const onCheckboxTickerToggle = (ticker, checked) => {
		const newTickers = selectedTickerGroup.tickers.filter(tgTicker => tgTicker === ticker ? !checked : tickers.includes(tgTicker));
		setState({ tickers: newTickers }, updateTickerStatus);
	}

	const onTickerTextChange = (event, index, callback) => {
		let value = event.currentTarget.value.toUpperCase();
		setState({ tickers: [value] }, callback)
	}

	let component = null;

	if (template?.ticker) {
		switch (template.ticker.type) {
			case TEMPLATE_TICKER_TYPES.OPEN:
				component = <TickerSelection setState={setState} updateTickerStatus={updateTickerStatus} ticker={tickers[0]} idx={0} />
				break;
			case TEMPLATE_TICKER_TYPES.SELECTION:
				component = <TickerGroupSelector
					template={template}
					setState={setState}
					onUpdateTickerGroup={onUpdateTickerGroup}
					selectedTickerGroup={selectedTickerGroup}
					tickers={tickers}
					tickerMessage={tickerMessage}
					loadingLocalData={loadingLocalData}
					onCheckboxTickerToggle={onCheckboxTickerToggle}
				/>
				break;
			case TEMPLATE_TICKER_TYPES.FUND:
				component = <FundSelector setState={setState} state={state} updateTickerStatus={updateTickerStatus} tickerOption={template.ticker} onUpdateTickerGroup={onUpdateTickerGroup} />
				break;
			case TEMPLATE_TICKER_TYPES.SCHEME:
				component = <SchemeSelector setState={setState} template={template} state={state} onUpdateTickerGroup={onUpdateTickerGroup} />
				break
			case TEMPLATE_TICKER_TYPES.BRAND:
				component = <BrandSelector setState={setState} template={template} state={state} updateTickerStatus={updateTickerStatus} tickerOption={template.ticker} />
				break
		}
	}

	return (
		<SortableSidebarSection
			separator
			onSortStart={() => (document.body.style.cursor = "grabbing")}
			onSortEnd={reorderTicker}
			helperClass="drag-item"
		>
			{component}
			<div className={`flex-align-center justify-content-between pr-s mb-s${component ? ' mt-xl' : ' mt-none'}`}>
				<p className={`label-input mb-none`}>Market Data Spreadsheet</p>
				{showDropBox
					? <ChevronUp size={'1rem'} className='colour-text-primary' cursor='pointer' onClick={() => {
						setStartAnimation(true);
						setTimeout(() => {
							setStartAnimation(false)
							setShowDropBox(false)
						}, 200);
					}} />
					: <ChevronDown size={'1rem'} className='colour-text-neutral-dark-40' cursor='pointer' onClick={() => setShowDropBox(true)} />}
			</div>

			{showDropBox && <div className={startAnimation ? 'drop-box-fade-up' : !showDropBox ? '' : 'drop-box-fade-down'}>
				<Dropzone
					onDrop={uploadDataFiles}
					uploading={uploading}
					showUploadingSpinner={showUploadingSpinner}
					uploadingMessage={uploadingMessage}
					disabled={!showDropBox}
				/>
			</div>}
			{tickerMessage[tickers[0]] && (
				<AlertBlock
					justify="center"
					className="mt-m"
					type={tickerMessage[tickers[0]] !== "No data available" ? "positive" : "warning"}
					small
					fullWidth
				>
					{tickerMessage[tickers[0]]}
				</AlertBlock>
			)}
		</SortableSidebarSection>
	);
}

export default AssetSelectionSection;


const TickerSelection = ({ setState, updateTickerStatus, ticker, idx }) => {

	const blurOnEnter = (e) => {
		if (e.key === 'Enter') {
			e.target.blur()
		}
	}

	return <TextInput
		label="Ticker"
		// size="small"
		onChange={(e) => setState({ tickers: [e.target.value.toUpperCase()] })}
		onBlur={(e) => updateTickerStatus(true)}
		onKeyDown={(e) => blurOnEnter(e)}
		name="ticker"
		value={ticker && ticker}
		placeholder="Enter Ticker..."
	/>
}

import React from "react";
import { X } from "react-feather";
import OverlayWrapper from "../../layouts/OverlayWrapper";
import Button from "../Buttons/Button";
import ButtonGroupWrapper from "../Buttons/ButtonGroupWrapper";
import "./Modal.scss";
import ReactDOM from "react-dom";
import FlexWrapper from "../../layouts/FlexWrapper";

const ModalButton = ({ type }) => {
	const { variant, className, onClick, disabled, label } = type;
	return (
		<Button variant={variant} className={className ? className : ""} onClick={onClick} disabled={disabled}>
			{label}
		</Button>
	);
}


const Modal = ({ header, handleClose, large, children, alignModal, cancelButton, confirmButton, padding, className, footer, overlayClick, onMouseOver, onKeyDown, overlayStyle, overlayClass, modalStyle, modalHeaderClass, modalContentStyle }) => {
	let modalClass = "modal";
	if (large) {
		modalClass += " size-large";
	}

	if (className) {
		modalClass += " " + className;
	}

	if (padding) {
		modalClass += (" padding-" + padding);
	}

	try {
		return ReactDOM.createPortal(
			<OverlayWrapper alignItems={alignModal} onMouseOver={onMouseOver} onClick={overlayClick} onKeyDown={onKeyDown} style={overlayStyle} overlayClass={overlayClass}>
				<div className={modalClass} style={modalStyle}>
					{/* MODAL HEADER */}
					<div className={`modal-header${modalHeaderClass ? " " + modalHeaderClass : ""}`}>
						<h3 className="font-weight-semi-bold">{header}</h3>
						<X className="modal-close-icon" onClick={handleClose} />
					</div>
					{/* MODAL CONTENT */}
					<div className="modal-content" style={modalContentStyle}>{children}</div>
					{/* MODAL BUTTONS */}
					{(cancelButton || confirmButton) && (
						<FlexWrapper className="pt-xxl" justify="space-between">
							<div>
								{footer}
							</div>
							<ButtonGroupWrapper className="pt-xxl" justifyContent="space-between">
								{cancelButton && <ModalButton type={{ variant: "secondary", label: "Cancel", ...cancelButton }} />}
								{confirmButton && <ModalButton type={{ variant: "", label: "Confirm", ...confirmButton }} />}
							</ButtonGroupWrapper>
						</FlexWrapper>
					)}
				</div>
			</OverlayWrapper>,
			document.querySelector("#modal-portal")
		);
	} catch (error) {
		return ReactDOM.createPortal(<OverlayWrapper alignItems={alignModal} onClick={overlayClick} overlayClass={overlayClass}>
			<div className={modalClass}>
				<div className="modal-header">
					<h3 className="font-weight-semi-bold">An unexpected error occurred</h3>
					<X className="modal-close-icon" onClick={handleClose} />
				</div>
				<div className="modal-content">This may have been caused by corruption in your browser.  Please try refreshing the page, and remove any caches.  If the problem persists, please conact 3Skye.</div>
			</div>
		</OverlayWrapper>,
			document.querySelector("#modal-portal")
		);
	}

};

export default Modal;

import React, { useEffect, useState } from 'react';
import { Edit2, Plus, Save, X, ChevronDown, ChevronUp } from 'react-feather';
import { useRemote } from '../../../Utils/Utils';
import { PAGES, ROUTE_PATHS } from '../../../InternalApiApp'
import { format, parseISO } from 'date-fns';
import withRouteChange from "@threeskye/route-change"
import Table from '../../../components/Table/Table';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridItem from '../../Shared/Page/PageGridItem';
import Divider from '../../../components/Divider';
import FlexWrapper from '../../FlexWrapper';
import DetailField from '../../Shared/DetailSection/DetailField';
import Button from '../../../components/Buttons/Button';
import SearchTermFilter from '../../../components/Input/SearchTermFilter';
import "./ModelPortfolioPage.scss"


const ModelsList = ({ changeRoute }) => {
    const [models, seModels] = useState([])
    const [filteredModels, setFilteredModels] = useState([])
    const [hasSearchTerm, setHasSearchTerm] = useState("")
    const [newGroupingName, setNewGroupingName] = useState('')
    const [showCreateGroupingForm, setShowCreateGroupingForm] = useState(false)
    const [sortingColumn, setSortingColumn] = useState({ column: "Name", ascending: false })
    const [loading, setLoading] = useState(true)
    const [editingGroupingName, setEditingGroupingName] = useState(null)
    const [noModels, setNoModels] = useState(false);

    const remote = useRemote();

    // Clear Sorting
    useEffect(() => {
        getFreshData()
        sortTable()
    }, [])

    useEffect(() => {
        setSortingColumn({ column: "Name", ascending: true })
    }, [models, filteredModels])

    function toggleSort(column) {
        if (column === null) {
            setSortingColumn({ column: "Name", ascending: true })
            return
        }
        const ascending = sortingColumn.column === column ? !sortingColumn.ascending : true
        setSortingColumn({ column: column, ascending: ascending })
    }

    function sortTable(dataToSort) {
        if (sortingColumn.column === null) {
            return dataToSort
        }
        if (!dataToSort) {
            return dataToSort
        }

        let tableData = dataToSort
        const ascending = sortingColumn.ascending
        switch (sortingColumn.column) {
            case "Name":
                tableData.sort((a, b) => ascending ? a.modelName.localeCompare(b.modelName) : b.groupName.localeCompare(a.modelName))
                break;
            case "Asset Classes":
                tableData.sort((a, b) => {
                    const groups1 = a.groups && a.groups.length ? a.groups.length : 0
                    const groups2 = b.groups && b.groups.length ? b.groups.length : 0
                    return ascending ? groups1 - groups2 : groups2 - groups1
                })
                break;
            case "Last Modified":
                tableData.sort((a, b) => {
                    const groups1 = a.lastModified || '-'
                    const groups2 = b.lastModified || '-'
                    return ascending ? groups1.localeCompare(groups2) : groups2.localeCompare(groups1)
                })
                break;
            case "Date Created":
                tableData.sort((a, b) => {
                    const groups1 = a.dateCreated || '-'
                    const groups2 = b.dateCreated || '-'
                    return ascending ? groups1.localeCompare(groups2) : groups2.localeCompare(groups1)
                })
                break;
            default:
                break;
        }
        return tableData
    }

    const modelsData = hasSearchTerm ? filteredModels : models
    const sortedModels = sortTable(modelsData,)

    function getFreshData() {
        remote.get("/model-portfolio/models").then(groupings => {
            if (groupings && groupings.length) {
                const filteredGroupings = groupings.filter(g => g.groupName !== 'Sectors');
                if (filteredGroupings.length > 0) {
                // if (groupings.length > 0) {
                    // let modelsWithGroups = groupings.map(model => {
                    let modelsWithGroups = filteredGroupings.map(model => {
                        return remote.get(`/model-portfolio/model/${model.id}`)
                    })
                    Promise.all(modelsWithGroups).then((fetchedGroupings) => {
                        seModels(fetchedGroupings)
                        setNoModels(false)
                        setLoading(false);
                        setShowCreateGroupingForm(false)
                    })

                } else {
                    setLoading(false);
                    setNoModels(true);
                }

            } else {
                setLoading(false);
                setNoModels(true);
            }
        })
    }

    function createGrouping(name) {
        remote.post(`/model-portfolio/model`, { modelName: name, focus: null, HasWeightings: null }).then(resp => {
            getFreshData()
        })
    }

    function updateGroupingName(name, grouping) {
        remote.put(`/model-portfolio/model/${grouping.id}`, { ...grouping, "modelName": name })
            .then(() => {
                getFreshData()
            })
    }

    const modelsTableColWidths = [400 + "px", 15 + "%", 15 + "%", 15 + "%"]
    const modelsTableHeaders = [
        {
            text: (
                <div className='sorting-header' onClick={() => toggleSort("Name")} >
                    Name
                    {sortingColumn && sortingColumn.column === "Name" && sortingColumn.ascending === true ?
                        <ChevronUp className="colour-text-primary" size={12} /> :
                        <ChevronDown className={sortingColumn && sortingColumn.column === "Name" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                    }
                </div>
            )
        },
        {
            text: (
                <div className='sorting-header' onClick={() => toggleSort("Asset Classes")} >
                    Asset Classes
                    {sortingColumn && sortingColumn.column === "Asset Classes" && sortingColumn.ascending === true ?
                        <ChevronUp className="colour-text-primary" size={12} /> :
                        <ChevronDown className={sortingColumn && sortingColumn.column === "Asset Classes" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                    }
                </div>
            )
        },
        {
            text: (
                <div className='sorting-header' onClick={() => toggleSort("Last Modified")} >
                    Last Modified
                    {sortingColumn && sortingColumn.column === "Last Modified" && sortingColumn.ascending === true ?
                        <ChevronUp className="colour-text-primary" size={12} /> :
                        <ChevronDown className={sortingColumn && sortingColumn.column === "Last Modified" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                    }
                </div>
            )
        },
        {
            text: (
                <div className='sorting-header' onClick={() => toggleSort("Date Created")} >
                    Date Created
                    {sortingColumn && sortingColumn.column === "Date Created" && sortingColumn.ascending === true ?
                        <ChevronUp className="colour-text-primary" size={12} /> :
                        <ChevronDown className={sortingColumn && sortingColumn.column === "Date Created" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                    }
                </div>
            )
        }
    ]
    const modelsTableDataMap = (dataToMap) => {
        return dataToMap.map((model) => {
            const { modelName, id, urlSafeName, modelClasses, createdDate } = model
            return (
                <tr className='tr-borders cursor-pointer' key={modelName + '-' + id} onClick={() => changeRoute(`${ROUTE_PATHS[PAGES.ADMIN]}/model-portfolio/${urlSafeName.toLowerCase()}/${id}`)}>
                    <td className="model-portfolio-class-name" style={{ border: "0" }}>
                        {editingGroupingName && editingGroupingName.groupingId === id
                            ? <FlexWrapper direction="row" gap="s" style={{ height: '1.3rem' }}>
                                <input autoFocus onClick={(e) => e.stopPropagation()} className="model-portfolio-class-input" value={editingGroupingName.value} onChange={(e) => { setEditingGroupingName({ groupingId: id, value: e.target.value }) }} />
                                <Divider height={24} dark vertical />
                                <Save className="model-portfolio-class-save-icon" style={{ display: "block" }} onClick={(e) => {
                                    e.stopPropagation()
                                    setEditingGroupingName(null)
                                    updateGroupingName(editingGroupingName.value, model)
                                }} />
                                <X size={22} color="#999DAD" onClick={(e) => { e.stopPropagation(); setEditingGroupingName(null) }} />
                            </FlexWrapper>
                            : <div style={{ display: 'flex', alignItems: 'center' }}>
                                <p style={{ paddingLeft: 2 }}>{modelName}</p>
                                <Edit2 className="model-portfolio-class-edit-icon" onClick={(e) => { e.stopPropagation(); setEditingGroupingName({ groupingId: id, value: modelName }) }} />
                            </div>}
                    </td>
                    <td>{modelClasses.length}</td>
                    <td>-</td>
                    <td>{format(parseISO(createdDate), 'hh:mmaaa dd/MM/yyyy')}</td>
                </tr>
            )
        })
    }


    if (noModels) {
        return <Page>
            <PageContentBanner divider gap='m' align='center'>
                {showCreateGroupingForm
                    ? <div className='model-portafolio-banner-create-grouping'>
                        <FlexWrapper direction="row" gap="m">
                            <PageGridItem container col="1 / span 2">
                                <DetailField editMode={true} placeholder="Model Name" value={newGroupingName} onChange={(e) => setNewGroupingName(e.target.value)} />
                            </PageGridItem>
                            <PageGridItem container col="5 / span 2">
                                <FlexWrapper direction="row" gap="m">
                                    <Divider height={24} dark vertical />
                                    <Save size={22} color="#0D8EFD" className='cursor-pointer' onClick={() => createGrouping(newGroupingName)} />
                                    <X size={22} color="#999DAD"  className='cursor-pointer' onClick={() => { setShowCreateGroupingForm(false); setNewGroupingName("") }} />
                                </FlexWrapper>
                            </PageGridItem>
                        </FlexWrapper>
                    </div>
                    : <Button icon={<Plus />} onClick={() => setShowCreateGroupingForm(true)}>Create Model</Button>
                }
            </PageContentBanner>
            <PageContentBodyGrid id="model-portfolio-page" rowGap="xl" >
                <PageGridItem container col="1 / span 12">
                    No model portfolios created
                </PageGridItem>
            </PageContentBodyGrid>
        </Page>
    }

    return (
        <Page>
            {/* Page banner with back button */}
            <PageContentBanner divider gap='m'>
                <SearchTermFilter
                    size="small"
                    width="l"
                    placeholder={`Search`}
                    isClearable
                    dataSet={models}
                    setFilteredData={setFilteredModels}
                    path={["groupName"]}
                    hasSearchTerm={(boolean) => setHasSearchTerm(boolean)}
                />
                {showCreateGroupingForm
                    ? <div className='model-portafolio-banner-create-grouping'>
                        <FlexWrapper direction="row" gap="m">
                            <PageGridItem container col="1 / span 2">
                                <DetailField editMode={true} placeholder="Model Name" value={newGroupingName} onChange={(e) => setNewGroupingName(e.target.value)} />
                            </PageGridItem>
                            <PageGridItem container col="5 / span 2">
                                <FlexWrapper direction="row" gap="m">
                                    <Divider height={24} dark vertical />
                                    <Save size={22} color={newGroupingName.length === 0 ? '#999DAD' : '#0D8EFD'} className={newGroupingName.length === 0 ? 'disabled-icon' : 'cursor-pointer'} onClick={() => newGroupingName.length === 0 ? null : createGrouping(newGroupingName)} />
                                    <X size={22} color="#999DAD" className='cursor-pointer' onClick={() => { setShowCreateGroupingForm(false); setNewGroupingName("") }} />
                                </FlexWrapper>
                            </PageGridItem>
                        </FlexWrapper>
                    </div>
                    : <Button icon={<Plus />} onClick={() => setShowCreateGroupingForm(true)}>Create Model</Button>
                }
            </PageContentBanner>
            {/* Page content */}
            <PageContentBodyGrid id="model-portfolio-page" rowGap="xl" >
                {/* grid container for the ticker details section */}
                <PageGridItem container col="1 / span 12" style={{ marginBottom: "2rem" }}>
                    {sortedModels && <Table
                        minWidth={450}
                        colWidths={modelsTableColWidths}
                        headers={modelsTableHeaders}
                        dataMap={modelsTableDataMap(sortedModels)}
                        dataLoading={loading}
                    />}
                </PageGridItem>
            </PageContentBodyGrid>
        </Page >
    );
}

export default withRouteChange(ModelsList);
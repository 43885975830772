import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ChevronUp, ChevronDown, Edit2 } from 'react-feather';
import Table from '../../../components/Table/Table';
import Page, { PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridItem from '../../Shared/Page/PageGridItem';
import PageGridDivider from '../../Shared/Page/PageGridDivider'
import { useRemote } from '../../../Utils/Utils';
import { PAGES, ROUTE_PATHS, BannerContext } from '../../../InternalApiApp';
import SearchTermFilter from '../../../components/Input/SearchTermFilter'
import { formatDate } from '../../../Utils/Utils';


const SchemesList = ({ }) => {
	const { setBannerBreadCrumbs } = useContext(BannerContext);
	const remote = useRemote();
	const history = useHistory()

	const [sortingColumn, setSortingColumn] = useState({ column: 'dateAdded', ascending: true })
	const [schemeData, setSchemeData] = useState([]);
	const [hasSchemeSearchTerm, setHasSchemeSearchTerm] = useState(false);
	const [filteredSchemeData, setFilteredSchemeData] = useState([])
	const [loadingSchemes, setLoadingSchemes] = useState(true)

	const schemeDataDisplaying = hasSchemeSearchTerm ? filteredSchemeData : schemeData
	// const discloseInputRef = React.createRef();

	useEffect(() => {
		setBannerBreadCrumbs({ tab: 'Schemes' })
		remote.get("/funds/schemes").then((schemes) => {
			setSchemeData(schemes)
			setLoadingSchemes(false)
		});
	}, [remote])

	// Clear Sorting
	useEffect(() => {
		setSortingColumn({ column: "dateAdded", ascending: true })
	}, [filteredSchemeData])

	const toggleSort = (column) => {
		if (column === null) {
			setSortingColumn({ column: "dateAdded", ascending: true })
			return
		}

		const ascending = sortingColumn.column === column ? !sortingColumn.ascending : true
		setSortingColumn({ column: column, ascending: ascending })
	}

	const sortTable = (dataToSort) => {
		if (sortingColumn.column === null) {
			return dataToSort
		}
		if (!dataToSort) {
			return dataToSort
		}

		let tableData = dataToSort
		const ascending = sortingColumn.ascending
		const column = sortingColumn.column;

		tableData.sort((a, b) => {
			if (column === 'dateAdded' || column === 'lastModified') {
				const sch1 = formatDate(a.dateAdded, true)
				const sch2 = formatDate(b.dateAdded, true)
				return ascending ? sch1.localeCompare(sch2) : sch2.localeCompare(sch1)
			} else return ascending ? a[column].localeCompare(b[column]) : b[column].localeCompare(a[column])
		})

		return tableData
	}

	// Table widths and headers
	const schemesColWidths = ['auto', 15 + '%', 15 + '%', 15 + '%', '50px'];
	const schemesHeaderLabels = [
		{ heading: 'Scheme Name', field: 'schemeName' },
		{ heading: 'Scheme Number', field: 'schemeNumber' },
		{ heading: 'Date Added', field: 'dateAdded' },
		{ heading: 'Last Modified', field: 'lastModified' }
	]

	const schemesHeaders = schemesHeaderLabels.map((column) => {
		return (
			{
				text: (
					<div className='sorting-header' onClick={() => toggleSort(column.field)} >
						{column.heading}
						{sortingColumn?.column === column.field && sortingColumn?.ascending === true ?
							<ChevronUp className='colour-text-primary' size={12} /> :
							<ChevronDown className={sortingColumn?.column === column.field ? 'colour-text-primary' : 'colour-text-neutral-dark-30'} size={12} />
						}
					</div>
				)
			}
		)
	})

	const schemeDataMap = (data) => data && data.map((scheme, idx) => {
		const { schemeName, schemeNumber, dateAdded, lastModified } = scheme

		return (
			<>
				<tr key={idx} className='cursor-pointer' onClick={() => history.push(ROUTE_PATHS[PAGES.ADMIN] + '/fund-management/schemes/' + scheme.schemeNumber)}>
					<td>{schemeName}</td>
					<td>{schemeNumber}</td>
					<td>{formatDate(dateAdded)} </td>
					<td>{formatDate(lastModified)} </td>
					<td>
						<div style={{ display: 'flex', gap: 10, alignItems: 'center', justifyContent: 'right' }}>
							<Edit2 className='colour-text-primary' />
						</div>
					</td>
				</tr>
			</>);
	})

	const sortedDataToDisplay = sortTable(schemeDataDisplaying)
	const dataToDisplay = !!sortedDataToDisplay ? schemeDataMap(sortedDataToDisplay) : null

	// const discloseImport = () => {
	// 	const id = discloseInputRef.current.value;
	// 	console.log("Importing ", id);

	// 	remote.post(`/funds/funds/schemes/load?schemeNumber=${id}`).then(resp => {
	// 		if (resp.success) {
	// 			toastSuccess("Imported")
	// 			discloseInputRef.current.value = '';
	// 			remote.get("/funds/schemes").then(setSchemeData);
	// 		} else {
	// 			toastDanger("Failure importing")
	// 		}
	// 	});
	// }

	return (
		<Page noBanner>
			<PageContentBodyGrid rowGap="xl">
				<PageGridItem colSpan='12' gap='l' direction='col'>
					<PageGridItem direction='row' gap='l' alignItems='center'>
						<SearchTermFilter
							size='small'
							width='m'
							placeholder='Search'
							isClearable
							dataSet={schemeData}
							setFilteredData={setFilteredSchemeData}
							path={['schemeName', 'schemeNumber']}
							hasSearchTerm={(boolean) => setHasSchemeSearchTerm(boolean)}
						/>
						{/* <Divider height={32} vertical />
						<div className='text-input-wrapper text-field-width-m'><input type="text" ref={discloseInputRef} placeholder='Type an Offer Number' className='text-input input-size-small' /></div>
						<Button onClick={discloseImport}>Import from Disclose</Button> */}
					</PageGridItem>
					<PageGridDivider />
				</PageGridItem>
				<PageGridItem colSpan='12' >
					<Table
						minWidth={1100}
						tableStyle={{ maxWidth: 1200 }}
						colWidths={schemesColWidths}
						headers={schemesHeaders}
						dataMap={dataToDisplay}
						id='scheme-and-funds-expandable-table'
						dataLoading={loadingSchemes}
					/>
				</PageGridItem>
			</PageContentBodyGrid>
		</Page >
	)

}

export default SchemesList;
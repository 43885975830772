import React, { useEffect, useState } from 'react';
import "./ExpandableTableRow.scss";
import { ChevronDown, ChevronUp } from 'react-feather';

const ExpandableTableRow = ({ children, className, expandContent, manuallySetExpandActive, onClick, expandRowClassname, dontExpand, showRowChevron, rowChevronColour, emptyChevronTd, numberOfCols }) => {
	const [expandActive, setExpandActive] = useState(false);

	useEffect(() => {
		if (manuallySetExpandActive === true || manuallySetExpandActive === false) {
			setExpandActive(manuallySetExpandActive);
		}
	}, [manuallySetExpandActive]);

	let classes = "expandable-table-row cursor-pointer";
	let expandRowClasses = "expand-content-row";

	if (className) {
		classes += " " + className;
	}
	if (expandActive && !dontExpand) {
		classes += " expand-active";
		expandRowClasses += " expand-active";
	}
	if (expandRowClassname) {
		expandRowClasses += " " + expandRowClassname;
	}

	return (
		<>
			<tr className={classes} onClick={() => {
				!manuallySetExpandActive && setExpandActive(!expandActive)
				onClick && onClick()
			}}>
				{children}
				{showRowChevron
					? <td className="chevron-td" >
						{expandActive
							? <ChevronUp color={rowChevronColour
								? rowChevronColour
								: "#000"} />
							: <ChevronDown color={rowChevronColour
								? rowChevronColour
								: "#000"} />
						}</td>
					: emptyChevronTd
						? <td></td>
						: null
				}
			</tr>
			{!dontExpand &&
				<tr className={expandRowClasses}>
					<td colSpan={numberOfCols || numberOfCols === null ? numberOfCols : "100%"} className="expand-inner">{expandContent}</td>
				</tr>
			}
		</>
	);
}

export default ExpandableTableRow;
import React from "react";
import "./AdminPage.scss";
import { Route, Switch } from "react-router";
import AdminCompanyDisclaimersPage from "./AdminCompanyDisclaimersPage/AdminCompanyDisclaimersPage";
import { PAGES, ROUTE_PATHS } from "../../InternalApiApp";
import RolePermissionsList from "./RolePermissions/RolePermissionsList";
import UserManagement from "./UserManagement/UserManagement";
import SystemUserPage from "./UserManagement/SystemUserPage";
import TickerList from "./Ticker/TickerList";
import TickerPage from "./Ticker/TickerPage";
import TemplateList from "./Templates/TemplateList";
import TemplateMgmt from "./Templates/TemplateMgmt";
import SectorList from "./Sectors/SectorList";
import SectorPage from "./Sectors/SectorPage";
import EmailTemplates from "./System/EmailTemplates";
import EmailTemplatePage from "./System/EmailTemplatePage";
import ModelsList from "./ModelPortfolio/ModelsList";
import ModelPortfolioPage from "./ModelPortfolio/ModelPortfolioPage";
import NoAccess from "../../NoAccess";
import { hasFeature } from "../../Utils/Utils";
import SchemesList from './SchemesAndFunds/SchemesList'
import SchemePage from './SchemesAndFunds/SchemePage'
import FundsList from './SchemesAndFunds/FundsList'
import FundPage from './SchemesAndFunds/FundPage'
import OffersList from './SchemesAndFunds/OffersList'
import OfferPage from './SchemesAndFunds/OfferPage'
import StyleGuide from "./BrandSettings/StyleGuide";
import SubBrandPage from "./BrandSettings/SubBrandPage";
import SubBrandsList from "./BrandSettings/SubBrandsList";
import AssetClassesList from "./AssetClassManagement/AssetClassesList";
import AssetClassPage from './AssetClassManagement/AssetClassPage'

export const ADMIN_SUB_PAGES = { TICKERS: "tickers", DISCLAIMERS: "disclaimers", MAILING_LIST: "mailing-list", REVIEW_LIST: "review-list" };
export const ADMIN_SUB_PATHS = { [ADMIN_SUB_PAGES.DISCLAIMERS]: "/disclaimers", [ADMIN_SUB_PAGES.MAILING_LIST]: "/mailing-list", [ADMIN_SUB_PAGES.REVIEW_LIST]: "/review-list" };

const AdminPage = ({ me, organisation }) => {
	if (!me || !me.perms) {
		return <NoAccess />
	}

	return (
		<>
			<Switch>
				<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/fund-management"} component={hasFeature("FUNDS", SchemesList, me)} />
				<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/fund-management/schemes"} component={hasFeature("FUNDS", SchemesList, me)} />
				<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/fund-management/funds"} component={hasFeature("FUNDS", FundsList, me)} />
				<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/fund-management/offers"} component={hasFeature("FUNDS", OffersList, me)} />
				<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/fund-management/schemes/:schemeId"} component={hasFeature("FUNDS", SchemePage, me)} />
				<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/fund-management/funds/:fundId"} component={hasFeature("FUNDS", FundPage, me)} />
				<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/fund-management/offers/:offerId"} component={hasFeature("FUNDS", OfferPage, me)} />
				<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/tickers"} component={hasFeature("TICKERS", TickerList, me)} />
				<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/tickers/:ticker"} component={hasFeature("TICKERS", TickerPage, me)} />
				<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/model-portfolio"} component={hasFeature("MODEL_PORTFOLIOS", ModelsList, me)} />
				<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/model-portfolio/:modelName/:modelId"} component={hasFeature("MODEL_PORTFOLIOS", ModelPortfolioPage, me)} />
				<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/asset-class"} component={hasFeature("ASSET_CLASS_MGT", AssetClassesList, me)} />
				<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/asset-class/:asetClassId"} component={hasFeature("ASSET_CLASS_MGT", AssetClassPage, me)} />
				<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/sectors"} component={hasFeature("SECTORS", SectorList, me)} />
				<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/sectors/sector"} component={hasFeature("SECTORS", SectorPage, me)} />
				<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + ADMIN_SUB_PATHS[ADMIN_SUB_PAGES.DISCLAIMERS]} component={hasFeature("DISCLAIMER", AdminCompanyDisclaimersPage, me)} />
				<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/user-management"} component={() => hasFeature("USER_MANAGEMENT", <UserManagement me={me} />, me)} />
				<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/user-management/:userId"} component={hasFeature("USER_MANAGEMENT", SystemUserPage, me)} />
				<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/company-email-templates"} component={hasFeature("COMPANY_MANAGEMENT", EmailTemplates, me)} />
				<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/company-email-templates/:emailTemplateId"} component={hasFeature("COMPANY_MANAGEMENT", EmailTemplatePage, me)} />
				<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/role-permissions"} component={hasFeature("ROLES", RolePermissionsList, me)} />
				<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/templates/:template"} component={() => hasFeature("TEMPLATES", <TemplateMgmt me={me}/>, me)} />
				<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/templates"} component={hasFeature("TEMPLATES", TemplateList, me)} />
				<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/brand-settings/brand"} render={() => <StyleGuide me={me} organisation={organisation}/>} />
				<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/brand-settings/sub-brands/:subBrandId"} render={() => hasFeature("BRAND_MANAGEMENT", <SubBrandPage me={me} organisation={organisation}/>, me)} />
				<Route exact path={ROUTE_PATHS[PAGES.ADMIN] + "/brand-settings/sub-brands"} render={() => hasFeature("BRAND_MANAGEMENT", <SubBrandsList me={me} organisation={organisation}/>, me)} />

			</Switch>
		</>
	);
}

export default AdminPage;

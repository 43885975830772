import React, { useState } from 'react';
import { AlertCircle, CheckCircle, Upload } from 'react-feather';
import { format } from 'date-fns';
import Tooltip from '../../../components/popups/Tooltip';
import Checkbox from '../../../components/Checkbox';
import DataUploadFileName from './DataUploadFileName';
import "./DataManagerPage.scss";


const DataUploadExpandedRow = ({ upload, batchIndex }) => {
    const expandedColWidths = ["auto", "135px", "145px", "95px", "95px", "21%"]
    const [filters, setFilters] = useState(['FAILED'])

    function getStatus(status) {
        switch (status) {
            case 'PROCESSING':
            case 'PENDING':
                return <Upload size={20} color='#0E8EFD' />
            case 'COMPLETED':
                return <CheckCircle size={20} color={"#56D1C3"} />
            default:
                return <AlertCircle size={20} color={"#E66780"} />
        }
    }


    return (
        < tr >
            <td colspan='11' style={{ backgroundColor: '#FBFBFD', padding: '0 0 1rem' }}>
                <div style={{ display: 'grid', gridTemplateColumns: '112px 1fr' }}>
                    <div style={{ margin: '32px 0 1rem', padding: '0 1rem 1rem', borderRight: 'solid 1px #E7EAF2' }}>
                        <div style={{ fontSize: '0.85rem', fontWeight: 500, padding: '0 0 1.5rem' }}>Filters</div>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                            <Checkbox
                                // disabled={isFilterable(upload.uploadedFiles) ? false : true}
                                checked={filters.includes('COMPLETED')}
                                label={<div style={{ fontSize: '0.9rem' }}>Success</div>}
                                onChange={() => setFilters(filters.includes('COMPLETED') ? filters.length === 1 ? ['FAILED'] : filters.filter(f => f !== 'COMPLETED') : [ ...filters, 'COMPLETED' ])}
                            />
                            <Checkbox
                                // disabled={isFilterable(upload.uploadedFiles) ? false : true}
                                checked={filters.includes('FAILED')} 
                                label={<div style={{ fontSize: '0.9rem' }}>Failure</div>}
                                onChange={() => setFilters(filters.includes('FAILED') ? filters.length === 1 ? ['COMPLETED'] : filters.filter(f => f !== 'FAILED') : [ ...filters, 'FAILED' ])}
                            />
                        </div>
                    </div>
                    <table>
                        <colgroup>
                            {expandedColWidths.map((width, idx) => (
                                <col key={"col-" + idx} span="1" style={{ width }} />
                            ))}
                        </colgroup>
                        <thead>
                            <tr>
                                <th className="nested-table-empty-th small"><div className="nested-table-div-in-td no-top left">File Name</div></th>
                                <th className="nested-table-empty-th small"><div className="nested-table-div-in-td no-top">Type</div></th>
                                <th className="nested-table-empty-th small text-align-right"><div className="nested-table-div-in-td no-top pr-xxl">Funds Covered</div></th>
                                <th className="nested-table-empty-th small"><div className="nested-table-div-in-td no-top">Period end</div></th>
                                <th className="nested-table-empty-th small text-align-center"><div className="nested-table-div-in-td no-top">Status</div></th>
                                <th className="nested-table-empty-th small"><div className="nested-table-div-in-td no-top right align-items-center justify-content-between">Message</div></th>
                            </tr>
                        </thead>
                        <tbody>
                            {upload.uploadedFiles && upload.uploadedFiles.map((file, index) => {
                                if ((filters.includes(file.status)) || filters.length === 0) {
                                    return (
                                        <tr key={file.fileName + "-expanded-" + index}>
                                            <td className="nested-table-empty-td">
                                                <div style={{ height: 39, maxWidth: 'calc(100% - 1rem)', overflowY: 'hidden' }} className="nested-table-div-in-td left pr-l">
                                                    <DataUploadFileName upload={upload} file={file} batchIndex={batchIndex} />
                                                </div>
                                            </td>
                                            <td className="nested-table-empty-td"><div style={{ height: 39 }} className="nested-table-div-in-td">{file.type}</div></td>
                                            <td className="nested-table-empty-td text-align-right">
                                                <div style={{ height: 39 }} className="nested-table-div-in-td pr-xxl">
                                                    <Tooltip disabled={file.fundsCovered.length === 0} label={file.fundsCovered.map(fund => <div style={{ lineHeight: 1.5 }}>{fund}</div>)}>{file.fundsCovered.length}</Tooltip>
                                                </div>
                                            </td>
                                            <td className={`nested-table-empty-td`}><div style={{ height: 39 }} className="nested-table-div-in-td">{file.periodEnd ? format(new Date(file.periodEnd), 'dd/MM/yyyy') : '-'}</div></td>
                                            <td className="nested-table-empty-td" key={index}>
                                                <div className="nested-table-div-in-td text-align-center" style={{ padding: '0.6rem 0 0', height: 39 }}>{getStatus(file.status)}</div>
                                            </td>
                                            <td className="nested-table-empty-td"><div style={{ height: 39 }} className="nested-table-div-in-td right">{file.errorMessage}</div></td>
                                        </tr>)
                                } else return ""
                            }
                            )}
                        </tbody>
                    </table>
                </div>
            </td>
        </tr >
    )
}

export default DataUploadExpandedRow
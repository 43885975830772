import React, { useContext } from 'react';
import SearchTermFilter from '../../../components/Input/SearchTermFilter';
import Table from '../../../components/Table/Table';
import Page, { PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridItem from '../../Shared/Page/PageGridItem';
import { PAGES, ROUTE_PATHS, BannerContext } from '../../../InternalApiApp';
import { useState, useEffect } from 'react';
import { useRemote, minutesIntoReadableFormat } from '../../../Utils/Utils';
import Tooltip from '../../../components/popups/Tooltip';
import { Eye, TrendingUp, BookOpen, Clock, ChevronUp, ChevronDown } from 'react-feather';
import FlexWrapper from '../../FlexWrapper'
import PageGridDivider from '../../Shared/Page/PageGridDivider'
import LoadingIcon from '../../Shared/LoadingIcon'
import { useHistory } from 'react-router-dom';


const AnalyticsAuthorsList = ({ }) => {
    const { setBannerBreadCrumbs } = useContext(BannerContext);
    const remote = useRemote();
    const history = useHistory()

    const [filteredData, setFilteredData] = useState([]);
    const [hasSearchTerm, setHasSearchTerm] = useState(false);
    const [loading, setLoading] = useState(true);
    const [metrics, setMetrics] = useState(null)
    const [sortingColumn, setSortingColumn] = useState({ column: "Minutes", ascending: false })
    const [dataDisplaying, setDataDisplaying] = useState(null)
    const [authors, setAuthors] = useState([]);

    useEffect(() => {
        setBannerBreadCrumbs({ tab: 'Authors' })
        remote.get("/analytics/aggregates/authors").then(fetchedAuthors => {
            const allAuthors = fetchedAuthors
            const myMetrics = {}

            const totalLogins = allAuthors.reduce((acc, curr) => acc + curr.portalViews, 0)   // is this time that users spend on this author's research or is this time the author spends writing?
            const totalMinutes = allAuthors.reduce((acc, curr) => acc + curr.minutes, 0)   // is this the users logins looking at the author's research or is this the author logins?
            const mostResearchePublished = allAuthors.sort((a, b) => b.researchPublished - a.researchPublished)[0]
            const mostReadAuthor = allAuthors.sort((a, b) => b.minutes - a.minutes)[0]

            myMetrics.totalAuthors = allAuthors.length;
            myMetrics.averageSessionInMinutes = isNaN(Math.round(totalMinutes / totalLogins)) ? 0 + "m" : minutesIntoReadableFormat(Math.round(totalMinutes / totalLogins));
            myMetrics.mostResearchePublished = {name: mostResearchePublished?.name, publications: mostResearchePublished?.researchPublished }
            myMetrics.mostReadAuthor = {name: mostReadAuthor?.name, minutes: mostReadAuthor?.minutes }

            setAuthors(allAuthors)
            setMetrics(myMetrics)
            setLoading(false)
        }).then(() => setLoading(false))

    }, [])

    function displayMatricsValues(metrics, property, subProperty) {
        if (metrics && (metrics[property] === 0 || metrics[property])) {
            if (!subProperty) return <h1 className='total-metrics-value'>{metrics[property]}</h1>
            else if (subProperty && metrics[property][subProperty]) return <h1 className='total-metrics-value'>{metrics[property][subProperty]}</h1>
            else return <h1>&nbsp;</h1>
        } else return <h1>&nbsp;</h1>
    }

    // Sorting
    useEffect(() => {
        sortTable(null)
    }, [authors, filteredData])

    const sortTable = (column) => {
        if (column === null) {
            setDataDisplaying(hasSearchTerm ? filteredData : authors)
            return
        }
        let tableData = hasSearchTerm ? filteredData : authors
        const ascending = sortingColumn.column === column ? !sortingColumn.ascending : true
        switch (column) {
            case "Name":
                tableData.sort((a, b) => ascending ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name))
                break;
            case "Email":
                tableData.sort((a, b) => ascending ? a.email.localeCompare(b.email) : b.email.localeCompare(a.email))
                break;
            case "Role":
                tableData.sort((a, b) => ascending ? a.role.localeCompare(b.role) : b.role.localeCompare(a.role))
                break;
            case "Minutes":
                tableData.sort((a, b) => ascending ? a.minutes - b.minutes : b.minutes - a.minutes)
                break;
            case "TickersCovered":
                tableData.sort((a, b) => ascending ? a.tickersCovered - b.tickersCovered : b.tickersCovered - a.tickersCovered)
                break;
            case "ResearchPublished":
                tableData.sort((a, b) => ascending ? a.researchPublished - b.researchPublished : b.researchPublished - a.researchPublished)
                break;
            case "PortalViews":
                tableData.sort((a, b) => ascending ? a.portalViews - b.portalViews : b.portalViews - a.portalViews)
                break;
            default:
                break;
        }
        setSortingColumn({ column: column, ascending: ascending })
        setDataDisplaying(tableData)
    }

    // Table data
    const colWidths = ["275px", "275px", "270px", "80px", "80px", "80px", "80px"];
    const headers = [
        {
            text: (
                <div className='sorting-header' onClick={() => sortTable("Name")}>
                    Name
                    {sortingColumn && sortingColumn.column === "Name" && sortingColumn.ascending === true ?
                        <ChevronUp className="colour-text-primary" size={12} /> :
                        <ChevronDown className={sortingColumn && sortingColumn.column === "Name" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                    }
                </div>
            )
        },
        {
            text: (
                <div className='sorting-header' onClick={() => sortTable("Email")}>
                    Email
                    {sortingColumn && sortingColumn.column === "Email" && sortingColumn.ascending === true ?
                        <ChevronUp className="colour-text-primary" size={12} /> :
                        <ChevronDown className={sortingColumn && sortingColumn.column === "Email" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                    }
                </div>
            )
        },
        {
            text: (
                <div className='sorting-header' onClick={() => sortTable("Role")}>
                    Role
                    {sortingColumn && sortingColumn.column === "Role" && sortingColumn.ascending === true ?
                        <ChevronUp className="colour-text-primary" size={12} /> :
                        <ChevronDown className={sortingColumn && sortingColumn.column === "Role" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                    }
                </div>
            )
        },
        {
            text: (
                <Tooltip label="Minutes">
                    <div className='sorting-header' onClick={() => sortTable("Minutes")}>
                        <Clock className="cursor-pointer colour-text-primary" size={16} />
                        {sortingColumn && sortingColumn.column === "Minutes" && sortingColumn.ascending === true ?
                            <ChevronUp className="colour-text-primary" size={12} /> :
                            <ChevronDown className={sortingColumn && sortingColumn.column === "Minutes" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                        }
                    </div>
                </Tooltip>
            ),
            className: "text-align-center"
        },
        {
            text: (
                <Tooltip label="Tickers Covered">
                    <div className='sorting-header' onClick={() => sortTable("TickersCovered")}>
                        <TrendingUp className="cursor-pointer colour-text-primary" size={16} />
                        {sortingColumn && sortingColumn.column === "TickersCovered" && sortingColumn.ascending === true ?
                            <ChevronUp className="colour-text-primary" size={12} /> :
                            <ChevronDown className={sortingColumn && sortingColumn.column === "TickersCovered" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                        }
                    </div>
                </Tooltip>
            ),
            className: "text-align-center"
        },
        {
            text: (
                <Tooltip label="Research Published">
                    <div className='sorting-header' onClick={() => sortTable("ResearchPublished")}>
                        <BookOpen className="cursor-pointer colour-text-primary" size={16} />
                        {sortingColumn && sortingColumn.column === "ResearchPublished" && sortingColumn.ascending === true ?
                            <ChevronUp className="colour-text-primary" size={12} /> :
                            <ChevronDown className={sortingColumn && sortingColumn.column === "ResearchPublished" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                        }
                    </div>
                </Tooltip>
            ),
            className: "text-align-center"
        },
        {
            text: (
                <Tooltip label="Portal Views">
                    <div className='sorting-header' onClick={() => sortTable("PortalViews")}>
                        <Eye className="cursor-pointer colour-text-primary" size={16} />
                        {sortingColumn && sortingColumn.column === "PortalViews" && sortingColumn.ascending === true ?
                            <ChevronUp className="colour-text-primary" size={12} /> :
                            <ChevronDown className={sortingColumn && sortingColumn.column === "PortalViews" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                        }
                    </div>
                </Tooltip>
            ),
            className: "text-align-center"
        }
    ];

    const dataMap = (data) =>
        data &&
        data.map((datum, idx) => {
            return (
                <tr key={idx} className="cursor-pointer" onClick={() => history.push(ROUTE_PATHS[PAGES.ANALYTICS] + "/authors/author/" + datum.email)}>
                    {/* User details */}
                    <td style={{ paddingRight: "1.5rem" }} >{datum.name}</td>
                    <td style={{ paddingRight: "1.5rem" }} className="truncate">{datum.email}</td>
                    <td style={{ paddingRight: "1.5rem" }}>{datum.role}</td>
                    {/* Analytics icons */}
                    <td className="text-align-center">{minutesIntoReadableFormat(datum.minutes)}</td>
                    <td className="text-align-center">{datum.tickersCovered}</td>
                    <td className="text-align-center">{datum.researchPublished}</td>
                    <td className="text-align-center">{datum.portalViews}</td>
                </tr>
            );
        });


    return (
        <Page noBanner showScrollbar>
            {/* Modal */}
            <PageContentBodyGrid showScrollbar>
                <PageGridItem colSpan="12">
                    <FlexWrapper gap="xl" style={{ paddingBottom: "1rem" }}>
                        <FlexWrapper gap="xs" direction="column" align="flex-start" >
                            <label className='total-metrics-label'>Total Authors</label>
                            {loading ? <LoadingIcon size={32} /> : displayMatricsValues(metrics, 'totalAuthors')}
                        </FlexWrapper>
                        <FlexWrapper gap="xs" direction="column" align="flex-start" >
                            <label className='total-metrics-label'>Average Session Time</label>
                            {loading ? <LoadingIcon size={32} /> : displayMatricsValues(metrics, 'averageSessionInMinutes')}
                        </FlexWrapper>
                        <Tooltip disabled={!metrics || (metrics && metrics.mostResearchePublished && !metrics.mostResearchePublished.publications)} label={metrics && <div>{metrics.mostResearchePublished.publications + " publications"}</div>}>
                            <FlexWrapper gap="xs" direction="column" align="flex-start" >
                                <label className='total-metrics-label'>Most Research Published</label>
                                {loading ? <LoadingIcon size={32} /> : displayMatricsValues(metrics, 'mostResearchePublished', 'name')}
                            </FlexWrapper>
                        </Tooltip>
                        <Tooltip disabled={!metrics || (metrics && metrics.mostReadAuthor && !metrics.mostReadAuthor.minutes)} label={metrics && <div style={{ display: "flex", alignItems: "center", gap: 5 }}><Clock className="colour-text-primary" size={14} /> {" " + minutesIntoReadableFormat(metrics.mostReadAuthor.minutes)}</div>}>
                            <FlexWrapper gap="xs" direction="column" align="flex-start" >
                                <label className='total-metrics-label'>Most Read Author</label>
                                {loading ? <LoadingIcon size={32} /> : displayMatricsValues(metrics, 'mostReadAuthor', 'name')}
                            </FlexWrapper>
                        </Tooltip>
                    </FlexWrapper>
                    <PageGridDivider />
                    <PageGridItem style={{ paddingTop: "1.5rem" }}>
                        <SearchTermFilter
                            size="small"
                            width="l"
                            placeholder={`Search authors ${loading ? "" : "(" + authors.length + ")"}`}
                            isClearable
                            dataSet={authors}
                            setFilteredData={setFilteredData}
                            path={["name", "email"]}
                            hasSearchTerm={(boolean) => setHasSearchTerm(boolean)}
                        />
                    </PageGridItem>
                    <div className="table-title">All Authors</div>
                    <Table
                        minWidth={1000}
                        tableStyle={{ maxWidth: 1200 }}
                        colWidths={colWidths}
                        headers={headers}
                        dataMap={dataMap(dataDisplaying)}
                        noResults={{ message: "No authors found" }}
                        dataLoading={loading}
                    />
                </PageGridItem>
            </PageContentBodyGrid>
        </Page>
    );
}

export default AnalyticsAuthorsList;
import React, { useState, useEffect } from "react";
import { useStorage, handleDraftFiltering } from '../../Utils/Utils';
import { gicsSectors } from '../../Utils/DummyData';
import Table from "../../components/Table/Table"
import RequestsTableRow from "./RequestsTableRow";
import { useWebsockets } from "../../Utils/WebsocketReceiverHook";

const RequestsLibraryTable = ({ setRequestsLoading, filters }) => {
	const [expandedRows, setExpandedRows] = useState([])
	const [tickers, setTickers] = useState([])
	const [requests, setRequests] = useState([])
	const [rowActivePreview, setRowActivePreview] = useState(null)

	const storage = useStorage();
	const sockets = useWebsockets();


	useEffect(() => {
		storage.getOrFetch("/crm/tickers").then(setTickers)

		storage.refresh("/documents/requests")
			.then(filterSortAndSetData)
			.catch((err) => {
				console.error(err)
				setRequestsLoading(false)
			});
		storage.watch("/documents/requests", filterSortAndSetData);

		sockets.subscribe({
			"/api/documents/requests": [(message) => {
				storage.refresh("/documents/requests")
					.then(filterSortAndSetData)
					.catch((err) => {
						console.error(err)
						setRequestsLoading(false)
					});
			}]
		})



		return () => {
			storage.unwatch("/documents/requests")
			sockets.unsubscribe(["/api/documents/requests"])
		}

	}, [])

	useEffect(() => {
		refreshList()
	}, [filters])

	const filterSortAndSetData = (drafts, closeOpenModal) => {
		let nullsafeDrafts = drafts && Array.isArray(drafts) ? drafts : []
		let sortedDrafts = nullsafeDrafts.filter((doc) => {
			return handleDraftFiltering(doc.requestedAt, doc.ticker, filters)
		}).sort((a, b) => {
			if (a.date > b.date) return -1
			else if (a.date < b.date) return 1
			else return 0
		})
		setRequests(sortedDrafts)
		setRequestsLoading(false)
		if (closeOpenModal && nullsafeDrafts.length > 0) {
			const currentRow = rowActivePreview
			setRowActivePreview(null)
			setRowActivePreview(currentRow === nullsafeDrafts.length ? 0 : currentRow)
		}
	}

	const refreshList = (closeOpenModal) => storage.refresh("/documents/requests").then((drafts) => filterSortAndSetData(drafts, closeOpenModal))

	const getImage = (t) => {
		const sector = gicsSectors.find(s => s.name === t)
		const theTicker = tickers && tickers.find(ticker => ticker.ric === t)

		if (theTicker) return theTicker.logo
		else if (sector) return sector.image
		else return ""
	}

	const headerWidths = ["19%", "11%", "auto", "18%", "16%", "90px"];
	const headers = ["Created Date", "Coverage", "Title", "Author", "Approvals", "Actions"];

	const dataMap = requests.map((document, idx) => {

		return <RequestsTableRow
			data={document}
			idx={idx}
			tickers={tickers}
			expandedRows={expandedRows}
			setExpandedRows={setExpandedRows}
			getImage={getImage}
			refreshList={refreshList}
			allRequests={requests}
			setRowActivePreview={setRowActivePreview}
			rowActivePreview={rowActivePreview}
		/>
	});

	return (
		<Table
			id="requests-library-table"
			headerWidths={headerWidths}
			minWidth={1250}
			headers={headers}
			dataMap={dataMap}
			noResults={{ message: "No Requests", subMessage: "You're up to date." }}
			wrapperStyle={{ paddingTop: "2rem" }}
		/>
	);
}

export default RequestsLibraryTable;

export const RawDraftsLibraryTable = RequestsLibraryTable;

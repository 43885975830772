import React from "react";
import ProfileImage from "../../components/ProfileImage";
import Modal from "../../components/Modals/Modal";
import Table from "../../components/Table/Table";
import { User } from "react-feather";


const UserListModal = ({ handleClose, data }) => {

	const dataMap = data && data.map((user, index) => {
		return <tr key={"user-token-" + index}>
			<td className="flex-align-center" >
				<ProfileImage user={user} />
				<span className="ml-s mr-s">
					{user.name}
				</span>
				<span className="extension-label">
					{user.email}
				</span>
			</td>
		</tr>
	})

	return (
		<Modal
			handleClose={handleClose}
			overlayClick={handleClose}
			modalStyle={{ height: data.length > 10 ? '90%' : (data.length * 65) + 104, overflowY: 'auto' }}
			modalHeaderClass='pb-l'
			modalContentStyle={{ height: 'calc(100% - 3rem)', overflowY: 'scroll', paddingTop: 0 }}
			header={
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<User className="mr-m" color="#0E8EFD" />
					<span>{`Approvers Available (${data.length})`}</span>
				</div>
			}>
			<Table
				dataMap={dataMap}
			/>
		</Modal>
	);
};

export default UserListModal;

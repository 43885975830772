import React, { useEffect, useState } from 'react';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import { Search, ChevronDown, ChevronUp } from 'react-feather'
import { useRemote } from '../../../Utils/Utils';
import { format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { PAGES, ROUTE_PATHS } from '../../../InternalApiApp';
import PageGridItem from '../../Shared/Page/PageGridItem';
import SimpleSearchFilter, { filterBy } from '../../../components/Input/SimpleSearchFilter';
import Table from '../../../components/Table/Table';


const EmailTemplates = ({ }) => {
	const remote = useRemote();
	const history = useHistory();

	const [loading, setLoading] = useState(true)
	const [data, setData] = useState([])
	const [searchTerm, setSearchTerm] = useState('')
	const [sortingColumn, setSortingColumn] = useState({ column: "Name", ascending: true })

	useEffect(() => {
		remote.get("data/email/template/list").then(list => {
			setData(list)
			setLoading(false)
		}).catch(() => {
			setData([])
			setLoading(false)
		})
	}, [])

	// function setTemplatesEnabled(template) {
	// 	switch (template) {
	// 		case "peer":
	// 			setCompany({ ...company, peerReviewAvailable: !company.peerReviewAvailable })
	// 			break;
	// 		case "compliance":
	// 			setCompany({ ...company, complianceReviewAvailable: !company.complianceReviewAvailable })
	// 			break;

	// 		default:
	// 			break;
	// 	}
	// }

	// function getTokenState(template) {
	// 	switch (template) {
	// 		case "peer":
	// 			return company.peerReviewAvailable ? "positive" : ""
	// 		case "compliance":
	// 			return company.complianceReviewAvailable ? "positive" : ""
	// 		default:
	// 			break;
	// 	}

	// }
	// function getTokenMessage(template) {
	// 	switch (template) {
	// 		case "peer":
	// 			return company.peerReviewAvailable ? "Hide Peer Templates" : "Show Peer Templates"
	// 		case "compliance":
	// 			return company.complianceReviewAvailable ? "Hide Compliance Templates" : "Show Compliance Templates"
	// 		default:
	// 			break;
	// 	}
	// }

	// function getURL() {
	// 	switch (view) {
	// 		case company.complianceReviewEmailTemplate:
	// 			return "/client/review/compliance/template"
	// 		case company.complianceReviewApprovalEmailTemplate:
	// 			return "/client/review/compliance/approval/template"
	// 		case company.peerReviewEmailTemplate:
	// 			return "/client/review/peer/template"
	// 		case company.peerReviewApprovalEmailTemplate:
	// 			return "/client/review/peer/approval/template"
	// 		default:
	// 			break;
	// 	}
	// }

	// function handleFileUpload(e) {
	// 	let files = e.target.files;
	// 	setUploading(true);
	// 	const tooBig = [];
	// 	const wrongFileType = [];
	// 	let anyUploaded = false;
	// 	for (var i = 0; i < files.length; i++) {
	// 		let file = files[i];
	// 		let name = file.name;

	// 		anyUploaded = true;
	// 		let xhr = new XMLHttpRequest();
	// 		xhr.open('post', getURL(), true);
	// 		xhr.addEventListener('load', e => {
	// 			if (xhr.status >= 200 && xhr.status < 300) {
	// 				// onUploadComplete(e, asset);
	// 				toastSuccess("Upload Complete");
	// 			} else {
	// 				console.log(xhr);
	// 			}
	// 		});
	// 		xhr.upload.addEventListener('progress', e => {
	// 			if (e.lengthComputable) {
	// 				let complete = (e.loaded / e.total * 100 | 0);
	// 				if (complete === 100) {
	// 					//TODO onscreen reporting
	// 					console.log("Processing ...");
	// 				} else {
	// 					console.log("Finished");
	// 				}
	// 			}
	// 		});

	// 		xhr.setRequestHeader("Content-Type", file.type);
	// 		xhr.setRequestHeader("Content-Disposition", "attachment; filename=" + name);
	// 		xhr.send(file);
	// 	}

	// 	if (tooBig.length > 0 || wrongFileType.length > 0) {
	// 		toastDanger(`Upload Failed ${tooBig.length > 0 ? "file too large" : ""}${tooBig.length > 0 && wrongFileType.length > 0 ? ", " : ""}${wrongFileType.length > 0 ? "wrong file type" : ""}`)
	// 		if (!anyUploaded) {
	// 			setUploading(false)
	// 		}
	// 	}
	// }

	function filter(dataToFilter) {
		if (searchTerm === null || searchTerm.trim().size === 0) {
			return dataToFilter
		}
		return dataToFilter.filter((datum) => filterBy(datum, ["emailTemplateName", "emailType"], searchTerm))
	}

	function toggleSort(column) {
		if (column === null) {
			setSortingColumn({ column: "Name", ascending: true })
			return
		}
		const ascending = sortingColumn.column === column ? !sortingColumn.ascending : true
		setSortingColumn({ column: column, ascending: ascending })
	}

	function sortTable(dataToSort) {
		if (sortingColumn.column === null) {
			return dataToSort
		}

		let tableData = dataToSort
		const ascending = sortingColumn.ascending

		switch (sortingColumn.column) {
			case "Name":
				tableData.sort((a, b) => ascending ? a.emailTemplateName.localeCompare(b.emailTemplateName) : b.emailTemplateName.localeCompare(a.emailTemplateName))
				break;
			case "Type":
				tableData.sort((a, b) => {
					const temp1 = a.emailType.includes('Approval') ? 1 : 2
					const temp2 = b.emailType.includes('Approval') ? 1 : 2
					return ascending ? temp1 - temp2 : temp2 - temp1
				})
				break;
			case "Date Created":
				tableData.sort((a, b) => ascending ? a.createdAt.localeCompare(b.createdAt) : b.createdAt.localeCompare(a.createdAt))
				break;
			case "Last Modified":
				tableData.sort((a, b) => ascending ? a.lastUpdatedAt.localeCompare(b.lastUpdatedAt) : b.lastUpdatedAt.localeCompare(a.lastUpdatedAt))
				break;
			default:
				break;
		}
		return tableData
	}
	const filteredData = filter(data)
	const sortedData = sortTable(filteredData)

	const colWidths = ['50%', '16%', '17%', '17%']
	const headers = [
		{
			text: (
				<div className='sorting-header' onClick={() => toggleSort("Name")} >
					Name
					{sortingColumn && sortingColumn.column === "Name" && sortingColumn.ascending === true ?
						<ChevronUp className="colour-text-primary" size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === "Name" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
					}
				</div>
			)
		},
		{
			text: (
				<div className='sorting-header' onClick={() => toggleSort("Type")} >
					Type
					{sortingColumn && sortingColumn.column === "Type" && sortingColumn.ascending === true ?
						<ChevronUp className="colour-text-primary" size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === "Type" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
					}
				</div>
			)
		},
		{
			text: (
				<div className='sorting-header' onClick={() => toggleSort("Date Created")} >
					Date Created
					{sortingColumn && sortingColumn.column === "Date Created" && sortingColumn.ascending === true ?
						<ChevronUp className="colour-text-primary" size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === "Date Created" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
					}
				</div>
			)
		},
		{
			text: (
				<div className='sorting-header' onClick={() => toggleSort("Last Modified")} >
					Last Modified
					{sortingColumn && sortingColumn.column === "Last Modified" && sortingColumn.ascending === true ?
						<ChevronUp className="colour-text-primary" size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === "Last Modified" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
					}
				</div>
			)
		}
	];

	const dataMap = (dataToMap) => {
		return dataToMap.map((template) => {
			const { emailTemplateId, emailTemplateName, emailType, createdAt, lastUpdatedAt } = template
			return (
				<tr className='tr-borders cursor-pointer' key={emailTemplateName + '-' + emailTemplateId} onClick={() => history.push(ROUTE_PATHS[PAGES.ADMIN] + "/company-email-templates/" + emailTemplateId)}>
					<td style={{ paddingLeft: '0.5rem' }}>{emailTemplateName}</td>
					<td>{emailType.includes('Approval') ? 'Comfirmation' : 'Request'}</td>
					<td>{format(new Date(createdAt), 'dd/MM/yyyy')}</td>
					<td>{format(new Date(lastUpdatedAt), 'dd/MM/yyyy')}</td>
				</tr>
			)
		})
	}


	return (
		<Page fixedBanner>
			<PageContentBanner divider>
				<SimpleSearchFilter
					size='small'
					width='xxl'
					placeholder={`Search Email template`}
					isClearable
					icon={<Search />}
					setSearchTerm={setSearchTerm}
					searchTerm={searchTerm}
				/>
			</PageContentBanner>
			<PageContentBodyGrid id="client-details-page" rowGap="xl" gridTemplateRows="1fr" splitScroll>
				<PageGridItem container col="1 / span 12">
					<Table
						minWidth={800}
						colWidths={colWidths}
						headers={headers}
						dataMap={dataMap(sortedData)}
						dataLoading={loading}
					/>
				</PageGridItem>
			</PageContentBodyGrid >
		</Page >
	)
}

export default EmailTemplates;
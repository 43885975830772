import React, { useEffect, useState } from 'react';
import { ChevronDown, ChevronUp, MoreHorizontal } from 'react-feather';
import { useRemote, useStorage } from '../../../Utils/Utils';
import { PAGES, ROUTE_PATHS } from '../../../InternalApiApp'
import { toastDanger, toastSuccess } from '../../../components/popups/Toast';
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import withRouteChange from "@threeskye/route-change"
import PageGridItem from '../../Shared/Page/PageGridItem';
import FlexWrapper from '../../FlexWrapper';
import ActionButtonsBanner from '../../Shared/Banner/ActionButtonsBanner'
import AddAssetClass from './AddAssetClass'
import DropDown from '../../../components/DropDown';
import SimpleSearchFilter, { filterBy } from '../../../components/Input/SimpleSearchFilter';
import Divider from '../../../components/Divider';
import LoadingIcon from '../../../components/LoadingIcon';
import NoResultsMessage from '../../../components/EmptyMessage/NoResultsMessage';
import AssetActionsPopup from './AssetActionsPopup';
import Tooltip from '../../../components/popups/Tooltip';
import ImageEditModal from '../../../components/Modals/ImageEditModal';
import Modal from '../../../components/Modals/Modal';
import "../ModelPortfolio/ModelPortfolioPage.scss"


const AssetClassPage = ({ match, changeRoute }) => {
    const asetClassId = +match.params.asetClassId
    const remote = useRemote()
    const storage = useStorage()

    const [list, setList] = useState([])
    const [data, setData] = useState([])
    const [searchTerm, setSearchTerm] = useState("")
    const [loading, setLoading] = useState(true)
    const [sortingColumn, setSortingColumn] = useState({ column: "Name", ascending: true })
    const [editMode, setEditMode] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [tickers, setTickers] = useState(null)
    const [assetClassName, setAssetClassName] = useState("")
    const [allAssets, setAllAssets] = useState([])
    // edit logo
    const [showImageEditModal, setShowImageEditModal] = useState(null)
    const [imageUpload, setImageUpload] = useState(null)
    const [stagedImage, setStagedImage] = useState(null)
    const [onSaveListeners, setOnSaveListeners] = useState([])
    const [onCancelListeners, setOnCancelListeners] = useState([])
    const [editedAsset, setEditedAsset] = useState(null)


    useEffect(() => {
        storage.getOrFetch("/crm/tickers").then((fetchedTickers) => {
            const tickersObj = fetchedTickers.reduce((a, c) => ({ ...a, [c.isin]: c }), {})
            setTickers(tickersObj)
            refreshClassData()
        })
        storage.getOrFetch("assets/classes/list").then((resp) => {
            setList(resp)
        })
        storage.getOrFetch('assets/list').then(setAllAssets)
    }, [asetClassId, remote, storage])



    function refreshClassData() {
        remote.get(`assets/classes/${asetClassId}`).then(fetchedClass => {
            setData(fetchedClass.assets)
            setAssetClassName(fetchedClass.name)
            setLoading(false)
        })
    }

    function setOnSaveListener(func) {
        let newOnSaveListeners = [...onSaveListeners, func]
        setOnSaveListeners(newOnSaveListeners)
    }

    function setOnCancelListener(func) {
        let newOnCancelListeners = [...onCancelListeners, func]
        setOnCancelListeners(newOnCancelListeners)
    }

    function deleteAssetClass() {
        remote.delete(`assets/classes/${asetClassId}`).then((res) => {
            setDeleteModal(false)
            storage.getOrFetch("assets/classes/list").then((resp) => {
                setList(resp)
                toastSuccess('Asset class deleted')
                changeRoute(`${ROUTE_PATHS[PAGES.ADMIN]}/asset-class`)
            })
        })
    }

    function onDrop(files, callbackFunction) {
        let file = files[0];
        if (file.size > 5 * 1024 * 1024) {
            toastDanger("File too large - please upload a 200x200 .png file");
            return;
        }
        if (file.type !== "image/png" && file.type !== "image/jpeg" && file.type !== "image/webp") {
            toastDanger("Invalid file type - please upload a .png, .jpeg, or .webp file");
            return;
        }
        let xhr = new XMLHttpRequest();
        xhr.open('post', `/api/crm/images/asset/${editedAsset.id}`, true);
        xhr.addEventListener('load', e => {
            if (xhr.status >= 200 && xhr.status < 300) onUploadComplete(e, callbackFunction);
            else console.log(xhr);
        });
        xhr.upload.addEventListener('progress', e => {
            if (e.lengthComputable) {
                let complete = (e.loaded / e.total * 100 | 0);
                if (complete === 100) {
                    console.log("Processing ...");
                } else {
                    console.log("Finished");
                }
            }
        });
        xhr.setRequestHeader("Content-Type", file.type);
        xhr.setRequestHeader("Content-Disposition", "attachment; filename=upload.png");
        xhr.withCredentials = true;
        xhr.send(file);
    }

    function onUploadComplete(e, callbackFunction) {
        const resp = JSON.parse(e.target.response);
        if (!resp.success) {
            toastDanger(resp.message)
        } else {
            callbackFunction && callbackFunction()
            toastSuccess("Successfully uploaded")
        }
    }

    function toggleSort(column) {
        if (column === null) {
            setSortingColumn({ column: "Name", ascending: true })
            return
        }
        const ascending = sortingColumn.column === column ? !sortingColumn.ascending : true
        setSortingColumn({ column: column, ascending: ascending })
    }

    function sortTable(dataToSort) {
        if (sortingColumn.column === null) {
            return dataToSort
        }

        const tableData = dataToSort
        const ascending = sortingColumn.ascending
        switch (sortingColumn.column) {
            case "Name":
                tableData.sort((a, b) => ascending ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name))
                break;
            case "Code":
                tableData.sort((a, b) => {
                    const asset1 = a.code || '-'
                    const asset2 = b.code || '-'
                    return ascending ? asset1.localeCompare(asset2) : asset2.localeCompare(asset1)
                })
                break;
            default:
                break;
        }
        return tableData
    }

    function filter(dataToFilter) {

        if (searchTerm === null || searchTerm.trim().size === 0) {
            return dataToFilter
        }

        return dataToFilter.filter((datum) => filterBy(datum, ["name", "code"], searchTerm))
    }

    function dataMap(dataToMap) {
        return dataToMap && dataToMap.length > 0 && dataToMap.map((asset, idx) => {
            const { name, code, id, logoUploaded } = asset
            const foundTicker = asset.code && tickers[code]

            return (
                <div className='div-row' key={asset.name + '-' + asset.code} style={{ transform: 'translateY(2rem)' }}>
                    <div className='div-td' style={{ width: '600px' }}>
                        <FlexWrapper align="center"  >
                            {logoUploaded ?
                                <img src={`/api/public/images/asset/${id}.png`} style={{ width: 40 }} alt="logo" /> : foundTicker && foundTicker.logo ?
                                    <img src={foundTicker.logo} style={{ width: 40 }} alt="logo" /> :
                                    <div className='logo-replacement'>{name.charAt()}</div>
                            }
                            <span style={{ margin: '0 10px 0 8px' }}/*className='model-portfolio-ticker-detail-name'*/>{name}</span>
                        </FlexWrapper>
                    </div>
                    <div className='div-td' style={{ width: '250px' }}>
                        <span>{foundTicker?.ric || code || '-'}</span>
                    </div>
                    <div className='div-td' style={{ width: 'calc(100% - 850px', textAlign: 'end' }}>
                        <Tooltip placement="bottom-start" padding='none' label={
                            <AssetActionsPopup
                                move
                                edit
                                remove
                                classes={list.filter(c => c.id !== asset.assetClassId)}
                                asset={asset}
                                asetClassId={asetClassId}
                                setImageUpload={setImageUpload}
                                setShowImageEditModal={setShowImageEditModal}
                                setEditedAsset={setEditedAsset}
                                allAssets={allAssets}
                                callbackFunction={refreshClassData}
                            />
                        } ><MoreHorizontal className='assets-actions-icon' />
                        </Tooltip>
                    </div>
                </div>
            )
        })
    }

    const filteredData = filter(data)
    const sortedData = sortTable(filteredData)

    return (editMode ?
        <AddAssetClass
            classesList={list}
            setAddMode={setEditMode}
            assetClassName={assetClassName}
            assetsList={data}
            classId={asetClassId}
            setData={setData}
            setAssetClassName={setAssetClassName}
            imageUpload={imageUpload}
            setStagedImage={setStagedImage}
            setImageUpload={setImageUpload}
            showImageEditModal={showImageEditModal}
            setShowImageEditModal={setShowImageEditModal}
            setEditedAsset={setEditedAsset}
            setOnSaveListener={setOnSaveListener}
            setOnCancelListener={setOnCancelListener}
            onDrop={onDrop}
            refreshClassData={refreshClassData}
        /> :
        <>
            <Page fixedBanner>
                <PageContentBanner divider gap='m' align='baseline'>
                    <ActionButtonsBanner
                        handleBackOnClick={() => changeRoute(`${ROUTE_PATHS[PAGES.ADMIN]}/asset-class`)}
                        cancel={{ onClick: () => setEditMode(false) }}
                        editMode={editMode}
                        edit={{ onClick: () => setEditMode(true), noDivider: true }}
                        remove={{ onClick: () => setDeleteModal(true) }}
                        hideFirstDivider
                    />
                </PageContentBanner>
                <PageContentBodyGrid id="model-portfolio-page" rowGap="l" paddingTop='0rem'>
                    <PageGridItem container col="1 / span 12" direction='row' gap='m' align='center' className='pt-l pb-m' alignItems='center' style={{ position: 'fixed', zIndex: 2, width: 'calc(100% - 13.5rem)', backgroundColor: '#fff' }}>
                        <DropDown value={assetClassName} classNames='header-dropdown' clickable minMenuWidth={250} fixed>
                            {list.map(o => <li key={o.name + "-" + o.id} onClick={() => changeRoute(`${ROUTE_PATHS[PAGES.ADMIN]}/asset-class/${o.id}`)}>{o.name}</li>)}
                        </DropDown>
                        {sortedData.length > 0 && <Divider vertical height='32px' />}
                        {sortedData.length > 0 &&
                            <SimpleSearchFilter
                                size="small"
                                width='l'
                                placeholder={`Filter list`}
                                isClearable
                                searchTerm={searchTerm}
                                setSearchTerm={setSearchTerm}
                            />
                        }
                    </PageGridItem>
                    <PageGridItem container col="1 / span 12" className='mb-xl' style={{ marginBottom: "2rem", paddingTop: '5rem' }}>
                        {loading ?
                            <LoadingIcon size={48} centered /> : sortedData.length === 0 ?
                                <NoResultsMessage message="No results" subMessage="Looks like there are no results for this page" /> :
                                <>
                                    <div className='div-row' style={{ position: 'fixed', zIndex: 1, width: '100%', backgroundColor: '#fff' }}>
                                        <div className='div-th' style={{ width: '600px' }}>
                                            <div className='sorting-header' onClick={() => toggleSort("Name")}>
                                                Name
                                                {sortingColumn && sortingColumn.column === "Name" && sortingColumn.ascending === true ?
                                                    <ChevronUp className="colour-text-primary" size={12} /> :
                                                    <ChevronDown className={sortingColumn && sortingColumn.column === "Name" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                                                }
                                            </div>
                                        </div>
                                        <div className='div-th' style={{ width: '250px' }}>
                                            <div className='sorting-header' onClick={() => toggleSort("Code")}>
                                                Code
                                                {sortingColumn && sortingColumn.column === "Code" && sortingColumn.ascending === true ?
                                                    <ChevronUp className="colour-text-primary" size={12} /> :
                                                    <ChevronDown className={sortingColumn && sortingColumn.column === "Code" ? "colour-text-primary" : "colour-text-neutral-dark-30"} size={12} />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className='pt-m'>{dataMap(sortedData)}</div>
                                </>
                        }
                    </PageGridItem>
                </PageContentBodyGrid>
                {showImageEditModal && (
                    <ImageEditModal
                        handleClose={() => setShowImageEditModal(null)}
                        cancelButton={{ onClick: () => { setShowImageEditModal(null) } }}
                        initialImg={imageUpload}
                        clearInitialImg={() => setImageUpload(null)}
                        onConfirm={(image) => {
                            onDrop(image, refreshClassData)
                            setShowImageEditModal(null)
                        }}
                    />
                )}
            </Page >
            {deleteModal &&
                <Modal
                    header="Are you sure?"
                    cancelButton={{ onClick: () => setDeleteModal(false), label: 'No, Go Back' }}
                    confirmButton={{ onClick: () => deleteAssetClass(), label: 'Yes, Delete' }}
                    handleClose={() => setDeleteModal(false)}>
                    <p>{`The asset class ${assetClassName} will be deleted permanently`}</p>
                </Modal>
            }
        </>
    );
}

export default withRouteChange(AssetClassPage);
import React, { useEffect, useState } from 'react';
import { Clock, Eye } from 'react-feather';
import Table from '../../../components/Table/Table';
import IconCounter from '../../../components/IconCounter';
import TickerSectorItem from '../../../components/TickerSectorItem';
import { useRemote, useStorage } from '../../../Utils/Utils';
import { format, sub } from 'date-fns';
import { useHistory } from 'react-router-dom';

const TopTickers = ({dateRange}) => {
	const remote = useRemote();
	const storage = useStorage();
	const history = useHistory()

	const [tickerData, setTickerData] = useState([])
	const [allTickers, setAllTickers] = useState([])
	const [allGroups, setAllGroups] = useState([])

	const end = format(new Date(), 'yyyy-MM-dd')

	useEffect(() => {
		storage.getOrFetch(`/crm/tickers`).then(setAllTickers).catch(() => setAllTickers([]))
		storage.getOrFetch(`/crm/groupings/${1}`).then((fetchedGrouping) => setAllGroups(fetchedGrouping.groups)).catch(() => setAllGroups([]))
	}, []);

	useEffect(() => {
		let time = {months: 6}
		
		if (dateRange) {
			time = dateRange
		}

		let date = typeof dateRange === "string" ? dateRange : format(sub(new Date(), time), "yyyy-MM-dd")

		remote.get(`/analytics/aggregates/documents?from=${date}&to=${end}`).then((stats) => {
			const tickerAggregate = {};
	
			stats.forEach(r => {
				const tickerStats = tickerAggregate[r.ticker] || { ticker: r.ticker, count: 0, minutes: 0, uniqueViews: 0 };
				tickerStats.uniqueViews += r.uniqueViews
				tickerStats.noteId = r.documentId;
				tickerAggregate[r.ticker] = tickerStats;
			})
			const tickerAsArray = Object.keys(tickerAggregate).map(key => tickerAggregate[key])

			const topFiveDescendingSort = tickerAsArray.sort((a, b) => b.uniqueViews - a.uniqueViews).slice(0,5)
	
			setTickerData(topFiveDescendingSort);
		});
	}, [dateRange]);

	return <TickerTable data={tickerData} changeRoute={(url) => history.push(url)} allTickers={allTickers} allGroups={allGroups} />

}

const TickerTable = ({ data, changeRoute, allTickers, allGroups }) => {
	const colWidths = ["90%", "150px"];

	function handleOnClick(research) {
		if (allTickers && allTickers.length > 0 && research.ticker.includes(".")) {
			const ticker = allTickers.find(t => t.ric && t.ric === research.ticker)
			return ticker ? changeRoute(`/analytics/tickers/${ticker.id}`) : null
		} else if (allGroups && allGroups.length > 0 && !research.ticker.includes(".")) {
			const group = allGroups.find(g => g.groupName === research.ticker)
			return group ? changeRoute(`/analytics/group/${group.id}`) : null

		} else return null
	}

	function getImage(research) {
		if (allTickers && allTickers.length > 0 && research.ticker.includes(".")) {
			const ticker = allTickers.find(t => t.ric && t.ric === research.ticker)
			return ticker ? `/api/public/images/ticker/${ticker.id}.png` : null
		} else if (allGroups && allGroups.length > 0 && !research.ticker.includes(".")) {
			const group = allGroups.find(g => g.groupName === research.ticker)
			return group ? `/api/public/images/group/${group.id}.png` : null
		} else return null
	}

	const dataMap = () => data && data.map((research, idx) => {
		return (
			<tr key={idx} className="cursor-pointer" onClick={() => handleOnClick(research)}>
				<td>
					<TickerSectorItem size="m" label={research.ticker} image={getImage(research)} />
				</td>
				<td className="table-action-icons transition-fade-in-slow">
					<div className='top-research-icons top-tickers-viewed-icons'>
						<IconCounter icon={<Eye />} tooltip="Views" dataAvailable count={research.uniqueViews} />
					</div>
				</td>
			</tr>
		);
	});

	return <Table suggestionMessage rowSize="s" colWidths={colWidths} dataMap={dataMap()} />

}

export default TopTickers;
import React, { useEffect, useState, useRef } from "react";
import Modal from "./Modal";
import FlexWrapper from "../../layouts/FlexWrapper";
import Divider from "../Divider";
import PDFViewer from "../PDFViewer";
import ErrorBoundary from "../ErrorBoundary";
import CommentsSection from "../Comments/CommentsSection";
import IconLink from "../Menus/IconLink";
import { MessageSquare, ChevronLeft, ChevronRight, ChevronsUp, ChevronsDown, ChevronUp, ChevronDown, UserX, UserCheck, Info } from 'react-feather';
import "./DraftNotePreviewModal.scss";
import { handleComplianceRead, handlePeerRead, handleSupervisorRead, showConfirmActionModal, useStorage } from "../../Utils/Utils";
import ConfirmActionModal from "./ConfirmActionModal";
import Tooltip from "../popups/Tooltip";
import { format, parseISO } from "date-fns";


const DraftNotePreviewModal = ({ handleClose, data, doc, currentIndex, allRequests, setRowActivePreview, compliance, autoOpenComments, refreshList, singleDocViewer, noActions }) => {

	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(null);
	const [twoPageView, setTwoPageView] = useState(false);
	const [commentsOpen, setCommentsopen] = useState(false);
	const [comments, setComments] = useState([])
	const [blankPageMesurements, setBlankPageMesurements] = useState({ width: 0, height: 0 })
	const [confirmActionModal, setConfirmActionModal] = useState(null);
	const [me, setMe] = useState({});
	const previewContentRef = useRef()
	const storage = useStorage()

	const perms = me?.perms || [];
	const currentUserHasRejected = me && me.email && data && data.reviewResponderActions && data.reviewResponderActions.find(response => response.status === "Rejected" && response.responderEmail === me.email)
	const currentUserHasApproved = me && me.email && data && data.reviewResponderActions && data.reviewResponderActions.find(response => response.status === "Approved" && response.responderEmail === me.email)
	const rejectionAction = data && data.reviewResponderActions && data.reviewResponderActions.find(response => response.status === "Rejected")

	useEffect(() => {
		sessionStorage.getItem("3SKYE.draft.modal.2PageView") === "true" ? setTwoPageView(true) : setTwoPageView(false)
		document.addEventListener("keydown", handleKeyDown);
		storage.getOrFetch(`/crm/me`).then((res) => {
			setMe(res)

			if (res.perms) {
				if (res.perms.includes("REVIEW_PEER")) {
					handlePeerRead(data.template, data.ticker, data.requestId)
				} else if (res.perms.includes("REVIEW_COMPLIANCE")) {
					handleComplianceRead(data.template, data.ticker, data.requestId)
				} else if (res.perms.includes("REVIEW_SUPERVISOR")) {
					handleSupervisorRead(data.template, data.ticker, data.requestId)
				}
			}
		})

		return () => {
			document.removeEventListener("keydown", handleKeyDown);
		};
	}, []);

	useEffect(() => {
		if (autoOpenComments) setCommentsopen(true)
	}, [autoOpenComments])

	useEffect(() => {
		// fetch comments
		if (!data.ticker) return;
		if (!data.template) return;

		const identifier = btoa(data.ticker);
		let url = `/comments/${identifier}/${data.template}`

		const params = new URLSearchParams();
		if (data.requestId) params.append("requestId", data.requestId);
		if (data.workflowStatusId) params.append("workflowStatusId", data.workflowStatusId);

		if (params.toString()) {
			url += `?${params.toString()}`;
		}
		storage.getOrFetch(url).then((val) => {
			const fetchedComments = []

			val.forEach(comment => {
				if (!comment.replyTo) {
					fetchedComments.push(comment)
				} else {
					const parentComment = fetchedComments.find(c => c.commentId === comment.replyTo)
					if (!parentComment.replies) parentComment.replies = [comment]
					else parentComment.replies.push(comment)
				}
			})
			setComments(fetchedComments)
		})
	}, [])

	const handleSetTwoPageView = (value) => {
		setTwoPageView(value);
		sessionStorage.setItem("3SKYE.draft.modal.2PageView", value);
	};

	const handleKeyDown = (e) => {
		if (e.key === "Escape") {
			handleClose();
		}
	};

	const handleOverlayClick = (e) => {
		// Check if the clicked element or any of its ancestors have the classes 'floating-sidebar' or 'preview-wrapper'
		if (!hasAncestorWithClass(e.target, "floating-sidebar") && !hasAncestorWithClass(e.target, "preview-wrapper") && !hasAncestorWithClass(e.target, "popup-menu"))  {
			// Trigger the handleClose function if the clicked element or its ancestors don't have the specified classes
			handleClose();
		}
	};

	// Helper function to check if the element or any of its ancestors have a specific class
	const hasAncestorWithClass = (element, className) => {
		let currentElement = element;
		while (currentElement) {
			if (currentElement.classList.contains(className)) {
				return true;
			}
			currentElement = currentElement.parentElement;
		}
		return false;
	};

	const errorFallback = (
		<div style={{
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			paddingTop: "4rem"
		}}
		>
			<div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
				<p>An error occurred while loading this note. </p>
				<a
					href="mailto:kathryn.warusevitane@craigsip.com?subject=Investor Portal - Trouble Logging In"
					target="_blank"
					className="text-link colour-text-primary"
				>
					Contact Us
				</a>
			</div>
		</div>
	)

	const gotToPreviousNote = () => {
		if (currentIndex === 0) setRowActivePreview(allRequests.length - 1)
		else setRowActivePreview(currentIndex - 1)
	}

	const gotToNextNote = () => {
		if (currentIndex === allRequests.length - 1) {
			setRowActivePreview(0)

		} else {
			setRowActivePreview(currentIndex + 1)
		}
	}

	function calculatePage(page, up, doble, extreme) {
		if (extreme) {
			if (doble) {
				if (up) return totalPages % 2 === 0 ? totalPages - 1 : totalPages
				else return 1
			} else {
				if (up) return totalPages
				else return 1
			}
		} else {
			if (doble) {
				if (up) return totalPages % 2 === 0 && page === totalPages - 1 ? totalPages - 1 : totalPages % 2 !== 0 && page === totalPages ? totalPages : page + 2
				else return page === 1 ? 1 : page - 2
			} else {
				if (up) return page === totalPages ? totalPages : page + 1
				else return page === 1 ? 1 : page - 1
			}
		}
	}

	function getIsDisabled(button) {
		if (!perms.includes("REVIEW_COMPLIANCE") && !perms.includes("REVIEW_PEER") && !perms.includes("REVIEW_SUPERVISOR")) {
			return true
		} else {
			const hasRejection = doc?.reviewResponderActions && doc.reviewResponderActions.find(a => a.status === 'Rejected')
			if (hasRejection && me.name !== hasRejection.responderName) return true
			else if (button === 'reject' && hasRejection) return true
			else return false
		}
	}

	function calculatePage(page, up, doble, extreme) {
		if (extreme) {
			if (doble) {
				if (up) return totalPages % 2 === 0 ? totalPages - 1 : totalPages
				else return 1
			} else {
				if (up) return totalPages
				else return 1
			}
		} else {
			if (doble) {
				if (up) return totalPages % 2 === 0 && page === totalPages - 1 ? totalPages - 1 : totalPages % 2 !== 0 && page === totalPages ? totalPages : page + 2
				else return page === 1 ? 1 : page - 2
			} else {
				if (up) return page === totalPages ? totalPages : page + 1
				else return page === 1 ? 1 : page - 1
			}
		}
	}

	return (
		<>
			{confirmActionModal && <ConfirmActionModal {...confirmActionModal} />}
			<Modal handleClose={handleClose} className="draft-note-preview-modal" overlayClick={handleOverlayClick}>
				<div className={`preview-wrapper ${commentsOpen ? "right-sidebar-open" : ""}`}>
					{/* Header */}
					<FlexWrapper gap="s" align="center" direction='row' justify={singleDocViewer ? "center" : 'space-between'} className="mt-m pl-xl pr-xl preview-note-modal-header">
						{!singleDocViewer &&
							<FlexWrapper gap="s" align="center" direction='row' justify='center' className="mt-m mb-m " onClick={gotToPreviousNote} style={{ cursor: "pointer", marginLeft: `calc(130px - 2rem` }}>
								<ChevronLeft color="#0D8EFD" />
								<p className="">Previous Note</p>
							</FlexWrapper>
						}
						<FlexWrapper gap="s" align="center" direction='column' justify='center' className="mt-m mb-m">
							<h4>{data?.type ? data.type : data?.templateName ? data.templateName : "" }</h4>
							<h3 style={{ display: 'flex', alignItems: "center" }}>
								{data?.title ? data.title : data?.name ? data.name : data?.ticker ? data.ticker : ""}
								{rejectionAction &&
									<Tooltip label={`Rejected by: ${rejectionAction.responderName}, ${format(parseISO(rejectionAction.grantedAt), "hh:mmaaa, dd/MM/yyyy")}`} outerStyle={{ marginLeft: "0.5rem" }} >
										<Info color="#E66780" size={20} />
									</Tooltip>
								}
							</h3>
						</FlexWrapper>
						{!singleDocViewer &&
							<FlexWrapper gap="s" align="center" direction='row' justify='center' className="mt-m mb-m" onClick={gotToNextNote} style={{ cursor: "pointer", marginRight: commentsOpen ? `calc(474px - 1rem` : `calc(130px - 2rem` }}>
								<p>Next Note</p>
								<ChevronRight color="#0D8EFD" />
							</FlexWrapper>
						}
					</FlexWrapper>
					<Divider spacing="bottom-l" />
					<FlexWrapper gap="s" align="flex-start" direction="row">
						{/* Left Sidebar */}
						<FlexWrapper className="floating-sidebar navigation-menu" direction="column" align="center" gap="s" style={{ minWidth: "100px" }}>
							<p>Page</p>
							<IconLink
								icon={<ChevronsUp color="#0D8EFD" />}
								onClick={() => setPage(calculatePage(page, false, twoPageView, true))}
								active={false}
								label="First Page"
							/>
							<IconLink
								icon={<ChevronUp color="#0D8EFD" />}
								onClick={() => setPage(calculatePage(page, false, twoPageView, false))}
								active={false}
								label="Previous Page"
							/>
							<div className="page-guide">
								<p>{twoPageView ? page === totalPages ? page : `${page} - ${page + 1}` : `${page} / ${totalPages || "1"}`}</p>
							</div>
							<IconLink
								icon={<ChevronDown color="#0D8EFD" />}
								onClick={() => setPage(calculatePage(page, true, twoPageView, false))}
								active={false}
								label="Next Page"
							/>
							<IconLink
								icon={<ChevronsDown color="#0D8EFD" />}
								onClick={() => setPage(calculatePage(page, true, twoPageView, true))}
								active={false}
								label="Last Page"
							/>
							<Divider width="60px" />
							<IconLink
								icon={
									<span className="material-symbols-outlined">
										description
									</span>
								}
								onClick={() => handleSetTwoPageView(false)}
								active={!twoPageView}
								label="Single Page View"
							/>
							<IconLink
								icon={
									<span className="material-symbols-outlined">
										two_pager
									</span>
								}
								onClick={() => handleSetTwoPageView(true)}
								active={twoPageView}
								label="Double Page View"
							/>
						</FlexWrapper>
						{/* Preview */}
						<FlexWrapper className={`preview-wrapper-inner ${noActions ? "no-actions" : "" }`} direction="column" align="flex-start" gap="m" fullWidth ref={previewContentRef}>
							<ErrorBoundary fallback={errorFallback}>
								{data && <FlexWrapper direction="row" width="100%">
									<PDFViewer
										enableKeyboardNavigation
										ticker={data?.ticker}
										template={data?.template ? data.template : data?.templateName ? data?.templateName : data?.type}
										id={data?.docId}
										scale={2}
										fullHeight
										fullWidth
										setTotalPages={setTotalPages}
										pageOverride={page}
										blankPageMesurements={blankPageMesurements}
										setBlankPageMesurements={setBlankPageMesurements}
									/>
									{twoPageView &&
										<PDFViewer
											enableKeyboardNavigation
											ticker={data?.ticker}
											template={data?.template ? data.template : data?.templateName ? data?.templateName : data?.type}
											id={data?.docId}
											scale={1}
											fullHeight
											fullWidth
											setTotalPages={setTotalPages}
											pageOverride={page + 1}
											blankPage={page + 1 > totalPages}
											blankPageMesurements={blankPageMesurements}
											setBlankPageMesurements={setBlankPageMesurements}
										/>
									}
								</FlexWrapper>
									|| null}
							</ErrorBoundary>
						</FlexWrapper>
						{/* Right Sidebar */}
						{!noActions &&
							<FlexWrapper className="floating-sidebar navigation-menu smaller" direction="column" align="center" gap="m" style={{ minWidth: "100px", paddingRight: "0.5rem" }}>
								<IconLink
									icon={<MessageSquare />}
									onClick={() => setCommentsopen(!commentsOpen)}
									active={commentsOpen}
									variant={comments.length > 0 ? 'active' : ''}
									diabled={!perms.includes("COMMENTS_COMPLIANCE") && !perms.includes("COMMENTS_PEER") && !perms.includes("COMMENTS_SUPERVISOR")}
									// label="Comments"
									// counter={2}   // TODO: replace with real new messages counter
									counterClassName="workplace-template-preview-icon-counter"
									className="workplace-template-preview-icon"
								/>
								<Divider spacing="none" width="60px" />
								<IconLink
									icon={<UserCheck />}
									label={perms.includes("REVIEW_PEER") ? "Confirm Peer Review Approval" : perms.includes("REVIEW_COMPLIANCE") ? "Confirm Compliance Approval" : perms.includes("REVIEW_SUPERVISOR") ? "Confirm Supervisor Approval" : null}
									variant={currentUserHasApproved ? "positive" : ""}
									disabled={getIsDisabled('approve')}
									className="workplace-template-preview-icon"
									onClick={() => {
										const disabled = getIsDisabled('approve')
										if (disabled) return null
										else {
											let permittedAction = perms.includes("REVIEW_PEER") ? "approvePeerReview" : perms.includes("REVIEW_COMPLIANCE") ? "approveCompliance" : perms.includes("REVIEW_SUPERVISOR") ? "approveSupervisor" : null
											permittedAction && showConfirmActionModal(permittedAction, setConfirmActionModal, data?.template, data?.ticker, data?.requestId, () => refreshList(true))
										}
									}}
								/>
								<IconLink
									icon={<UserX />}
									label={perms.includes("REVIEW_PEER") ? "Deny Peer Review Approval" : perms.includes("REVIEW_COMPLIANCE") ? "Deny Compliance Approval" : perms.includes("REVIEW_SUPERVISOR") ? "Deny Supervisor Approval" : null}
									variant={currentUserHasRejected ? "danger" : ""}
									disabled={getIsDisabled('reject')}
									className="workplace-template-preview-icon"
									onClick={() => {
										const disabled = getIsDisabled('reject')
										if (disabled) return null
										else {
											let permittedAction = perms.includes("REVIEW_PEER") ? "rejectPeerReview" : perms.includes("REVIEW_COMPLIANCE") ? "rejectCompliance" : perms.includes("REVIEW_SUPERVISOR") ? "rejectSupervisor" : null
											permittedAction && showConfirmActionModal(permittedAction, setConfirmActionModal, data?.template, data?.ticker, data?.requestId, refreshList)
										}
									}}
								/>
							</FlexWrapper>
						}
						{/* Comments */}
						{commentsOpen && <Divider vertical height={previewContentRef && previewContentRef.current ? `calc(${previewContentRef.current.clientHeight}px + 1.5rem` : "100vh"} marginTop="-1.5rem" />}
						{commentsOpen &&
							<FlexWrapper className="floating-sidebar" direction="column" align="flex-start" gap="m" style={{ minWidth: "344px" }}>
								<CommentsSection ticker={data && data.ticker} templateName={data && data.template} compliance={compliance} workflowStatusId={data && data.workflowStatusId} requestId={data && data.requestId} />
							</FlexWrapper>
						}
					</FlexWrapper>
				</div>
			</Modal>
		</>
	);
};

export default DraftNotePreviewModal;

import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { FileText, Users, Trash2, Clipboard, Plus } from 'react-feather';
import { useRemote } from '../../../Utils/Utils';
import { PAGES, ROUTE_PATHS, BannerContext } from '../../../InternalApiApp';
import Page, { PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridItem from '../../Shared/Page/PageGridItem';
import PageGridDivider from '../../Shared/Page/PageGridDivider';
import DetailField from '../../Shared/DetailSection/DetailField';
import DetailSectionHeader from '../../Shared/DetailSection/DetailSectionHeader';
import SearchField from '../../../components/Input/SearchField';
import Table from '../../../components/Table/Table';
import ActionButtonsBanner from '../../Shared/Banner/ActionButtonsBanner';
import DetailTextbox from './DetailTextbox'
import Button from '../../../components/Buttons/Button';
import { formatDate } from '../../../Utils/Utils';
import "./FundPage.scss"


const OfferPage = ({ }) => {
    const remote = useRemote();
    const { offerId } = useParams()
    const history = useHistory()
	const { setBannerBreadCrumbs } = useContext(BannerContext);

    const [originalOffer, setOriginalOffer] = useState(null)
    const [offer, setOffer] = useState(null)
    const [editMode, setEditMode] = useState(false)
    const [options, setOptions] = useState([])
    const [notes, setNotes] = useState([])
    const [autoFocus, setAutoFocus] = useState(false)

    useEffect(() => {
        remote.get(`/funds/offers/${offerId}`).then(fetchOffer => {
            setOriginalOffer(fetchOffer)
            setOffer(fetchOffer)
            setBannerBreadCrumbs({ tab: 'Offers', param1: { name: fetchOffer?.offerName, id: offerId } })
            const optionsList = fetchOffer.funds.map(option => {
                return {
                    label: option.fundName,
                    value: option
                }
            })
            setOptions(optionsList)
            setNotes(fetchOffer.disclosures)
        });
    }, [remote, offerId])

    function updateOffer(newValue, property) {
        // save temporary updates
        const newObj = { ...offer, [property]: newValue }
        setOffer(newObj)
    }

    function saveEdits() {
        remote.post(`/funds/offers/${offerId}`, offer).then(offer => {
            setOriginalOffer(offer)
            setOffer(offer)
            const optionsList = offer.funds.map(option => {
                return {
                    label: option.fundName,
                    value: option
                }
            })
            setOptions(optionsList)
        })
        setEditMode(false)
        setAutoFocus(false)
    }

    function cancelEdits() {
        setOffer(originalOffer)
        setNotes(originalOffer.disclosures)
        setEditMode(false)
        setAutoFocus(false)
    }

    function createNote() {
        const newList = [...notes, { disclosure: '', id: 0 }]
        setAutoFocus(true)
        setNotes(newList)
        setOffer({ ...offer, disclosures: newList })
    }

    function updateNote(value, id, index) {
        const newList = notes.map((note, idx) => {
            if (note.id === id && index === idx) {
                const updatedNote = { ...note, disclosure: value }
                return updatedNote
            } else return note
        })
        setNotes(newList)
        setOffer({ ...offer, disclosures: newList })
    }

    function deleteNote(id) {
        const newList = notes.filter(note => note.id !== id)
        setNotes(newList)
        setOffer({ ...offer, disclosures: newList })
    }

    function deleteFund() { }
    function updateFunds() { }

    const fundsColWidths = ['auto', '10%']
    const fundsDataMap = (data) => data && data.map((fund, idx) => {
        const { fundName, fundNumber } = fund
        return (
            <tr key={idx} className='cursor-pointer' onClick={() => history.push(ROUTE_PATHS[PAGES.ADMIN] + '/fund-management/funds/' + fund.fundNumber)}>
                <td>{fundName + " " + fundNumber}</td>
                <td className='text-align-right'>
                    <Trash2 onClick={(e) => { e.stopPropagation(); deleteFund(fund) }} className='delete-icon' />
                </td>
            </tr>
        );
    })

    const disclosureList = (data) => data?.length > 0 && data.map((note, index) => {
        const { disclosure, id } = note
        return (
            <PageGridItem container direction='row' gap='s' alignItems='center' style={{ paddingBottom: '1rem' }}>
                <DetailTextbox
                    value={disclosure}
                    update={(e) => updateNote(e.target.value, id, index)}
                    editMode={editMode}
                    placeholder={'Add notes'}
                    minHeight={50}
                    resize='vertical'
                    className='box-style'
                    boxStyle
                    fullWidth
                    autoFocus={id === 0 && index === notes.length - 1 && autoFocus}
                />
                {editMode && <Trash2 onClick={() => deleteNote(id)} className="delete-icon" />}
            </PageGridItem>
        )
    })

    return (
        <Page noBanner >
            <PageContentBodyGrid rowGap="none" gridColCount="12" subGrid paddingBottom="2.5rem">
                <PageGridItem gap='l' direction='col' col="1 / span 12" >
                    <ActionButtonsBanner
                        className="pt-l"
                        hideBackButton
                        editMode={editMode}
                        edit={{ onClick: () => setEditMode(true) }}
                        duplicate={{ disabled: true }}
                        remove={{ disabled: true }}
                        cancel={{ onClick: () => { cancelEdits() } }}
                        save={{ onClick: () => { saveEdits() } }}
                    />
                    <PageGridDivider />
                </PageGridItem>
                <PageGridItem container col="1 / span 6" direction='col' gap="xl" style={{ display: 'flex', paddingRight: '1.5rem' }}>
                    <PageContentBodyGrid rowGap="xl" gridColCount="6" subGrid>
                        <PageGridItem container col="1 / span 6" style={{ marginTop: '1.5rem' }}>
                            <DetailSectionHeader header="Offer Details" icon={<FileText />} />
                        </PageGridItem>
                        <PageGridItem col="1 / span 3">
                            <DetailField
                                bold
                                editMode={editMode}
                                onChange={(e) => updateOffer(e.target.value, "offerName")}
                                label="Offer Name"
                                value={offer?.offerName}
                            />
                        </PageGridItem>
                        <PageGridItem col="4 / span 3">
                            <DetailField
                                bold
                                editMode={editMode}
                                onChange={(e) => updateOffer(e.target.value, "offerNumber")}
                                label="Offer Number"
                                value={offer?.offerNumber}
                            />
                        </PageGridItem>
                        <PageGridItem col="1 / span 3">
                            <DetailField
                                bold
                                editMode={editMode}
                                onChange={(e) => updateOffer(e.target.value, "status")}
                                label="Status"
                                value={offer?.status}
                            />
                        </PageGridItem>
                        <PageGridItem col="1 / span 3">
                            <DetailField
                                bold
                                editMode={editMode}
                                onChange={(e) => updateOffer(e.target.value, "offerRegistered")}
                                label="Date Registered"
                                value={offer?.offerRegistered && formatDate(offer?.offerRegistered)}
                            />
                        </PageGridItem>
                    </PageContentBodyGrid>
                    <PageGridDivider />
                    <PageContentBodyGrid rowGap="xl" gridColCount="6" subGrid>
                        <PageGridItem container col="1 / span 6">
                            <div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: '1rem' }}>
                                <DetailSectionHeader header="Funds" icon={<Users />} />
                                <SearchField
                                    options={options}
                                    onChange={(list) => updateFunds(list.value)}
                                    placeholder='Add Funds'
                                    size='small'
                                    width='m'
                                    value=""
                                />
                            </div>
                            <Table
                                // minWidth={300}
                                // tableStyle={{ maxWidth: 675 }}
                                minWidth={350}
                                tableStyle={{ maxWidth: 1675 }}
                                colWidths={fundsColWidths}
                                dataMap={fundsDataMap(offer?.funds)}
                                id='scheme-and-funds-expandable-table'
                            />
                        </PageGridItem>
                    </PageContentBodyGrid>
                </PageGridItem>
                <PageGridItem container col="7 / span 6" gap='xl' style={{ display: 'flex', flexDirection: 'column', marginTop: '1.5rem' }}>
                    <PageContentBodyGrid rowGap="xl" gridColCount="6" subGrid divider='left'>
                        <PageGridItem container col="1 / span 6" direction='row' justifyContent='between'>
                            <DetailSectionHeader header="Disclosure" icon={<Clipboard />} />
                            <Button icon={<Plus />} onClick={createNote} disabled={!editMode}>add disclosure note</Button>
                        </PageGridItem>
                        <PageGridItem container col="1 / span 6">
                            {disclosureList(notes)}
                        </PageGridItem>
                    </PageContentBodyGrid>
                </PageGridItem>
            </PageContentBodyGrid >
        </Page >
    );
}

export default OfferPage;
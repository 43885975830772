import React, { useEffect, useRef, useState } from 'react';
import { PageContentBodyGrid } from '../../Shared/Page/Page';
import { Trash2, ChevronDown, ChevronUp, Search } from 'react-feather';
import { useRemote, useStorage } from '../../../Utils/Utils';
// import { format, formatISO, parseISO } from 'date-fns';
// import RecommendationSlider from './RecommendationSlider';
// import Slider from '../../../contents/Slider';
import PageGridItem from '../../Shared/Page/PageGridItem';
import DetailField from '../../Shared/DetailSection/DetailField'
import Divider from '../../../components/Divider';
import SearchField from '../../../components/Input/SearchField';
import SimpleSearchFilter, { filterBy } from '../../../components/Input/SimpleSearchFilter'
import Table from '../../../components/Table/Table';
import DetailSectionHeader from '../../Shared/DetailSection/DetailSectionHeader';
// import NumberInputWithStepper from '../../../components/Input/NumberInputWithStepper'
import FlexWrapper from '../../FlexWrapper';
import './ModelPortfolioPage.scss'


const ModelAssetClassTable = ({ assetClass, editMode, updateDataState, saving, setCancelling, cancelling, tickers, isLast }) => {
	const [allAssets, setAllAssets] = useState([])
	const [originalAllAssets, setOriginalAllAssets] = useState([])
	const [assetClassName, setAssetClassName] = useState(assetClass.name)
	const [assets, setAssets] = useState(assetClass.assets);
	const [sortingColumn, setSortingColumn] = useState({ column: 'Name', ascending: true })
	const [searchTerm, setSearchTerm] = useState("")
	const [collapsed, setCollapsed] = useState(false)
	const [assetsToAdd, setAssetsToAdd] = useState([])
	const [assetsToDelete, setAssetsToDelete] = useState([])
	// const [assetsToModify, setAssetsToModify] = useState([])

	const storage = useStorage()
	const remote = useRemote()
	const searchSelectRef = useRef()

	useEffect(() => {
		storage.getOrFetch('assets/list')
			.then(response => {
				const assetsCodesArray = assetClass?.assets?.map(a => a.code)
				const filteredAssets = response.filter(a => !assetsCodesArray?.includes(a.code))
				const mappedAssets = filteredAssets.map((asset) => {
					const assetObj = createOption(asset)
					return assetObj
				})
				setAssets(assetClass.assets);
				sortTable('Name', true)
				setAssetClassName(assetClass.name)
				setAllAssets(mappedAssets)
				setOriginalAllAssets(mappedAssets)
			});
	}, [assetClass])

	useEffect(() => {
		if (saving && (assetsToAdd || assetsToDelete /* || assetsToModify*/)) {
			if (assetsToDelete.length > 0) {
				assetsToDelete.map(assetId => remote.delete(`/model-portfolio/model/${assetClass.model}/class/${assetClass.id}/asset/${assetId}`).then(() => {
					setAssetsToDelete(assetsToDelete.filter(id => id !== assetId))
				}));
			}
			if (assetsToAdd.length > 0) {
				assetsToAdd.map(asset => remote.post(`/model-portfolio/model/${assetClass.model}/class/${assetClass.id}/asset/${asset.id}`, { weighting: asset.actualPercentage || 0.00 }).then(() => {
					setAssetsToAdd(assetsToAdd.filter(a => a.id !== asset.id))
				}))
			}
			// weird bug, it duplicates the asset and does not change the percentage
			// if (assetsToModify.length > 0) {
			// 	assetsToModify.map(asset => remote.put(`/model-portfolio/model/${assetClass.model}/class/${assetClass.id}/asset/${asset.id}`, { weighting: +asset.actualPercentage / 100 }).then(() => {
			// 		setAssetsToModify(assetsToModify.filter(a => a.id !== asset.id))
			// 	}))
			// }
		}
		if (cancelling) {
			setAssets(assetClass.assets)
			setAllAssets(originalAllAssets)
			setAssetsToDelete([])
			setAssetsToAdd([])
			// setAssetsToModify([])
			setCancelling(false)
		}
	}, [saving, cancelling])


	function createOption(asset) {
		const foundTicker = (asset.code && tickers[asset.code]) || null
		const assetObj = {
			value: asset,
			label: <FlexWrapper align='center'>
				{foundTicker && foundTicker.logo ? <img src={foundTicker.logo} style={{ width: 40 }} alt='logo' /> : <div className='logo-replacement'>{asset.name.charAt()}</div>}
				<span style={{ margin: '0 10px 0 8px' }}>{asset?.name}</span>
				<span className='model-portfolio-ticker-detail-name'>{foundTicker?.ric || asset?.code || ""}</span>
			</FlexWrapper>,
		}
		return assetObj
	}

	function addAsset(asset) {
		if (assetsToAdd && assetsToAdd.length > 0) setAssetsToAdd([...assetsToAdd, asset.value])
		else setAssetsToAdd([asset])
		const updatedData = [...assets, asset]
		const newAllAssetsList = allAssets.filter(a => a.value.id !== asset.id)
		setAssets(updatedData.sort((a, b) => a.name.localeCompare(b.name)))
		setAllAssets(newAllAssetsList)
	}

	// function updateAssetsState(val, asset) {
	// 	if (assetsToModify && assetsToModify.length > 0) {
	// 		const exists = assetsToModify.find(a => a.id === asset.id)
	// 		if (exists) {
	// 			const modificationsArray = assetsToModify.length > 0 && assetsToModify.map(a => {
	// 				if (a.id === asset.id) {
	// 					const updatedAsset = { ...a, actualPercentage: val }
	// 					return updatedAsset
	// 				} else return a
	// 			})
	// 			setAssetsToModify(modificationsArray)
	// 		} else setAssetsToModify([...assetsToModify, { asset, actualPercentage: val }])
	// 	} else setAssetsToModify([{ ...asset, actualPercentage: val }])

	// 	const updatedAssets = assets.length > 0 && assets.map(a => {
	// 		if (a.id === asset.id) {
	// 			const updatedAsset = { ...asset, actualPercentage: val }
	// 			return updatedAsset
	// 		} else return a
	// 	})
	// 	setAssets(updatedAssets)
	// }

	function deleteAsset(asset) {
		if (assetsToDelete.length > 0) setAssetsToDelete([...assetsToDelete, asset.id])
		else setAssetsToDelete([asset.id])
		const assetObj = createOption(asset)
		const newAllAssetsList = [...allAssets, assetObj]
		const updatedData = assets.filter(a => a.id !== asset.id)
		setAssets(updatedData)
		setAllAssets(newAllAssetsList.sort((a, b) => a.value.name.localeCompare(b.value.name)))
	}

	function sortTable(column, direction) {
		let tableData = assets?.length > 0 ? assets : assetClass?.assets?.tickers
		if (!tableData) return
		const ascending = direction === null && sortingColumn.column === column ? !sortingColumn.ascending : direction
		switch (column) {
			case 'Name':
				tableData.sort((a, b) => ascending ? a?.name.localeCompare(b?.name) : b?.name.localeCompare(a?.name))
				break;
			case 'Code':
				tableData.sort((a, b) => {
					const asset1 = a.code || '-'
					const asset2 = b.code || '-'
					return ascending ? asset1.localeCompare(asset2) : asset2.localeCompare(asset1)
				})
				break;
			case 'Contry':
				tableData.sort((a, b) => ascending ? a.country.localeCompare(b.country) : b.country.localeCompare(a.country))
				break;
			case 'Weight':
				tableData.sort((a, b) => {
					const asset1 = a.weighting || 0
					const asset2 = b.weighting || 0
					return ascending ? asset1 - asset2 : asset2 - asset1
				})
				break;
			case 'Target Weight':
				tableData.sort((a, b) => {
					const asset1 = a.targetWeighting || 0
					const asset2 = b.targetWeighting || 0
					return ascending ? asset1 - asset2 : asset2 - asset1
				})
				break;
			default:
				break;
		}
		setSortingColumn({ column: column, ascending: ascending })
		setAssets(tableData)
	}

	function filterOption(option, inputValue) {
		const { value } = option;
		const searchTerm = inputValue.toLowerCase();
		// Customize these fields based on what you want to search
		return (
			value?.name?.toLowerCase().includes(searchTerm) ||
			value?.code?.toLowerCase().includes(searchTerm) ||
			(value.securityCode || '').toLowerCase().includes(searchTerm)
		);
	};

	function filter(dataToFilter, searchTerm) {
		if (searchTerm === null || searchTerm.trim().size === 0) {
			return dataToFilter
		}
		return dataToFilter.filter((datum) => filterBy(datum, ['name', 'code'], searchTerm))
	}

	const colWidths = ['33%', '12%', '5%', '8%', '12%', '7%', '12%']
	const headers = [
		{
			text: (
				<div className='sorting-header' onClick={() => sortTable('Name', null)} >
					Name
					{sortingColumn && sortingColumn.column === 'Name' && sortingColumn.ascending === true ?
						<ChevronUp className='colour-text-primary' size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === 'Name' ? 'colour-text-primary' : 'colour-text-neutral-dark-30'} size={12} />
					}
				</div>
			),
			headerKey: 'name'
		},
		{
			text: (
				<div className='sorting-header' onClick={() => sortTable('Code', null)} >
					Code
					{sortingColumn && sortingColumn.column === 'Code' && sortingColumn.ascending === true ?
						<ChevronUp className='colour-text-primary' size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === 'Code' ? 'colour-text-primary' : 'colour-text-neutral-dark-30'} size={12} />
					}
				</div>
			),
			headerKey: 'code'
		},
		'',
		{
			headerKey: 'currency',
			text: (
				<div className='sorting-header' onClick={() => sortTable('Currency', null)} >
					Currency
					{sortingColumn && sortingColumn.column === 'Currency' && sortingColumn.ascending === true ?
						<ChevronUp className='colour-text-primary' size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === 'Currency' ? 'colour-text-primary' : 'colour-text-neutral-dark-30'} size={12} />
					}
				</div>
			),
		},
		{
			text: (
				<div className='sorting-header' onClick={() => sortTable('Country', null)} >
					Country
					{sortingColumn && sortingColumn.column === 'Country' && sortingColumn.ascending === true ?
						<ChevronUp className='colour-text-primary' size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === 'Country' ? 'colour-text-primary' : 'colour-text-neutral-dark-30'} size={12} />
					}
				</div>
			),
			headerKey: 'country'
		},
		{
			text: (
				<div className='sorting-header' onClick={() => sortTable('Exch', null)} >
					Exch
					{sortingColumn && sortingColumn.column === 'Exch' && sortingColumn.ascending === true ?
						<ChevronUp className='colour-text-primary' size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === 'Exch' ? 'colour-text-primary' : 'colour-text-neutral-dark-30'} size={12} />
					}
				</div>
			),
			headerKey: 'exchange'
		},
		{
			text: (
				<div className='sorting-header justify-content-end' onClick={() => sortTable('Weight', null)} >
					Portfolio Weight
					{sortingColumn && sortingColumn.column === 'Portfolio Weight' && sortingColumn.ascending === true ?
						<ChevronUp className='colour-text-primary' size={12} /> :
						<ChevronDown className={sortingColumn && sortingColumn.column === 'Portfolio Weight' ? 'colour-text-primary' : 'colour-text-neutral-dark-30'} size={12} />
					}
				</div>
			),
			headerKey: 'portfolio-weight',
		},
		// {
		// 	text: (
		// 		<div className='sorting-header  justify-content-end' onClick={() => sortTable('Target Weight', null)} >
		// 			Target Weight
		// 			{sortingColumn && sortingColumn.column === 'Target Weight' && sortingColumn.ascending === true ?
		// 				<ChevronUp className='colour-text-primary' size={12} /> :
		// 				<ChevronDown className={sortingColumn && sortingColumn.column === 'Target Weight' ? 'colour-text-primary' : 'colour-text-neutral-dark-30'} size={12} />
		// 			}
		// 		</div>
		// 	),
		// 	headerKey: 'target-weight',
		// 	// className: 'justify-content-end'
		// }
	]

	const dataMap = (data) => data.map((asset, index) => {
		const actualPercentage = new DecimalFormat('##.##').format(asset.actualPercentage * 100);  //eslint-disable-line no-undef
		const foundTicker = asset.code && tickers[asset.code] || null
		return (
			<tr className={`${editMode ? ' model-tr-edit-mode' : ''}`} key={asset.assetClassId + '-' + asset.code + '-' + index}>
				<td >
					<FlexWrapper align='center'  >
						{foundTicker && foundTicker.logo ? <img src={foundTicker.logo} style={{ width: 40 }} alt='logo' /> : <div className='logo-replacement'>{asset?.name?.charAt()}</div>}
						<span style={{ margin: '0 10px 0 8px' }}>{asset?.name || 'N/A'}</span>
					</FlexWrapper>
				</td>
				<td><span style={{ margin: '0 10px 0 8px' }}>{foundTicker?.ric || asset?.code}</span></td>
				<td>
					{editMode ? <div className='model-portfolio-icons' style={{ justifyContent: 'center' }}>
						<Trash2 size={22} className='delete-icon' onClick={() => deleteAsset(asset)} />
					</div> : <div></div>}
				</td>
				<td>{asset && asset.currencyCode}</td>
				<td>{asset.country}</td>{/*TODO lookup exchange map, or just pass from backend*/}
				<td>{asset.exchange}</td>{/*TODO lookup exchange map, or just pass from backend*/}
				<td className={`text-align-right${editMode ? ' model-td-edit-mode' : ''}`}>
					{
						// editMode ?
						// 	<div className='range-number-input-container flex-align-right'>
						// 		<NumberInputWithStepper
						// 			width={60}
						// 			inputClassName='range-number-input'
						// 			value={actualPercentage}
						// 			onChange={(val) => updateAssetsState(val, asset)}
						// 		/>
						// 	</div> :
							actualPercentage ? actualPercentage + '%' : '-'
					}
				</td>
			</tr>
		)
	})


	return (
		<PageContentBodyGrid subGrid rowGap='m' style={{ marginBottom: isLast ? '1.5rem' : 0 }}>
			<PageGridItem container col='1 / span 12' className={'mb-s'} direction='row' gap='m' align='center' alignItems='center' style={{ height: '1.5rem', marginTop: '0.25rem', maxWidth: 1400 }}>
				{editMode ?
					<DetailField
						size='small'
						editMode={editMode}
						value={assetClassName}
						onChange={(e) => { setAssetClassName(e.target.value); updateDataState(e.target.value, assetClass.id, 'modelName') }}
						inputstyle={{ minWidth: 300 }}
						id={'model-class-id-' + assetClass.id}
					/> : <DetailSectionHeader id={'model-class-id-' + assetClass.id} header={assetClassName} iconEnd={assets && assets.length === 0 ? null : collapsed ? <ChevronUp cursor='pointer' onClick={() => setCollapsed(false)} /> : <ChevronDown cursor='pointer' color='#999DAD' onClick={() => setCollapsed(true)} />} />
				}
				{(!collapsed || assets && assets.length === 0) && <Divider vertical dark height={32} />}
				{!collapsed && assets && assets.length > 0 ?
					<SimpleSearchFilter
						size='small'
						width='l'
						placeholder={`Filter list`}
						isClearable
						icon={<Search />}
						setSearchTerm={setSearchTerm}
						searchTerm={searchTerm}
					/> : ''
				}
				{editMode || (assets && assets.length === 0) ?
					<SearchField
						onChange={(asset) => addAsset(asset.value)}
						options={allAssets}
						placeholder={`Add asset`}
						size='small'
						value=''
						innerRef={searchSelectRef}
						width='xl'
						filterOption={filterOption}
					/> : ''
				}
				{editMode && assets && assets.length > 0 && (collapsed ? <ChevronUp cursor='pointer' color='#0E8EFD' width='1.5rem' height='1.5rem' onClick={() => setCollapsed(false)} /> : <ChevronDown cursor='pointer' color='#999DAD' width='1.5rem' height='1.5rem' onClick={() => setCollapsed(true)} />)}
			</PageGridItem>
			{assets && assets.length === 0 && <PageGridItem container col='1 / span 12' className='helper-text'>No assets on this Model Class. Add some assets.</PageGridItem>}
			{!collapsed && assets && assets.length > 0 ?
				<PageGridItem container col='1 / span 12' style={{ marginBottom: '2rem' }}>
					<Table colWidths={colWidths} dataMap={dataMap(filter(assets, searchTerm))} headers={headers} minWidth={900} tableStyle={{ maxWidth: 1400 }} />
				</PageGridItem> : ''
			}
			{(collapsed || assets && assets.length === 0) && <PageGridItem container col='1 / span 12'><Divider noMargin width='1400px' /></PageGridItem>}
		</PageContentBodyGrid >
	);
}

export default ModelAssetClassTable;
import React, { useState } from 'react';
import DraftNotePreviewModal from '../../components/Modals/DraftNotePreviewModal';
import FlexWrapper from '../FlexWrapper';
import { ChevronDown, ChevronUp, MessageSquare, RefreshCcw, User } from 'react-feather';
import { InfoBlockStatusToken } from './WorkflowInfoBlock';
import Tooltip from '../../components/popups/Tooltip';
import { format, parseISO } from 'date-fns';
import MailIconFilled from '../../images/icons/MailIconFilled';
import ProfileImage from '../../components/ProfileImage';
import { useRemote } from '../../Utils/Utils';
import { toastDanger, toastSuccess } from '../../components/popups/Toast';
import LoadingIcon from '../../components/LoadingIcon';
import ConfirmActionModal from '../../components/Modals/ConfirmActionModal';

const WorkflowComplianceActionRow = ({ fund, taskId, idx, selectedRow, selectedFunds, onFundClick, refreshList }) => {
	const [showModal, setShowModal] = useState(false)
	const [resendingEmail, setResendingEmail] = useState(false)
	const [emailResent, setEmailResent] = useState(false)
	const [confirmingResend, setConfirmingResend] = useState(false)
	fund["template"] = fund.templateName;

	const remote = useRemote()

	function handleResendEmail(e) {
		e.stopPropagation()
		setResendingEmail(true)
		if (!fund.workflowStatusId) {
			toastDanger("Email failed to resend as this step has not commenced yet.")
			setResendingEmail(false)
			return
		}
		remote.post(`/workflows/workflow/${fund.workflowStatusId}/workflowTask/${taskId}/retry/email/${fund.reviewType}/${fund.ticker}`).then((res) => {
			if (res.success) {
				toastSuccess("Email Sent")
				setResendingEmail(false)
				setEmailResent(true)
				refreshList(fund)
			} else {
				toastDanger(res.message)
				setResendingEmail(false)
			}
		}).catch((err) => {
			toastDanger(`Failed To Resend Email`)
			setResendingEmail(false)
		})
	}

	const numberOfApprovals = fund.responderActions ? fund.responderActions.filter(a => a.status === "Approved").length : 0
	const isLastInList = idx === selectedRow.datas.length - 1
	const hasResponses = fund.responderActions && fund.responderActions.length > 0
	const resendEmailMsg = fund.requestEmailSentAt
		? <p>Last Email Sent {format(parseISO(fund.requestEmailSentAt), "hh:mmaa dd/MM/yyyy")}</p>
		: <p>No Email Sent</p>
	const content = [
		fund.name,
		<FlexWrapper >
			{Array.from({ length: selectedRow.approversNeeded }).map((_, index) => {
				return <User key={"approvers-needed-" + index} color={index < numberOfApprovals ? "#56D1C3" : "#D6D8DE"} />
			})}
		</FlexWrapper>,
		<InfoBlockStatusToken label={fund.status} status={fund.status == "Complete" ? "Approved" : fund.status} />,
		<FlexWrapper gap="l" justify={"flex-end"}>
			{fund.workflowStatusId && fund.status !== "Approved" ?
				<Tooltip label={"Resend Email"} >
					{resendingEmail ? <LoadingIcon size={24} /> : <RefreshCcw color="#0E8EFD" style={fund.workflowStatusId ? {} : { opacity: 0.5 }} onClick={() => setConfirmingResend(true)} />}
				</Tooltip>
				: <RefreshCcw color="#0E8EFD" style={{ opacity: 0.5 }} />
			}
			<Tooltip label={resendEmailMsg} >
				<MailIconFilled colour={fund.requestEmailSentAt ? "#56D1C3" : "#C2C4CE"} style={{ opacity: 0.6, marginRight: "0.5rem" }} />
			</Tooltip>
			{selectedFunds && selectedFunds.includes(idx) ? <ChevronUp color={hasResponses ? "#0E8EFD" : "transparent"} style={{ marginRight: "0.5rem" }} /> : <ChevronDown color={hasResponses ? "#C2C4CE" : "transparent"} style={{ marginRight: "0.5rem" }} />}
		</FlexWrapper>
	]
	return (
		<>
			{confirmingResend && <ConfirmActionModal
				header="Resend Email?"
				message="You are about to send a reminder to all available approvers. This action cannot be undone. Do you want to proceed?"
				handleClose={() => setConfirmingResend(false)}
				cancelButton={{ onClick: () => setConfirmingResend(false) }}
				confirmButton={{ label: "Yes, Send", onClick: (e) => { handleResendEmail(e); setConfirmingResend(false) } }}
			/>}
			{showModal && <DraftNotePreviewModal data={fund} doc={fund} compliance handleClose={() => { setShowModal(false) }} autoOpenComments={true} singleDocViewer noActions />}
			<tr key={"fund-" + idx} className={`workflow-compliance-step-row ${selectedFunds && selectedFunds.includes(idx) ? "fund-row-expanded" : ""} ${hasResponses ? "pointer" : "unselectable-fund-action-row"}`} onClick={() => hasResponses && onFundClick(idx)}  >
				<td className='compliance-action-fund-name' >{content[0]}</td>
				<td>{content[1]}</td>
				<td>{content[2]}</td>
				<td className='workflow-cell-align-right action-row-hoverable-chevron'>{content[3]}</td>
			</tr>
			{selectedFunds && selectedFunds.includes(idx) && fund.responderActions && fund.responderActions.length > 0 && fund.responderActions.map((action, actionIndex) => {
				return <tr className='fund-approval-row' key={"approval-" + actionIndex} >
					<td className='fund-approval-row-cell' colSpan={1} >
						<div className='inner-cell' style={{ paddingLeft: "1rem" }}>
							<ProfileImage user={{ email: action.responderEmail, name: action.responderName }} style={{ marginRight: "0.5rem", transform: "scale(0.75)" }} />
							{action.responderName}
						</div>
					</td>
					<td className='fund-approval-row-cell' colSpan={3} >
						<div className='inner-cell'>
							<InfoBlockStatusToken label={action.status} status={action.status} style={{ marginRight: "0.5rem" }} />
							<div className='font-size-header-5'>{format(parseISO(action.updatedAt), 'HH:mmaaa, dd/MM/yyyy')}</div>
							{action.status === "Pending Feedback"
								? <MessageSquare color="#0E8EFD" style={{ marginLeft: "1rem" }} onClick={() => { setShowModal(true) }} />
								: null
							}
						</div>
					</td>
				</tr>
			})}
			{/* {isLastInList && <tr ><td style={{ height: "6rem" }}></td></tr>} */}
		</>
	)
}
export default WorkflowComplianceActionRow;
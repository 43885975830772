import React, { useRef } from 'react';
import { useOutsideAlerter } from '../../Utils/Utils';
import PopupMenu from '../PopupMenu';
import PopupMenuItem from '../PopupMenuItem';



const CommentActionMenu = ({ onClick, resolveOnClick, handleClickAway, setPopupVisible }) => {

	const wrapperRef = useRef(null);
	useOutsideAlerter(wrapperRef, () => { setPopupVisible(false); handleClickAway() });

	return (
		<div ref={wrapperRef}>
			<PopupMenu width={118} padding="s" onClick={onClick}>
				<PopupMenuItem label="Resolve" onClick={resolveOnClick} />
			</PopupMenu>
		</div>
	);
}

export default CommentActionMenu;
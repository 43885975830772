import React from 'react';
import DropDown from '../../DropDown';

const BrandSelector = ({ template, updateTickerStatus, tickerOption, setState, state }) => {
	const { tickers } = state;
	const brands = template.ticker.options || []
	const selectedBrand = brands && tickers && tickers[0] && brands.find(b => b.name === tickers[0])

	const chooseBrand = (brand) => {
		setState({ tickers: [brand.name] }, () => updateTickerStatus(true));
	};

	return (
		<>
			<DropDown ellipseWidth={230} stringStyle={{ padding: '11px 0' }} clickable fullWidth label={tickerOption.selectionName} value={selectedBrand && selectedBrand.name} labelClassName='mt-l' maxDropdownHeight={"calc(98vh - 372px"}>
				{brands.map((option, index) => (
					<li key={index} onClick={() => chooseBrand(option, index)}>
						{option.name}
					</li>
				))}
			</DropDown>
		</>
	)
}

export default BrandSelector;
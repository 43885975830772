import React, { useEffect, useState } from 'react';
import { useRemote, useStorage } from '../../Utils/Utils';
import FlexWrapper from '../../layouts/FlexWrapper';
import Button from '../Buttons/Button';
import TextArea from '../Input/TextArea';
import Comment from './Comment';
import './CommentsSection.scss';

const CommentsSection = ({ ticker, templateName, workflowStatusId, requestId }) => {

	const [commentInputValue, setCommentInputValue] = useState("")
	const [comments, setComments] = useState([])
	const [authors, setAuthors] = useState([])
	const [me, setMe] = useState({})
	const [refreshCount, setRefreshCount] = useState(0)

	const remote = useRemote()
	const storage = useStorage()
	const perms = me?.perms || []
	const userRole = perms.includes("COMMENTS_PEER") ? "Peer" : perms.includes("COMMENTS_COMPLIANCE") ? "Compliance" : perms.includes("COMMENTS_SUPERVISOR") ? "Supervisor" : null

	// const replyInputRef = useRef();

	// const parseDate = (date) => {
	// 	if (!date) {
	// 		return ""
	// 	}
	// 	try {
	// 		const parsedDate = parseISO(date)
	// 		return handleDateFormatting(parsedDate)
	// 	} catch {
	// 		return handleDateFormatting(date)
	// 	}

	// }

	// const handleDateFormatting = (date) => {
	// 	const oneDayprevious = subDays(new Date(), 1)
	// 	const dateIslessThanOneDayOld = isAfter(date, oneDayprevious)
	// 	if (dateIslessThanOneDayOld) {
	// 		return formatDistance(date, new Date())
	// 	} else {
	// 		return format(date, "dd MMM yyyy h:mm aaa")
	// 	}
	// }

	// const hideShowReplies = (id) => {
	// 	if (showReplies.includes(id)) {
	// 		setShowReplies(showReplies.filter(replyId => replyId !== id))
	// 	} else {
	// 		setShowReplies([...showReplies, id])
	// 	}
	// 	setReplyInputValue("")
	// }

	// const showHideReplyInput = (id) => {
	// 	if (showReplyInput === id) {
	// 		setShowReplyInput(null)
	// 	} else {
	// 		setShowReplyInput(id)
	// 	}
	// }

	// useEffect(() => {
	// 	if (showReplyInput) {
	// 		replyInputRef.current.focus()
	// 	}
	// }, [showReplyInput])

	useEffect(() => {
		storage.getOrFetch('/crm/users').then((val) => {
			setAuthors(val);
		});
		storage.getOrFetch('/crm/me').then(setMe)
	}, [])

	useEffect(() => {
		// fetch comments
		if (!ticker) return;
		if (!templateName) return;

		const identifier = btoa(ticker);
		let url = `/comments/${identifier}/${templateName}`
		const params = new URLSearchParams();
		if (requestId) params.append("requestId", requestId);
		if (workflowStatusId) params.append("workflowStatusId", workflowStatusId);

		if (params.toString()) {
			url += `?${params.toString()}`;
		}
		remote.get(url).then((val) => {
			const fetchedComments = []

			val.forEach(comment => {
				if (!comment.replyTo) {
					fetchedComments.push(comment)
				} else {
					const parentComment = fetchedComments.find(c => c.commentId === comment.replyTo)
					if (!parentComment.replies) parentComment.replies = [comment]
					else parentComment.replies.push(comment)
				}
			})
			setComments(fetchedComments)
		})
	}, [ticker, templateName, refreshCount])


	const handleCommentSubmit = (commentId, replyInputValue, callback) => {
		const DTO = {
			replyTo: commentId,
			authorRole: userRole,
			comment: commentId ? replyInputValue : commentInputValue,
		}

		const identifier = btoa(ticker);

		let url = `/comments/${identifier}/${templateName}`
		const params = new URLSearchParams();
		if (requestId) params.append("requestId", requestId);
		if (workflowStatusId) params.append("workflowStatusId", workflowStatusId);

		if (params.toString()) {
			url += `?${params.toString()}`;
		}
		remote.post(url, DTO)
			.then((response) => {
				if (commentId) {
					let newComments = comments.map(comment => {
						if (comment.commentId === commentId) {
							if (!comment.replies) comment.replies = [response.data]
							else comment.replies.push(response.data)
						}
						return comment
					})
					setComments(newComments)
					callback && callback()
				} else {
					let newComments = [...comments, response.data]
					setComments(newComments)
					setCommentInputValue("")


				}
			}
			)
	}



	return (
		<>
			<FlexWrapper direction="column" className="comments-input-container" align="start">
				<TextArea
					placeholder="Add a comment"
					className="add-comment-text-area"
					value={commentInputValue}
					onChange={(e) => setCommentInputValue(e.target.value)}
				/>
				<Button size="small" disabled={!userRole || !commentInputValue} fullWidth onClick={() => handleCommentSubmit()} >Submit Comment</Button>
			</FlexWrapper >
			<FlexWrapper direction="column" className="comments-outer-container" align="start">
				{comments.map((comment, idx) => (
					<Comment
						ticker={ticker}
						templateName={templateName}
						isReply
						comment={comment}
						key={comment.commentId + idx}
						idx={idx}
						isLastComment={(idx + 1) === comments.length}
						authors={authors}
						userRole={userRole}
						handleCommentSubmit={handleCommentSubmit}
						refresh={() => setRefreshCount(refreshCount + 1)}
					/>
				))}
			</FlexWrapper>
		</>
	);
}

export default CommentsSection;
import React, { useEffect, useState, useContext } from 'react';
import { Edit2 } from 'react-feather';
import useWindowDimensions, { onDrop, useRemote } from '../../../Utils/Utils';
import { useLocation } from "react-router";
import { toastWarning } from '../../../components/popups/Toast';
import { ColourSample, DividerWidth, pieChartDataObject, getCategoryChartData, getCategoryChartConfig, extractColours, previewElement } from './BrandsStyles'
import Page, { PageContentBanner, PageContentBodyGrid } from '../../Shared/Page/Page';
import PageGridItem from '../../Shared/Page/PageGridItem';
import Divider from '../../../components/Divider';
import Button from '../../../components/Buttons/Button';
import SelectDetailField from '../../Shared/DetailSection/SelectDetailField';
// import Checkbox from '../../../components/Checkbox';
import WebfontLoader from '@dr-kobros/react-webfont-loader';
import LoadingIcon from '../../Shared/LoadingIcon';
import SideBarExtensionHeader from '../../../components/SideBar/SideBarExtension/SideBarExtensionHeader';
import Dropzone from '../../../components/Dropzone/Dropzone';
import FontAdmin from './FontAdmin';
import TypographyAdmin from './TypographyAdmin';
import DetailField from '../../Shared/DetailSection/DetailField';
import { BannerContext } from '../../../InternalApiApp';
import './StyleGuide.scss'
import "../../Shared/Page/PageGridItem.scss"


const StyleGuide = ({ organisation }) => {
    const { bannerBreadCrumbs, setBannerBreadCrumbs } = useContext(BannerContext);
    const remote = useRemote();
    const location = useLocation();
    const windowHeight = useWindowDimensions().height
    const windowWidth = useWindowDimensions().width

    const [template, setTemplate] = useState(null)
    // const [useDefault, setUseDefault] = useState(false)
    // const [useCustom, setUseCustom] = useState(true)
    const [brandColours, setBrandColours] = useState([])
    const [coloursPalette, setColoursPalette] = useState([])
    const [pieChartImage, setPieChartImage] = useState(null)
    const [categoryChartImage, setCategoryChartImage] = useState(null)
    const [tableStyles, setTableStyles] = useState({})
    const [loading, setLoading] = useState(true)
    // const [pieDataConfig, setPieDataConfig] = useState(null)
    // const [logoUpload, setLogoUpload] = useState(false)
    const [logoUploading, setLogoUploading] = useState(false)
    const [bannerUpload, setBannerUpload] = useState(false)
    const [bannerUploading, setBannerUploading] = useState(false)
    const [logoSrc, setLogoScr] = useState(null)
    const [bannerScr, setBannerScr] = useState(null)
    const [bannerElement, setBannerElement] = useState(null)
    const [bannerHeight, setBannerHeight] = useState(null)
    const [style, setStyle] = useState(null);
    const [details, setDetails] = useState(null);
    const [bannerEdit, setBannerEdit] = useState(false)
    const [logoSettings, setLogoSettings] = useState(null)
    const [bannerSettings, setBannerSettings] = useState(null)
    const [defaultLogoSettings, setDefaultLogoSettings] = useState(null)

    useEffect(() => {
        if (!bannerBreadCrumbs || (bannerBreadCrumbs?.tab !== "Brand")) {
            setBannerBreadCrumbs({ tab: "Brand" })
        }
    }, [bannerBreadCrumbs])

    useEffect(() => {
        setTemplate(organisation?.companyName)
        remote.get('brand/default').then(b => {
            setStyle(b);
            remote.get(`brand/${b.id}/details`).then(setDetails);
        });
    }, [])

    useEffect(() => {
        if (bannerElement) {
            if (style && style.bannerSettings) {
                const parsedSettings = JSON.parse(style.bannerSettings)
                setBannerHeight(parsedSettings)
            } else {
                setBannerHeight(bannerElement.clientHeight)
            }
        }
    }, [bannerElement])

    const fontUpdate = () => {
        //cheat and just replace details from the remote.
        remote.get(`brand/${style.id}/details`).then(setDetails);
    }

    useEffect(() => {
        setLoading(true)
        if (details) {
            const colours = details.colours
            const typography = details.typography

            // Pie Chart Configuration
            const pieChartColoursArray = [colours['Palette 1'].value, colours['Palette 2'].value, colours['Palette 3'].value, colours['Palette 4'].value, colours['Palette 5'].value, colours['Palette 6'].value, colours['Palette 7'].value]

            const pieDataConfiguration = {
                width: 360,
                //height: template === 'Simplicity' ? 168 : 200,
                height: 200,
                styles: { legend: 'body-text'}, // Warning: pie chart still depends on a template to be generated
                backgroundColour: '#ffffff',
                legendColour: colours['Text'].value,
                colours: pieChartColoursArray,
                title: { value: '' },
                //data: { value: template === 'Simplicity' ? '${INVESTMENT_MIX}' : '${WhereGraph}' },
                data: { value: '${WhereGraph}' },
                suppressEmpty: true,
                donutRadius: 0
            }

            const pieChartData = {
                "data": { "data": pieChartDataObject(template) },
                "className": "PieChart",
                "dataSuffix": null,
                "configuration": JSON.stringify(pieDataConfiguration),
                "brandId": details?.brandId,
                "template": {
                    "name": "test",
                    "fonts": {},
                    "styles": {},
                    "pages": []

                }
            };

            // Category Chart configuration
            const chartStyles = {
                width: 200,
                height: 132,
                styles: { p: template === 'Simplicity' ? 'body-text' : 'body-text-small', h1: template === 'Simplicity' ? 'title-header' : 'body-header' }, // Warning: category chart still depends on a template to be generated
                backgroundColour: '#ffffff',
                gridColour: "#ffffff",
                axisColour: colours['Divider'].value,
                title: { value: '' },
                series: [{ colour: colours['Chart Series 1'].value }, { colour: colours['Chart Series 2'].value }]
            }

            const chartColourList = {
                // axis: colours['Divider'].value,
                axis: template === 'Simplicity' ? '#D6D5D2' : "#2B2E34",
                chartBackground: '#ffffff', // template === 'Simplicity' ? '#EDECE8' : '#ffffff',
                grid: template === 'Simplicity' ? '#D6D5D2' : "#ffffff",
                line1: colours['Chart Series 1'].value,
                line2: colours['Chart Series 2'].value,
            }
            const categoryChartConfig = getCategoryChartConfig(chartColourList, template)
            const categoryChartData = getCategoryChartData(categoryChartConfig, template, details)

            // Table configuration
            const tableStyleObject = {
                font: { fontFamily: typography['Table Cell']?.familyName, fontSize: `${typography['Table Cell']?.size}pt` },
                border: {...typography['Table Border']},
                row1: { color: colours['Table Header Text 1']?.value, backgroundColor: colours['Table Header Background 1']?.value, label: 'Table Header 1', keyName: 'tableHead1' },
                row2: { color: colours['Table Header Text 2']?.value, backgroundColor: colours['Table Header Background 2']?.value, label: 'Table Header 2', keyName: 'tableHead2' },
                row3: { color: colours['Table Header Text 3']?.value, backgroundColor: colours['Table Header Background 3']?.value, label: 'Table Header 3', keyName: 'tableHead3' },
                default: { color: colours['Table Cell Text']?.value, backgroundColor: colours['Table Cell Background']?.value, label: 'Table Body Cell', keyName: 'tableCell' }
            }
            const stylesArray = [
                {
                    category: 'General',
                    values: [
                        { ...colours['Primary'], keyName: 'primary' },
                        { ...colours['Secondary'], keyName: 'secondary' },
                        { ...colours['Text'], keyName: 'text' },
                        { ...colours['Divider'], keyName: 'divider' },
                    ]
                },
                {
                    category: 'Tables',
                    values: [
                        { ...colours['Table Cell Text'], keyName: 'tableCellText' },
                        { ...colours['Table Cell Background'], keyName: 'tableCellBackground' },
                        { ...colours['Table Header Text 1'], keyName: 'tableHeaderText1' },
                        { ...colours['Table Header Background 1'], keyName: 'tableBackgroundText1' },
                        { ...colours['Table Header Text 2'], keyName: 'tableHeaderText2' },
                        { ...colours['Table Header Background 2'], keyName: 'tableBackgroundText2' },
                        { ...colours['Table Header Text 3'], keyName: 'tableHeaderText3' },
                        { ...colours['Table Header Background 3'], keyName: 'tableBackgroundText3' },
                        { ...colours['Table Border'], keyName: 'tableBorder' }
                    ]
                },
                {
                    category: 'Pie Chart',
                    values: [
                        { ...colours['Palette 1'], keyName: 'palette1' },
                        { ...colours['Palette 2'], keyName: 'palette2' },
                        { ...colours['Palette 3'], keyName: 'palette3' },
                        { ...colours['Palette 4'], keyName: 'palette4' },
                        { ...colours['Palette 5'], keyName: 'palette5' },
                        { ...colours['Palette 6'], keyName: 'palette6' },
                        { ...colours['Palette 7'], keyName: 'palette7' }
                    ]
                },
                {
                    category: 'Category Chart',
                    values: [
                        { ...colours['Chart Series 1'], keyName: 'chartSeries1' },
                        { ...colours['Chart Series 2'], keyName: 'chartSeries2' }
                    ]
                },
            ]
            const uniqueColours = [...new Set(extractColours(stylesArray))]

            // update states
            setBrandColours(stylesArray)
            setTableStyles(tableStyleObject)
            setColoursPalette(uniqueColours)

            if (style) {
                setLogoScr(style.logo ? true : false)
                setBannerScr(style.banner ? true : false)
                if (style.bannerSettings) {
                    setBannerSettings(JSON.parse(style.bannerSettings))
                } else setBannerSettings({ height: 'auto', width: '21cm' })
                if (style.logoSettings) {
                    setLogoSettings(JSON.parse(style.logoSettings))
                    setDefaultLogoSettings(JSON.parse(style.logoSettings))
                } else setLogoSettings({ height: 'auto', width: '150' })
            }

            if (pieChartData && chartStyles.styles && chartStyles.styles.h1) {
                remote.post(`ssrender/preview`, pieChartData).then(resp => {
                    setPieChartImage(resp.data);
                    const bannerImage = document.getElementById('banner-image-sample');
                    setLoading(false)
                    if (bannerImage) {
                        setBannerElement(bannerImage)
                    }
                }).catch(() => {
                    setPieChartImage(null)
                    setLoading(false)
                })

                remote.post(`ssrender/preview`, categoryChartData).then(resp => {
                    setCategoryChartImage(resp.data);
                }).catch(() => setCategoryChartImage(null))
            }
        }

    }, [location.pathname, template, details, style])


    const uploadLogo = (files) => {
        onDrop(files, `/brand/logo/${style.id}`, {
            setLoading: setLogoUploading,
            onUploadComplete: (data) => {
                try {
                    const parsed = JSON.parse(data.target.response);
                    setStyle(parsed.data);
                } catch (e) {
                    console.log("Error ", e)
                    toastWarning("Error uploading file")
                }
            },
            maxFileSize: 5 * 1024 * 1024
        })
    }

    const uploadBanner = (files) => {
        onDrop(files, `/brand/banner/${style.id}`, {
            setLoading: setBannerUploading,
            onUploadComplete: (data) => {
                setBannerUpload(false)
                try {
                    const parsed = JSON.parse(data.target.response);
                    setStyle(parsed.data);
                    setBannerScr(parsed.data.banner)
                } catch (e) {
                    console.log("Error ", e)
                    toastWarning("Error uploading file")
                }
            },
            maxFileSize: 5 * 1024 * 1024
        })
    }

    const updateColour = (id, colour, name) => {
        remote.put(`/brand/colour/${details.brandId}/${id}`, { name: name, value: colour }).then(response => {
            const deets = { ...details };
            deets.colours[response.name] = response;
            setDetails(deets);
        })
    }

    const updateImageSettings = (string, sizeObj) => {
        remote.post(`/brand/settings/${string}/${style.id}`, sizeObj).then((resp) => {
            if (string !== 'logo') setBannerEdit(false)
            else setDefaultLogoSettings(sizeObj)
        })
    }

    return (
        <Page noBanner noOverflownY style={{ paddingLeft: '1rem' }}>
            <PageContentBodyGrid splitScroll rowGap='m' gridColCount='4' paddingTop='0rem' subGrid gridTemplateColumns='300px calc(21cm + 1rem)' noOverflownY>
                <PageGridItem container className='second-sidebar-left' style={{ height: organisation.hasSubBrands ? windowHeight - 128 : windowHeight - 48 }}>
                    {/* <div className='second-sidebar-left-container'>
                        <div className='second-sidebar-left-label'>Settings</div>
                        <Checkbox disabled checked={useDefault} label='Use Default' onChange={() => { setUseDefault(!useDefault); setUseCustom(!useCustom) }} />
                        <Checkbox disabled checked={useCustom} label='Use Custom' onChange={() => { setUseCustom(!useCustom); setUseDefault(!useDefault) }} />
                    </div> */}
                    <div className='second-sidebar-left-container'>
                        <SelectDetailField editMode={true} disabled placeholder='Select' label="Brand" value={{ value: template, label: template }} options={[]} onChange={(option) => setTemplate(option)} />
                    </div>
                    <div className='second-sidebar-left-container'>
                        <div className='second-sidebar-left-label'>Logo</div>
                        <img style={{ height: 'auto', width: 'fit-content', maxHeight: 100, maxWidth: '100%' }} src={`data:image/png;base64, ${style?.logo}`} alt="logo" id="image-template-logo" />
                        <Dropzone onDrop={uploadLogo} uploading={logoUploading} showUploadingSpinner small />
                    </div>
                    <div className='second-sidebar-left-container'>
                        <div className='second-sidebar-left-label'>Logo Width (pt)</div>
                        <div style={{ display: 'flex', gap: 10 }}>
                            <DetailField editMode={true} size='small' value={logoSettings?.width} onChange={(e) => setLogoSettings({ ...logoSettings, width: e.target.value })} />
                            <Button onClick={() => updateImageSettings('logo', logoSettings)} style={{ width: 'fit-content' }} disabled={logoSettings?.width === defaultLogoSettings?.width}>Save</Button>
                        </div>
                    </div>
                    {template !== 'Simplicity' && <div className='second-sidebar-left-container'>
                        <div className='second-sidebar-left-label'>Banner Image</div>
                        <label className='colour-text-label-grey'>{style?.bannerSettings ? `Recommended size ${bannerSettings.width}pt by ${bannerSettings.height}pt` : 'No recommended size added'}</label>
                        {bannerUpload
                            ? <Dropzone onDrop={uploadBanner} uploading={bannerUploading} showUploadingSpinner small />
                            : <img style={{ height: 'auto', width: '198pt' }} src={`data:image/png;base64, ${style?.banner}`} alt="banner image" id="image-template-banner" />}
                        <div style={{ display: 'flex', gap: 10 }}>
                            <Button onClick={() => setBannerUpload(!bannerUpload)} style={{ width: 'fit-content' }}>{bannerUpload ? "Cancel" : "Upload"}</Button>
                            <Button variant='secondary' onClick={() => setBannerEdit(!bannerEdit)} style={{ width: 'fit-content' }}>{bannerEdit ? "Cancel" : "Edit size"}</Button>
                            {bannerEdit && <Button onClick={() => updateImageSettings('banner', bannerSettings)} style={{ width: 'fit-content' }}>Save</Button>}
                        </div>
                        {bannerEdit &&
                            <div style={{ display: 'flex', gap: 10 }}>
                                <DetailField editMode={true} autoFocus size='small' label="width" value={bannerSettings.width} onChange={(e) => setBannerSettings({ ...logoSettings, width: e.target.value })} />
                                <DetailField editMode={true} size='small' label="height" value={bannerSettings.height} onChange={(e) => setBannerSettings({ ...logoSettings, height: e.target.value })} />
                            </div>
                        }
                    </div>}
                    <div className='second-sidebar-left-container' >
                        <div className='second-sidebar-left-label'>Colour Palette</div>
                        {brandColours && brandColours.length > 0 && brandColours.map((obj, index) => {
                            return (
                                <div className='sample-group' key={obj.category + "-" + index}>
                                    <label className='colour-text-label-grey'>{obj.category}</label>
                                    {obj.values.map((val, idx) => {
                                        return (
                                            <ColourSample changeBrandColour={(value) => updateColour(val.id, value, val.name)} colour={val.value} label={val.name} key={`key-${idx}-${val.color}`} coloursPalette={coloursPalette} />
                                        )
                                    })}
                                </div>
                            )
                        })}
                    </div>
                    <div className='second-sidebar-left-container'>
                        <FontAdmin brand={style} fontUpdated={fontUpdate} details={details} updateDetails={setDetails} />
                    </div>
                    <div className='second-sidebar-left-container'>
                        <div className='second-sidebar-left-label'>Typography</div>
                        <TypographyAdmin brand={style} details={details} updateDetails={setDetails} />
                    </div>
                    {/* Hide dividers settings */}
                    <div className='second-sidebar-left-container' style={{ display: 'none' }}>
                        <div className='second-sidebar-left-label'>Misc</div>
                        {details?.dividers && Object.values(details.dividers).map((val, idx) => <DividerWidth details={details} updateDetails={setDetails} label={val.name} key={`key-${idx}-${val.name}`} />)}
                    </div>
                </PageGridItem>
                <PageGridItem container className='rest-of-the-page-width-second-sidebar-left' style={{ height: organisation.hasSubBrands ? windowHeight - 128 : windowHeight - 48 }}>
                    <PageContentBanner divider gap='m' dividerThinkness='m' style={{ height: 80 }}>
                        <SideBarExtensionHeader header='Style Guide' icon={<Edit2 color='#0E8EFD' />} style={{ marginBottom: 0, paddingRight: 0 }} noTabs />
                        <Divider vertical height='24px' marginTop='0.25rem' />
                        <Button disabled >Save changes</Button>
                        <Button disabled variant='secondary' >Save as Default</Button>
                    </PageContentBanner>
                    {loading ? <LoadingIcon centered /> :
                        <PageContentBodyGrid paddingTop='0px'>
                            <WebfontLoader onStatus={() => { }} >
                                <PageGridItem colSpan='12' >
                                    <div className='sample-content-section'>
                                        <label className='colour-text-label-grey'>Logo + Banner</label>
                                        {bannerScr === null || logoSrc === null ? <LoadingIcon centered /> : bannerScr === true ?
                                            <div id='banner-image-sample' style={{ position: 'relative' }}>
                                                <img src={`data:image/png;base64, ${style?.banner}`} alt='banner-image-sample' className='sample-banner-background' style={{ width: '21cm', height: bannerSettings?.height || bannerHeight }} />
                                                <div className='sample-banner-with-logo' style={{ height: (bannerSettings && bannerSettings.height) || bannerHeight, position: 'absolute' }}>
                                                    <div className='sample-banner-text'>
                                                        {previewElement("Banner Header", details)}
                                                        {previewElement("Banner Sub-header", details)}
                                                    </div>
                                                    <img style={{ height: 'auto', width: +logoSettings?.width + 'pt' || '57pt' }} src={`data:image/png;base64, ${style?.logo}`} alt="logo" id="image-template-logo-in-banner-image" />
                                                </div>
                                            </div> :
                                            <div className='sample-banner-with-logo'>
                                                <img style={{ height: 'auto', width: +logoSettings?.width + 'pt' || '57pt' }} src={`data:image/png;base64, ${style?.logo}`} alt="logo" id="image-template-logo-in-banner" />
                                                <div className='sample-banner-text'>
                                                    {previewElement("Banner Header", details)}
                                                    {previewElement("Banner Sub-header", details)}
                                                </div>
                                            </div >
                                        }
                                    </div>
                                    <div className={`sample-content-section`}>
                                        <label className='colour-text-label-grey'>Typography</label>
                                        {previewElement("Heading 1", details)}
                                        {previewElement("Body", details)}
                                        <div className='sample-headings-two-and-three'>
                                            <div>
                                                {previewElement("Heading 2", details)}
                                                {previewElement("Body", details, "Lorem ipsum")}
                                            </div>
                                            <div>
                                                {previewElement("Heading 3", details)}
                                                {previewElement("Body", details, "Lorem ipsum")}
                                            </div>
                                        </div>

                                        <label className='colour-text-label-grey'>Footer Text</label>
                                        {previewElement("Footer", details)}

                                    </div>
                                    <div className='sample-content-section double-column' style={{ paddingBottom: '2.5rem', gap: '2.5rem' }}>
                                        <div className='sample-chart'>
                                            <label className='colour-text-label-grey'>{template === 'Simplicity' ? 'Bar Chart' : 'Line Chart'}</label>
                                            {categoryChartImage && <img alt="category chart" style={{ width: 360, height: 210, marginTop: '-2rem' }} src={"data:image/png;base64," + categoryChartImage} />}
                                        </div>
                                        <div className='sample-chart'>
                                            <label className='colour-text-label-grey'>Pie Chart</label>
                                            {pieChartImage && <img alt="pie chart" style={{ width: 400, height: 'auto', marginBottom: '-0.5rem' }} src={"data:image/png;base64," + pieChartImage} />}
                                        </div>
                                    </div>
                                    <div className='sample-content-section' style={{ position: 'relative', marginTop: '-1.5rem', borderTop: 'solid 1.5px #E7EAF2', backgroundColor: '#fff' }}>
                                        <label className='colour-text-label-grey'>Table Col/Row</label>
                                        <div className='sample-tables'>
                                            <table className='sample-table' style={tableStyles?.font}>
                                                <thead>
                                                    <tr style={tableStyles?.row1}>
                                                        <th>Header 1</th>
                                                        <th>Lorem</th>
                                                        <th>Lorem</th>
                                                        <th>Lorem</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr style={tableStyles?.row2}>
                                                        <td>Header 2</td>
                                                        <td>Lorem</td>
                                                        <td>Lorem</td>
                                                        <td>Lorem</td>
                                                    </tr>
                                                    <tr style={tableStyles?.row3}>
                                                        <td>Header 3</td>
                                                        <td>Lorem</td>
                                                        <td>Lorem</td>
                                                        <td>Lorem</td>
                                                    </tr>
                                                    <tr style={tableStyles?.default}>
                                                        <td>Header 4</td>
                                                        <td>Lorem</td>
                                                        <td>Lorem</td>
                                                        <td>Lorem</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                   
                                    </div>
                                </PageGridItem>
                            </WebfontLoader>
                        </PageContentBodyGrid>
                    }
                </PageGridItem>

            </PageContentBodyGrid>
        </Page >
    )
}

export default StyleGuide;
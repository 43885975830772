import React, { useEffect, useState } from 'react';
import { Clock, Eye } from 'react-feather';
import Table from '../../../components/Table/Table';
import IconCounter from '../../../components/IconCounter';
import ProfileImage from '../../../components/ProfileImage';
import TickerSectorItem from '../../../components/TickerSectorItem';
import { formatDate, useRemote, useStorage } from '../../../Utils/Utils';
import { format, parseISO, sub } from 'date-fns';
import Tooltip from '../../../components/popups/Tooltip';
import TruncatedString from '../../Shared/TruncatedString'
import "./TopResearchTable.scss";
import { useHistory } from 'react-router-dom';


const TopResearchTable = ({ dateRange }) => {
	const remote = useRemote();
	const storage = useStorage();
	const history = useHistory()
	const [researchData, setResearchData] = useState([])
	const [allTickers, setAllTickers] = useState([])
	const [allGroups, setAllGroups] = useState([])

	const end = format(new Date(), 'yyyy-MM-dd')

	useEffect(() => {
		storage.getOrFetch(`/crm/tickers`).then(setAllTickers).catch(() => setAllTickers([]))
		storage.getOrFetch(`/crm/groupings/${1}`).then((fetchedGrouping) => setAllGroups(fetchedGrouping.groups)).catch(() => setAllGroups([]))
	}, [])

	useEffect(() => {
		let time = { months: 6 }

		if (dateRange) {
			time = dateRange
		}
		let date = typeof dateRange === "string" ? dateRange : format(sub(new Date(), time), "yyyy-MM-dd")
		remote.get(`/analytics/aggregates/documents?from=${date}&to=${end}`).then((stats) => {

			const topFiveDescendingSort = stats.sort((a, b) => b.uniqueViews - a.uniqueViews).slice(0,5)
			setResearchData(topFiveDescendingSort);
		});
	}, [dateRange]);

	return <ResearchTable data={researchData} changeRoute={(url) => history.push(url)} allTickers={allTickers} allGroups={allGroups} />
}

export default TopResearchTable;

const getInitials = (name) => {
	const arr = name && name.split(" ") || []
	let initials = arr.map(word => word.charAt(0))
	return initials.join("")
}

const ResearchTable = ({ data, changeRoute, allTickers, allGroups }) => {
	const colWidths = ["20%", "110px", "auto", "220px"];

	function handleOnClick(research, goToResearch) {
		if (allTickers && allTickers.length > 0 && research.ticker.includes(".")) {
			const ticker = allTickers.find(t => t.ric && t.ric === research.ticker)
			return ticker && goToResearch ? changeRoute(`/analytics/tickers/${ticker.id}/research/${research.documentId}`) : ticker ? changeRoute(`/analytics/tickers/${ticker.id}`) : null
		} else if (allGroups && allGroups.length > 0 && !research.ticker.includes(".")) {
			const group = allGroups.find(g => g.groupName === research.ticker)
			return group && goToResearch ? changeRoute(`/analytics/group/${group.id}/research/${research.documentId}`) : group ? changeRoute(`/analytics/group/${group.id}`) : null

		} else return null
	}

	function getImage(research) {
		if (allTickers && allTickers.length > 0 && research.ticker.includes(".")) {
			const ticker = allTickers.find(t => t.ric && t.ric === research.ticker)
			return ticker ? `/api/public/images/ticker/${ticker.id}.png` : null
		} else if (allGroups && allGroups.length > 0 && !research.ticker.includes(".")) {
			const group = allGroups.find(g => g.groupName === research.ticker)
			return group ? `/api/public/images/group/${group.id}.png` : null
		} else return null
	}

	const dataMap = () => data && data.map((research, idx) => {
		const averageSession = research.minutes / research.count
		const minutes = Math.floor(averageSession)
		const seconds = Math.round((averageSession - minutes) * 60)
		const readableFormat = `${minutes > 0 ? minutes + 'm ' : ''}${seconds}s`

		return (
			<tr key={idx} className="cursor-pointer">
				<td>
					<TickerSectorItem size="m" label={research.ticker} image={getImage(research)} onClick={() => handleOnClick(research)} />
				</td>
				<td onClick={() => handleOnClick(research, true)}>{formatDate(parseISO(research.publishedDate), 'dd/MM/yyyy')}</td>
				<td className="truncate" onClick={() => handleOnClick(research, true)}><TruncatedString string={research.title} /></td>
				<td className="table-action-icons transition-fade-in-slow" onClick={() => handleOnClick(research, true)}>
					<div className='top-research-icons'>
						<Tooltip label={research.publishedBy}><ProfileImage initials={getInitials(research.publishedBy)} size={24} /></Tooltip>
						<IconCounter icon={<Clock />} tooltip="Average Session" dataAvailable count={readableFormat} />
						<IconCounter icon={<Eye />} tooltip="Views" dataAvailable count={research.uniqueViews} />
					</div>
				</td>
			</tr>
		);
	});

	return (
		<Table suggestionMessage space='l' wrapperStyle={{ width: "100%" }} tableStyle={{ minWidth: 600 }} rowSize="s" colWidths={colWidths} dataMap={dataMap()} />
	);
}
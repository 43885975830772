import React, { useCallback } from 'react';
import { AlertCircle, Tool } from 'react-feather';
import Checkbox from '../../../components/Checkbox';
import ExpandableTableRow from '../../../components/Table/ExpandableTableRow';
import Table from '../../../components/Table/Table';
import DetailSectionHeader from '../../Shared/DetailSection/DetailSectionHeader';
import PageGridItem from '../../Shared/Page/PageGridItem';
import ParamField from './ParamField'
import './TemplateMgmt.scss'
import { debounce } from 'throttle-debounce';
import FlexWrapper from '../../FlexWrapper';


const TemplateSettingsList = ({ data, addActionToTemplate, selected, templateId, setSelectedPublishActions, removeAction, templateType, editMode, category, onParamsChange, changedParams, setInvalidParams, invalidParams, manuallyExpandedRows }) => {
	const colWidths = [40 + "%", 50 + "%", "5%", "5%"];

	const camelToTitle = (camelCaseText) => {
		return camelCaseText
			.replace(/([A-Z])/g, ' $1') // Add a space before each uppercase letter
			.replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
	};

	const debouncedOnParamsChange = useCallback(debounce(300, (action, paramName, value) => {
        onParamsChange(action.stepId, paramName, value);
		checkRowIsInvalid(action)
    }), [onParamsChange]);

	const activateTemplateSetting = (action) => {
		addActionToTemplate(action, checkRowIsInvalid(action, true))
	}

	const showParams = (action, name) => {
		if (!action) return null;

		return (
			<div className='param-wrapper'>
				{action.params.map((param, idx) =>
					<ParamField
						editMode={editMode}
						onValueChange={(value) => {
							debouncedOnParamsChange(action, param.name, value)
						}}
						key={idx}
						param={param}
						action={action}
						template={templateId}
						setSelectedPublishActions={setSelectedPublishActions}
					/>)}
			</div>
		)
	}

	const checkRowIsInvalid = (action, ignoreActive) => {
		const params = selected && selected.find((sel) => sel.stepId === action.id);
		const isActive = params && params.active
		const selectedInstance = ignoreActive ? selected.find(a => a.stepId === action.id) : isActive ? selected.find(a => a.stepId === action.id) : null;
		
		if (selectedInstance !== null) {
			const isInvalid = selectedInstance.params.some(param => {
				
				const changedValueIfExists = changedParams && changedParams.find(a => a.stepId === action.id && a[param.name])
				const val = changedValueIfExists ? changedValueIfExists : selectedInstance.paramValues[param.name];
				
				if (param.required && (!val || (val && val.length === 0))) {
					return true
				} else return false
			})
			if ((invalidParams[action.id] && !isInvalid) || (!invalidParams[action.id] && isInvalid)) {
				setInvalidParams(action.id, isInvalid)
				return isInvalid
			}
		}
	}

	const dataMap = () => data && data.map((action, index) => {
		const params = selected && selected.find((sel) => sel.stepId === action.id);
		const isActive = params && params.active
		const disabled = !action.implementation
		const hasParams = params && params.params && params.params.length > 0

		checkRowIsInvalid(action)

		let className = `cursor-pointer row-border-top checkbox-reduced-margin ${disabled ? 'disabled-row' : ''} ${editMode ? '' : 'not-editable-template-settings-row'}`;
		return (
			<ExpandableTableRow key={action.id} className={className} expandContent={showParams(params)} emptyChevronTd dontExpand={disabled} showRowChevron={!!hasParams} rowChevronColour="#333B5A" manuallySetExpandActive={manuallyExpandedRows.includes(action.id)? true : null} >
				<td><FlexWrapper ><Checkbox tooltipClassName={`${editMode ? "" : "cursor-default"}`} className={`${editMode ? "" : "cursor-default"}`} checked={isActive} disabled={!editMode} onChange={(e) => { !isActive ? activateTemplateSetting(action) : removeAction(action) }} />{action.displayName}</FlexWrapper></td>
				<td>{action.description}</td>
				<td className='chevron-td' >{invalidParams[action.id] ? <AlertCircle color='#E66780' /> : null}</td>
			</ExpandableTableRow>
		);
	})

	return (
		<>
			<PageGridItem col="1 / span 6">
				<DetailSectionHeader header={`${camelToTitle(category)} Actions`} icon={<Tool />} className='mb-m' />
				<Table colWidths={colWidths} dataMap={dataMap()} />
			</PageGridItem>
		</>
	);
}

export default TemplateSettingsList;
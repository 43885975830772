import React, { useState, useEffect } from 'react';
import { useRemote, checkIsTruncated } from '../../../Utils/Utils';
import Tooltip from '../../../components/popups/Tooltip';
import "./DataManagerPage.scss";


const DataUploadFileName = ({ upload, file, batchIndex }) => {
    const remote = useRemote()
    const [truncated, setTruncated] = useState(false)

    useEffect(() => {
        getFileNameTruncated()
    }, [batchIndex])

    useEffect(() => {
        window.addEventListener('resize', getFileNameTruncated)
        return () => window.removeEventListener('resize', getFileNameTruncated)
    }, [])

    function getFileNameTruncated() {
        if (checkIsTruncated('file-name-' + file.fileName + "-batch-index-" + batchIndex)) setTruncated(true)
        else setTruncated(false)
    }

    function downloadFile(batchId, fileName, event) {
        if (event) event.stopPropagation()
        remote.get(`data/upload/document/${batchId}/${fileName}`).then(response => {
            if (response) {
                openInTopFrame(response.url);
            }
        });
    }

    function openInTopFrame(url) {
        const newWindow = window.open(url, '_top', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }


    return (
        <Tooltip outerStyle={{ width: '100%' }} label={file.fileName} maxWidth='calc(100% - 1rem)' disabled={!truncated}>
            <div
                onClick={(e) => downloadFile(upload.batchId, file.fileName, e)}
                style={{ height: 39 }}
                className="truncate downlodable"
                id={'file-name-' + file.fileName + "-batch-index-" + batchIndex}
            >
                {file.fileName}
            </div>
        </Tooltip>
    )
}

export default DataUploadFileName
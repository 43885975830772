/* The WebSocketReceiver class manages listeners for different topics and handles incoming messages
accordingly. */

class WebSocketReceiver {
	constructor() {
		// const mockListeners = {
		// 	"/topic/requests": [
		// 		() => console.log("Received 'requests'"),
		// 		() => console.log("I've Listened"),
		// 	],

		// 	"/topic/data-manager": [
		// 		 () => console.log("Received 'data-manager'"),
		// 	],
		// }
		this.listeners = {};
	}

	subscribe(received) {
		received && Object.keys(received)?.forEach((topic) => {
			this.listeners[topic] = received[topic];
		});
	}

	unsubscribe(received) {
		received?.forEach((topic) => {
			delete this.listeners[topic];
		});
	}

	handleMessage(topic, message) {
		switch (topic) {
			case '/topic/broadcast':
				break;
			case '/user/topic/message':
				break;
			case '/user/topic/updates':
				if (this.listeners[message]) {
					this.listeners[message]?.forEach((listener) => listener(message));
				}
				break;
			case '/topic/updates':
				if (this.listeners[message]) {
					this.listeners[message]?.forEach((listener) => listener(message));
				}
				break;
			default:
				console.warning("No websocket handler for topic", topic);
		}

	}
}

const webSocket = new WebSocketReceiver();

export const useWebsockets = () => webSocket;
import React, { useState } from 'react';
import { AlertCircle, CheckCircle, ChevronDown, ChevronUp } from 'react-feather';
import { format } from 'date-fns';
import Table from '../../../components/Table/Table';
import FlexWrapper from '../../FlexWrapper';
import DataUploadExpandedRow from './DataUploadExpandedRow';
import "./DataManagerPage.scss";
import LoadingIcon from '../../../components/LoadingIcon';


const DataUploadTable = ({ data, loading }) => {
	const [sortingColumn, setSortingColumn] = useState({ column: "Upload Date", ascending: false })
	const [expandedRows, setExpandedRows] = useState([])

	const sortTable = (column) => {
		if (sortingColumn.column === column) {
			setSortingColumn({ column, ascending: !sortingColumn.ascending })
		} else {
			setSortingColumn({ column, ascending: true })
		}
	}

	const handleSort = (a, b) => {
		switch (sortingColumn.column) {
			case "Upload Date":
				return sortingColumn.ascending ? a.uploadedDate.localeCompare(b.uploadedDate) : b.uploadedDate.localeCompare(a.uploadedDate);
			case "Files Processed":
				return sortingColumn.ascending ? a.status.numberCompleted - b.status.numberCompleted : b.status.numberCompleted - a.status.numberCompleted;
			case "Files Failed":
				return sortingColumn.ascending ? a.status.numberFailed - b.status.numberFailed : b.status.numberFailed - a.status.numberFailed;
			case "Uploaded By":
				return sortingColumn.ascending ? a.uploadedBy.name.localeCompare(b.uploadedBy.name) : b.uploadedBy.name.localeCompare(a.uploadedBy.name);
			default:
				return 0;
		}
	};

	const colWidths = ["20%", "20%", "20%", "40%"]

	const headers = [
		{
			text: <HeaderText text="Upload Date" sortLabel="Upload Date" ascending={sortingColumn.ascending} currentSort={sortingColumn.column} sortTable={sortTable} />
		},
		{
			text: <HeaderText text="Files Processed" sortLabel="Files Processed" ascending={sortingColumn.ascending} currentSort={sortingColumn.column} sortTable={sortTable} />
		},
		{
			text: <HeaderText text="Files Failed" sortLabel="Files Failed" ascending={sortingColumn.ascending} currentSort={sortingColumn.column} sortTable={sortTable} />
		},
		{
			text: <HeaderText text="Uploaded By" sortLabel="Uploaded By" ascending={sortingColumn.ascending} currentSort={sortingColumn.column} sortTable={sortTable} />
		}
	]

	const dataMap = () => {
		return data && data.length > 0 && data.sort(handleSort).map((upload, idx) => {
			const { numberCompleted, numberFailed, numberOfFiles } = upload.status

			return <>
				<tr key={idx} className={`cursor-pointer data-processing-table-row ${expandedRows.includes(upload.batchId) ? "expanded" : ""}`} onClick={() => {
					expandedRows.includes(upload.batchId)
						? setExpandedRows(expandedRows.filter((val) => val !== upload.batchId))
						: setExpandedRows([...expandedRows, upload.batchId])
				}}>
					<td className={expandedRows.includes(upload.batchId) ? 'expanded-table-row' : ''}>{format(new Date(upload.uploadedDate), "dd/MM/yyyy")}</td>
					<td className={expandedRows.includes(upload.batchId) ? 'expanded-table-row' : ''}><FlexWrapper align='center' gap="s">{numberCompleted === numberOfFiles ? <CheckCircle size={20} color={"#56D1C3"} /> : ""} {`${numberCompleted}/${numberOfFiles}`}</FlexWrapper></td>
					<td className={expandedRows.includes(upload.batchId) ? 'expanded-table-row' : ''}><FlexWrapper align='center' gap="s">{numberFailed >= 1 ? <AlertCircle size={20} color={"#E66780"} /> : ""} {`${numberFailed}/${numberOfFiles}`}</FlexWrapper></td>
					<td className={expandedRows.includes(upload.batchId) ? 'expanded-table-row' : ''}>{upload?.uploadedBy?.name}</td>
				</tr>
				{expandedRows.includes(upload.batchId) && (
					<DataUploadExpandedRow upload={upload} batchIndex={idx} />
				)}
			</>
		})
	}

	if (loading) return <LoadingIcon centered/>
	return (
		<div className="data-upload-table">
			<Table
				minWidth={1100}
				tableStyle={{ maxWidth: 1400 }}
				colWidths={colWidths}
				headers={headers}
				dataMap={dataMap()}
				id='data-manager-table'
			/>
		</div>
	);
}

export default DataUploadTable;

const HeaderText = ({ text, sortLabel, ascending, currentSort, sortTable }) => {
	return (
		<div className='sorting-header' onClick={() => sortTable(sortLabel)} >
			{text}
			{currentSort === sortLabel && ascending === true
				? <ChevronUp className="colour-text-primary" size={12} />
				: <ChevronDown className={currentSort === sortLabel ? "colour-text-primary" : ""} size={12} />
			}
		</div>
	)
}
import React, { useEffect, useState, useContext } from 'react';
import PageGridItem from '../Shared/Page/PageGridItem';
import PageGridDivider from '../Shared/Page/PageGridDivider';
import Table from '../../components/Table/Table';
import TextInput from '../../components/Input/TextInput';
import DateRangeInput from '../CraigsLibraryPage/DateRangeInput';
import { Search } from 'react-feather';
import FlexWrapper from '../FlexWrapper';
import { formatRelative, parseISO } from 'date-fns';
import ProgressBar from '../../components/Input/ProgressBar';
import { useHistory } from 'react-router-dom';
import { PAGES, ROUTE_PATHS } from '../../InternalApiApp';
import { useRemote, useStorage } from '../../Utils/Utils';
import moment from 'moment';
import { statuses } from './WorkflowDetails';
import { BannerContext } from '../../InternalApiApp';
import PageContentToggle from '../../components/Menus/PageContentToggle';
import Divider from '../../components/Divider';

const headers = ["Process", "Last Update", "Progress"]

const WorkflowsList = ({ }) => {
	const [workflows, setWorkflows] = useState([]);
	const [completedWorkflows, setCompletedWorkflows] = useState([]);
	const [processFilter, setProcessFilter] = useState("");
	const [dateRange, setDateRange] = useState({ from: null, to: null });
	const [selectedTab, setSelectedTab] = useState('In Progress')
	const [loading, setLoading] = useState(true)

	const history = useHistory()
	const remote = useRemote()
	const storage = useStorage()
	const { setBannerBreadCrumbs } = useContext(BannerContext)

	useEffect(() => {
		setLoading(true)
		storage.refresh("workflows/list?active=" + (selectedTab === 'Completed' ? false : true)).then((res) => {
			if (selectedTab === 'Completed') setCompletedWorkflows(res);
			else setWorkflows(res)
			setBannerBreadCrumbs({ tab: 'Workflow Monitor' })
			setLoading(false)
		})
	}, [selectedTab]);

	const filterRows = (workflow) => {
		// filter process
		if (processFilter && !workflow.processName.toLowerCase().includes(processFilter.toLowerCase())) return false;
		// filter by date
		if (dateRange.from || dateRange.to) {
			const lastUpdated = parseISO(workflow.lastUpdatedAt);
			if (
				(dateRange.from && moment(lastUpdated).isSameOrBefore(dateRange.from.format())) ||
				(dateRange.to && moment(lastUpdated).isSameOrAfter(dateRange.to.format()))
			) return false;
		}
		return true
	};

	const getRows = (list) => {
		if (!list || list.length === 0) return <></>;

		return list.filter(filterRows).map((workflow, idx) => {
			let workflowTaskProgress = 0
			const workflowTaskTotal = workflow?.tasks?.length || 0
			workflow?.tasks?.forEach((task, idx) => {
				if (statuses[task.status] === statuses.COMPLETE) {
					workflowTaskProgress += 1
				}
			})
			return (
				<tr key={"workflow-" + workflow.processName + "-" + idx} style={{ cursor: "pointer" }} onClick={() => history.push(`${ROUTE_PATHS[PAGES.ACTIVE_WORKFLOWS]}/${workflow.id}`)} >
					<td>{workflow.processName}</td>
					<td>{formatRelative(new Date(parseISO(workflow.lastUpdatedAt)), new Date())}</td>
					<td>
						<FlexWrapper direction="column" style={{ maxWidth: "300px", paddingRight: "1rem", gap: "15px" }}>
							<ProgressBar noToolTip extent={workflowTaskTotal} value={workflowTaskProgress} />
						</FlexWrapper>
					</td>
				</tr>
			)
		})
	}

	return (
		<PageGridItem colSpan='12' gap='xl' direction='col'>
			<PageGridItem direction='row' gap='l' alignItems='center'>
				<PageContentToggle labels={[
					{ label: 'In Progress', active: selectedTab === 'In Progress', onClick: () => setSelectedTab('In Progress') },
					{ label: 'Completed', active: selectedTab === 'Completed', onClick: () => setSelectedTab('Completed') },
				]} />
				<Divider vertical height={32}/>
				<TextInput
					placeholder="QFU, SIPO"
					value={processFilter}
					onChange={(e) => setProcessFilter(e.target.value)}
					icon={<Search />}
					size="small"
				/>
				<DateRangeInput
					onChange={(dateRange) => setDateRange(dateRange)}
					value={dateRange}
				/>
			</PageGridItem>
			<PageGridDivider />
			<PageGridItem fullWidth showScrollbar>
				<Table headers={headers} dataMap={getRows(selectedTab === 'Completed' ? completedWorkflows : workflows)} dataLoading={loading} />
			</PageGridItem>
		</PageGridItem>
	);
}

export default WorkflowsList;